<script>
  import {
    State,
    Arrangement,
    ArrangementErrors,
    ArrangementCheckFlags,
    AddArrangementInteractor,
    AddAssetInteractor,
    UpdateArrangementInteractor,
    DeleteArrangementInteractor,
    DeleteAssetInteractor,
    QueryAssetsInteractor,
    UseTaxpayers,
  } from '@axelity/dac6trackerjs'
  import { equals, clone } from 'ramda'

  // Routing
  import { pop, replace } from 'svelte-spa-router'

  // I18N
  import { _, locale } from 'svelte-i18n'

  // Navigation;
  import Topnav from '../navigation/Topnav.svelte'

  // Components
  import ActionRibbon from '../components/ActionRibbon.svelte'
  import SurveyComponent from '../components/SurveyComponent.svelte'

  // Elements
  import InputField from '../elements/InputField.svelte'
  import Button from '../elements/Button.svelte'
  import Dropdown from '../elements/Dropdown.svelte'
  import Segment from '../elements/Segment.svelte'
  import Page from '../elements/Page.svelte'
  import Loader from '../elements/Loader.svelte'
  import { toast } from '@zerodevx/svelte-toast'

  // Stores
  import { mixinTags, resultTags, restart } from '../stores/AssessmentStore'

  if (!State.isAuthenticated) {
    replace('/signin')
  }

  let useTaxpayerList = new UseTaxpayers().list()

  let fields = new Arrangement({})
  let errors = new ArrangementErrors()
  let menuId = ''
  let documentUploadInput
  let arrangementAssets = []

  let inProcess = false

  const initialize = () => {
    arrangementAssets = []
    mixinTags.set([])
    resultTags.set([])
    restart.set(false)

    if (State.currentArrangement) {
      fields = new Arrangement(clone(State.currentArrangement))
      if (fields.assessments && fields.assessments.length > 0 && fields.assessments[0].tags) {
        resultTags.set(fields.assessments[0].tags)
      }
    }

    if (State.assets.length) {
      State.assets.forEach((asset) => {
        if (asset.arrangementId === State.currentArrangement?.id) {
          arrangementAssets.push(asset)
        }
      })
    }
  }

  initialize()

  // Handler
  const handleCancel = () => {
    if (!equals(fields, State.currentArrangement)) {
      let yes = confirm($_('warning.exitWithoutSaving.label'))
      if (!yes) return
    }
    State.setCurrentArrangement(undefined)
    State.setAssets([])
    pop()
  }

  const handleToggleContextMenu = (e) => {
    if (menuId === '') {
      menuId = e.target.dataset.key
    } else {
      menuId = ''
    }
  }

  const handleTaxpayerChange = async () => {
    // This event is only fired if the taxpayer changes
    // It prepares the taxpayer tags for mixin (see SurveyComponent)
    if (fields.relevantTaxpayer.trim() === '') {
      mixinTags.set([])
    } else {
      const person = State.getPerson(fields.relevantTaxpayer)
      if (person.assessments && person.assessments.length > 0) {
        mixinTags.set(person.assessments[0].tags)
      } else {
        mixinTags.set([])
      }
    }
    restart.set(true)
  }

  const handleAssessmentCompleted = async () => {
    fields.assessments = [
      {
        assessed: new Date().toISOString(),
        tags: $resultTags,
      },
    ]
  }

  const handleSave = async () => {
    const flags = new ArrangementCheckFlags({
      displayName: true,
      relevantTaxpayer: true,
      assessments: true,
    })

    errors = await fields.validate(flags)

    if (!fields.valid) return

    try {
      if (!State.currentArrangement) {
        await new AddArrangementInteractor().execute(fields)
      } else {
        await new UpdateArrangementInteractor().execute(fields)
      }
      State.setCurrentArrangement(undefined)
      State.setAssets([])

      mixinTags.set([])
      resultTags.set([])

      pop()
    } catch (error) {
      errors.system = error
    }
  }

  const handleDelete = async () => {
    if (!arrangementAssets.length) {
      let yes = confirm($_('arrangement.deleteArrangement.label'))

      if (!yes) return

      try {
        await new DeleteArrangementInteractor().execute(State.currentArrangement)
        State.setCurrentArrangement(undefined)
        State.setAssets([])

        mixinTags.set([])
        resultTags.set([])

        pop()
      } catch (error) {
        errors.system = error
      }
    } else {
      window.scrollTo(0, 0);
      errors.system = $_('arrangement.documentsPresentWarning.label')
    }
  }

  const handleDocumentDelete = async (e) => {
    let assets = arrangementAssets.filter((asset) => asset.id === e.target.dataset.key)

    try {
      inProcess = true
      await new DeleteAssetInteractor().execute(assets[0])
    } catch (error) {
      errors.system = error
      inProcess = false
    } finally {
      arrangementAssets = arrangementAssets.filter((asset) => asset.id !== assets[0].id)
      menuId = ''
      toast.push($_('toaster.documentDeleted'))
      inProcess = false

      if (!arrangementAssets.length) {
        errors.system = ''
      }
    }
  }

  const reFetchAssets = async () => {
    State.setAssets([])
    try {
      inProcess = true
      await new QueryAssetsInteractor().execute()
    } catch (error) {
      inProcess = false
      console.log(error.message)
    } finally {
      initialize()
      inProcess = false
    }
  }

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }

  const handleDocumentDownload = async (e) => {
    let assets = arrangementAssets.filter((asset) => asset.id === e.target.dataset.key)
    const file = dataURLtoFile(assets[0].file, assets[0].displayName)

    // Create a URL for the Blob object
    const blobURL = URL.createObjectURL(file)

    // Create a link element to download the file
    const downloadLink = document.createElement('a')
    downloadLink.href = blobURL
    downloadLink.download = assets[0].displayName

    downloadLink.click()
    menuId = ''
  }

  const convertFile = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        const arrayBuffer = reader.result
        resolve(arrayBuffer)
      }
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  }

  const handleUploadDocument = async (event) => {
    const uploadDate = new Date().toLocaleString('de-CH')
    const extractDate = uploadDate.split(',')
    const file = event.target.files[0]

    if (file) {
      const convertedFile = await convertFile(file)

      if (convertedFile) {
        try {
          inProcess = true
          await new AddAssetInteractor().execute({
            displayName: file.name,
            arrangementId: State.currentArrangement?.id,
            uploadDate: extractDate[0],
            file: convertedFile,
          })
        } catch (error) {
          errors.system = error
          inProcess = false
        } finally {
          reFetchAssets()
          inProcess = false
          toast.push($_('toaster.documentUploaded'))
        }
      }
    }
  }
</script>

<Topnav />
<ActionRibbon
  title={$_('section.arrangement.header.label')}
  actionLabel={$_('action.save')}
  actionIcon="save_alt"
  on:cancel={handleCancel}
  on:action={handleSave}
/>

<Page>
  <div class="error">{errors.system}</div>

  <Segment title={$_('arrangement.arrangementDetails.label')} expand={true}>
    <InputField
      type="text"
      name="displayName"
      placeholder={$_('arrangement.displayName.placeholder')}
      label={$_('arrangement.displayName.label')}
      required={true}
      bind:value={fields.displayName}
    />
    <div class="error">{errors.displayName}</div>

    <Dropdown
      name="relevantTaxpayer"
      label={$_('arrangement.taxpayer.label')}
      placeholder={$_('arrangement.taxpayer.placeholder')}
      options={useTaxpayerList}
      on:change={handleTaxpayerChange}
      bind:value={fields.relevantTaxpayer}
    />
    <div class="error">{errors.relevantTaxpayer}</div>

    <InputField
      type="text"
      name="reference"
      placeholder={$_('arrangement.reference.placeholder')}
      label={$_('arrangement.reference.label')}
      required={false}
      bind:value={fields.reference}
    />
    <div class="error">{errors.reference}</div>

    <InputField
      type="textarea"
      name="comment"
      placeholder={$_('arrangement.comment.placeholder')}
      label={$_('arrangement.comment.label')}
      required={false}
      bind:value={fields.comment}
    />
    <div class="error">{errors.comment}</div>
  </Segment>

  <Segment title={$_('person.assessment.label')} expand={true}>
    <SurveyComponent assignTo={'SERVICE'} locale={$locale.substr(0, 2)} on:completed={handleAssessmentCompleted} />
  </Segment>

  {#if State.currentArrangement}
    <Segment title={$_('arrangement.documentsTitle.label')} expand={true}>
      {#if arrangementAssets.length}
        <div class="grid-row header">
          <div class="grid-container">
            <div class="bold-text">{$_('arrangement.documentName.label')}</div>
            <div class="bold-text">{$_('arrangement.documentUploadDate.label')}</div>
            <div class="bold-text right-align">{$_('arrangement.documentAction.label')}</div>
          </div>
        </div>
      {/if}

      {#each arrangementAssets as asset}
        <div class="grid-row">
          <div class="grid-container">
            <div class="wrap">{asset.displayName}</div>
            <div class="wrap">{asset.uploadDate}</div>
            <div class="right-align">
              <div class="dropdown">
                <div
                  data-key={asset.id}
                  class="material-icons grid-item right-align action-icon"
                  on:click={handleToggleContextMenu}
                >
                  more_vert
                </div>
                <div id="contextMenu" class="dropdown-content" class:show-context-menu={menuId === asset.id}>
                  <li data-key={asset.id} on:click={handleDocumentDownload}>
                    {$_('action.download')}
                  </li>
                  <li data-key={asset.id} on:click={handleDocumentDelete}>
                    {$_('action.remove')}
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/each}

      <div class={arrangementAssets.length ? 'upload-button-container' : ''}>
        <Button on:click={documentUploadInput.click()}>{$_('action.uploadDocument')}</Button>
        <input
          id="documentUpload"
          class="hidden"
          accept="application/*, image/*"
          type="file"
          bind:this={documentUploadInput}
          on:change={handleUploadDocument}
        />
      </div>
    </Segment>
  {/if}

  {#if State.currentArrangement}
    <Segment title={$_('arrangement.deleteButton.label')} expand={false}>
      <div class="delete-button">
        <Button outline={false} on:click={handleDelete}>
          {$_('action.remove')}
        </Button>
      </div>
    </Segment>
  {/if}
</Page>

{#if inProcess}
  <Loader />
{/if}

<style>
  .grid-container {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 50% 40% 10%;
  }

  .grid-row {
    display: flex;
    align-items: center;
  }

  .grid-row:hover {
    cursor: pointer;
    background-color: #fff8f3;
  }

  .right-align {
    text-align: right;
  }

  .bold-text {
    font-weight: bold;
  }

  .wrap {
    overflow-wrap: break-word;
  }
  .dropdown {
    position: relative;
    text-align: right;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    left: -80px;
    background-color: #f1f1f1;
    width: 140px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown-content li {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown li:hover {
    cursor: pointer;
    background-color: #ddd;
  }

  .show-context-menu {
    display: block;
  }

  .action-icon {
    padding: 10px 10px 0 0;
  }

  .upload-button-container {
    margin-top: 2rem;
  }

  .hidden {
    visibility: hidden;
  }

  .header {
    margin-bottom: 4px;
  }

  .header:hover {
    cursor: default;
    background-color: transparent;
  }
</style>
