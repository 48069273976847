<script>
  import Arrow from './Arrow.svelte'

  // Parameter
  export let title = ''
  export let expand = true

  // Handler
  const toggleView = () => {
    expand = !expand
  }
</script>

<style>
  .segment {
    width: 100%;
    padding: 0;
    margin: 20px 0;
  }

  .header {
    width: 100%;
    display: grid;
    grid-template-columns: auto 15%;
    align-items: center;
    box-shadow: 1px 1px 5px 1px #d3d3d3;
    padding: 10px 0;
    background-color: #f8f8f8;
  }

  .header:hover {
    cursor: pointer;
  }

  .title {
    font-weight: bold;
    font-size: 120%;
    padding-left: 20px;
  }

  .action {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .arrow-sign {
    margin-right: 20px;
  }

  .content {
    width: 100%;
    box-shadow: 1px 1px 5px 1px #d3d3d3;
    padding: 20px;
  }
</style>

<div class="segment">
  <div class="header" on:click={toggleView}>
    <div class="title">{title}</div>
    <div class="action">
      <div class="arrow-sign" on:click={toggleView}>
        <Arrow direction={expand ? 'up' : 'down'} on:click={toggleView} />
      </div>
    </div>
  </div>
  {#if expand === true}
    <div class="content">
      <slot />
    </div>
  {/if}
</div>
