<script>
  import {
    State,
    UseLanguages,
    DeleteMemberInteractor,
    UpdateMemberInteractor,
    MemberInputCheckFlags,
    MemberInputErrors,
    MemberInput,
  } from '@axelity/dac6trackerjs'
  import { equals, clone } from 'ramda'

  // Routing
  import { pop, push, replace } from 'svelte-spa-router'

  // I18N
  import { _, locale } from 'svelte-i18n'

  // Navigation;
  import Topnav from '../navigation/Topnav.svelte'

  // Components
  import ActionRibbon from '../components/ActionRibbon.svelte'
  import PasswordComponent from '../components/PasswordComponent.svelte'

  // Elements
  import Page from '../elements/Page.svelte'
  import Segment from '../elements/Segment.svelte'
  import InputField from '../elements/InputField.svelte'
  import Button from '../elements/Button.svelte'
  import Dropdown from '../elements/Dropdown.svelte'

  if (!State.isAuthenticated) {
    replace('/signin')
  }

  let username = ''
  if (State && State.vault) {
    username = State.vault.username
  }

  let useLanguageList = new UseLanguages($locale).list()

  let fields = new MemberInput({})
  let errors = new MemberInputErrors()

  // Just used for identifying changes
  let _fields

  const initialize = () => {
    if (State.member) {
      fields.memberName = State.member.memberName
      fields.mobile = State.member.contact.mobile
      fields.language = State.member.contact.language
      fields.email = State.member.contact.email

      _fields = new MemberInput(clone(fields))
    }
  }
  initialize()

  // Handlers
  const handleCancel = () => {
    if (!equals(fields, _fields)) {
      let yes = confirm($_('warning.exitWithoutSaving.label'))
      if (!yes) return
    }
    pop()
  }

  const handleSave = async () => {
    const flags = new MemberInputCheckFlags({
      memberName: true,
      language: true,
    })

    errors = await fields.validate(flags)

    if (!fields.valid) return

    try {
      await new UpdateMemberInteractor().execute(fields)
      // Update locale if necessary
      locale.set(fields.language)
      pop()
    } catch (error) {
      errors.system = error
    }
  }

  const handleDelete = async () => {
    let yes = confirm($_('member.deleteMember.label'))
    if (!yes) return

    try {
      await new DeleteMemberInteractor().execute()
      push('/signoff')
    } catch (error) {
      errors.system = error
    }
  }
</script>

<style>

</style>

<Topnav />
<ActionRibbon
  title={$_('member.profile.label')}
  actionLabel={$_('action.save')}
  actionIcon="save_alt"
  on:cancel={handleCancel}
  on:action={handleSave} />

<Page>

  <div class="error">{errors.system}</div>

  <Segment title={$_('member.userDetails.label')} expand={true}>
    <InputField
      type="text"
      name="username"
      placeholder={$_('member.username.placeholder')}
      label={$_('member.username.label')}
      required={true}
      disabled={true}
      value={username} />

    <InputField
      type="text"
      name="memberName"
      placeholder={$_('member.memberName.placeholder')}
      label={$_('member.memberName.label')}
      required={true}
      bind:value={fields.memberName} />
    <div class="error">{errors.memberName}</div>

    <InputField
      type="email"
      name="email"
      placeholder={$_('member.email.placeholder')}
      label={$_('member.email.label')}
      required={false}
      bind:value={fields.email} />
    <div class="error">{errors.email}</div>

    <InputField
      type="text"
      name="mobile"
      placeholder={$_('member.mobile.placeholder')}
      label={$_('member.mobile.label')}
      required={false}
      bind:value={fields.mobile} />
    <div class="error">{errors.mobile}</div>

    <Dropdown
      name="language"
      label={$_('member.language.label')}
      placeholder={$_('member.language.placeholder')}
      options={useLanguageList}
      bind:value={fields.language} />
    <div class="error">{errors.language}</div>

  </Segment>

  <Segment title={$_('component.password.label')} expand={false}>

    <PasswordComponent />

  </Segment>

  <Segment title={$_('member.deleteButton.label')} expand={false}>

    <div class="delete-button">
      <Button outline={false} on:click={handleDelete}>
        {$_('action.remove')}
      </Button>
    </div>
  </Segment>

</Page>
