<script>
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  export let checked = false
  export let id = ''
  export let name = ''
  export let value = false

  const handleClick = (e) => {
    if (e.target.checked) {
      value = true
    } else {
      value = false
    }
    dispatch('click', value)
  }

  const handleDblClick = (e) => {
    if (e.target.checked) {
      value = true
    } else {
      value = false
    }
    dispatch('dblclick', value)
  }
</script>

<style>
  /* /* This code is originally from https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_custom_checkbox */
  /* The container */
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #ec6907;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 10px;
    top: 6px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
</style>

<label class="container" on:click={handleClick} on:dblclick={handleDblClick}>
  <slot />
  <input
    type="checkbox"
    checked={checked ? 'checked' : ''}
    {id}
    {value}
    {name}
    on:click={handleClick}
    on:dblclick={handleDblClick} />
  <span class="checkmark" on:click={handleClick} on:dblclick={handleDblClick} />
</label>
