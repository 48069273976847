export default {
  /**
   * product
   */
  product: {
    name: 'DAC6 Tracker',
    tagline:
      'Überwachen und Meldung grenzüberschreitender meldepflichtiger Steuergestaltungen',
    company: {
      name: 'Red Leafs InnoTax AG',
      link: 'https://redleafsinnotax.com',
    },
    ipRights: 'Alle Rechte vorbehalten',
    legal: 'Impressum',
    privacy: 'Datenschutzbestimmungen',
    terms: 'Nutzungsbedingungen',
  },

  /**
   * elements
   */
  elements: {
    firstname: {
      placeholder: 'Vorname',
      label: 'Vorname',
    },
    lastname: {
      placeholder: 'Nachname',
      label: 'Nachname',
    },
    fullname: {
      placeholder: 'Vollständiger Name',
      label: 'Vollständiger Name',
    },
    nickname: {
      placeholder: 'Eindeutiger Benutzername',
      label: 'Benutzername',
    },
    email: {
      placeholder: 'Gültige E-Mail-Adresse',
      label: 'E-Mail-Adresse',
    },
    password: {
      placeholder: 'Passwort (min. 8-stellig)',
      label: 'Passwort',
    },
    passwordConfirm: {
      placeholder: 'Passwort bestätigen',
      label: 'Passwort bestätigen',
    },
    oldPassword: {
      placeholder: 'Bisheriges Passwort',
      label: 'Bisheriges Passwort',
    },
    newPassword: {
      placeholder: 'Neues Passwort',
      label: 'Neues Passwort',
    },
    emergencyKey: {
      placeholder: 'Format XXXXX-XXXXX-XXXXX-XXXXX-XXXXX',
      label: 'Notfallschlüssel',
    },
    language: {
      placeholder: 'Wählen Sie Ihre Sprache',
      label: 'Sprache',
    },
    displayName: {
      placeholder: 'Sprechender Name',
      label: 'Name',
    },
    summary: {
      placeholder: 'Zusammenfassung',
      label: 'Zusammenfassung',
    },
    storageLocation: {
      placeholder: 'Wählen Sie Ihren Speicherort',
      label: 'Speicherort (Kann anschliessend nicht mehr geändert werden)',
    },
    workspace: {
      placeholder: 'Wählen Sie Ihren Arbeitsbereich',
      label: 'Arbeitsbereich',
    },
    recipients: {
      placeholder: 'Wählen Sie die Empfänger aus',
      label: 'Empfänger',
    },
    personLabel: {
      placeholder: 'Bezeichnung der Person',
      label: 'Bezeichnung',
    },
    personKind: {
      placeholder: 'Art',
      label: 'Art',
    },
    personRole: {
      placeholder: 'Rolle',
      label: 'Rolle',
    },
    legalForm: {
      placeholder: 'Rechtsform der juristischen Person',
      label: 'Rechtsform',
    },
    street: {
      placeholder: 'Strasse und Hausnummer',
      label: 'Strasse',
    },
    poBox: {
      placeholder: 'Postfach',
      label: 'Postfach',
    },
    zipCode: {
      placeholder: 'Postleitzahl',
      label: 'Postleitzahl',
    },
    city: {
      placeholder: 'Ortschaft',
      label: 'Ortschaft',
    },
    country: {
      placeholder: 'Land',
      label: 'Land',
    },
    dateOfBirth: {
      placeholder: 'Geburtsdatum',
      label: 'Geburtsdatum',
    },
    placeOfBirth: {
      placeholder: 'Geburtsort oder Heimatort (CH)',
      label: 'Geburtsort',
    },
    intermediary: {
      placeholder: 'Intermediär',
      label: 'Intermediär',
    },
    taxpayer: {
      placeholder: 'Kunde (Steuerzahler)',
      label: 'Kunde',
    },
    activityLabel: {
      placeholder: 'Aktivität',
      label: 'Aktivität',
    },
  },

  /**
   * sections
   */
  section: {
    home: {
      heading: {
        label: 'DAC6 Steuergestaltungen — mühelos und sicher überwachen',
      },
      subHeading: {
        label:
          'Die clevere Art grenzüberschreitende meldepflichtige Gestaltungen für Kunden und Steuerpflichtige zu verwalten.',
      },
    },
    signUp: {
      label: 'Registrieren',
      signedUpAlready: {
        label: 'Haben Sie sich bereits registriert?',
      },
      header: {
        label: 'Beginnen Sie gleich und registrieren Sie sich jetzt für die Gratisversion!',
      },
      acceptTerms: {
        label: 'Ich stimme dem Vertrag über die Nutzung des DAC6 Trackers zu',
      },
      userAgreement: {
        label: 'Nutzungsvertrag',
      },
    },
    signIn: {
      label: 'Anmelden',
      notSignedUpYet: {
        label: 'Noch nicht registriert?',
      },
      header: {
        label: 'Hier können Sie sich anmelden',
      },
      lostAccessData: {
        label: 'Haben Sie Ihre Zugangsdaten nicht mehr?',
      },
    },
    recover: {
      label: 'Wiederherstellung',
      header: {
        label: 'Hier können Sie Ihr Konto wiederherstellen',
        instructions:
          'Verwenden Sie Ihren Notfallschlüssel, den Sie bei der Registrierung erhalten haben und vergeben Sie ein neues Passwort. Der Nutzername bleibt bestehen.',
      },
    },
    recoveryKey: {
      header: {
        label: 'Ihr Notfallschlüssel',
        summary:
          'Bitte schreiben Sie Ihren Notfallschlüssel auf und bewahren Sie ihn an einem sicheren Ort auf. Mit diesem Schlüssel können Sie Ihr Konto wiederherstellen. Anderenfalls sind Ihre Informationen dauerhaft verloren.',
        notAvailable:
          'Ihr Notfallschlüssel ist nicht mehr verfügbar. Sicher haben Sie ihn aufgeschrieben und an einem sicheren Ort verwahrt.',
        confirmation:
          'Bitte bestätigen Sie, dass Sie den Notfallschlüssel notiert haben',
      },
    },
    dashboard: {
      header: {
        label: 'Das ist Ihre Übersicht',
      },
    },
    profile: {
      header: {
        label: 'Pflege Sie hier Ihre Profildaten',
      },
    },
    accounts: {
      header: {
        label: 'Konten',
      },
      empty: 'Sie haben noch keine Konten',
    },
    account: {
      header: {
        label: 'Konto',
      },
    },
    persons: {
      header: {
        label: 'Juristische Personen und Privatpersonen',
      },
      empty: 'Sie haben noch keine juristische Personen oder Privatpersonen',
    },
    person: {
      header: {
        label: 'Person',
      },
    },
    arrangements: {
      header: {
        label: 'Gestaltungen',
      },
      empty: 'Sie haben noch keine Gestaltungen',
    },
    arrangement: {
      header: {
        label: 'Gestaltung',
      },
    },
    disclosures: {
      header: {
        label: 'Offenlegungen/Meldungen',
      },
      empty: 'Sie haben noch keine Offenlegungen/Meldungen',
    },
    disclosure: {
      header: {
        label: 'Offenlegung/Meldung',
      },
    },
    notFound: {
      header: {
        label: 'Wir konnten die gewünschte Seite leider nicht finden',
      },
    },
    signOff: {
      header: {
        label: 'Sie sind jetzt abgemeldet',
      },
    },
  },

  /**
   * menu
   */
  menu: {
    dashboard: 'Dashboard',
    profile: 'Profil',
    accounts: 'Konten',
    account: 'Konto',
    persons: 'Personen',
    arrangements: 'Gestaltungen',
    disclosures: 'Meldungen',
    signOff: 'Abmelden',
  },

  /**
   * components
   */
  component: {
    email: {
      label: 'E-Mail-Adresse',
      empty: 'Hier fehlt noch Ihre E-Mail-Adresse',
    },
    password: {
      label: 'Passwort',
      empty: 'Hier fehlt noch Ihr Passwort',
    },
    language: {
      label: 'Sprache',
      empty: 'Hier fehlt noch Ihre Spracheinstellung',
    },
    workspace: {
      label: 'Arbeitsbereich',
      empty: 'Erfassen Sie hier die Details zum Arbeitsbereich',
    },
    workspaceSelection: {
      label: 'Arbeitsbereich',
      empty: 'Wählen Sie hier Ihren Standard-Arbeitsbereich aus',
    },
    intermediarySelection: {
      label: 'Intermediär',
      empty: 'Wählen Sie hier den Intermediär aus',
    },
    taxpayerSelection: {
      label: 'Kunde (Steuerzahler)',
      empty: 'Wählen Sie hier den Kunden aus',
    },
    topic: {
      label: 'Details zum Thema',
      empty: 'Erfassen Sie hier die Details zum Thema',
    },
    participants: {
      label: 'Teilnehmer',
      placeholder: 'Fügen Sie weitere Teilnehmer hinzu',
      empty: 'Es wurden keine Teilnehmer hinzugefügt',
    },
    person: {
      label: 'Person (Intermediär, Kunde, andere)',
      placeholder: 'Erfassen Sie hier die Details zur Person',
      empty: 'Es sind noch keine Daten erfasst',
    },
    address: {
      label: 'Adresse',
      placeholder: 'Erfassen Sie hier die Details zur Adresse',
      empty: 'Es sind noch keine Adressdaten erfasst',
    },
    individual: {
      label: 'Privatperson',
      placeholder: 'Erfassen Sie hier die Details zur Privatperson',
      empty: 'Es sind noch keine Details zur Privatperson erfasst',
    },
    assessment: {
      intermediary: 'Intermediär',
      taxpayer: 'Nutzer',
      service: 'Beratungsleistungen',
      done: 'Die Bewertung ist abgeschlossen',
    },
    activity: {
      label: 'Aktivität',
      placeholder: 'Erfassen Sie hier die Details zur Aktivität',
      empty: 'Es sind noch keine Details zur Aktivität erfasst',
    },
  },

  dashboard: {
    account: {
      title: 'Konto',
      empty: 'Es wurde noch kein Konto eingerichtet',
    },
    persons: {
      title: 'Juristische Personen und Privatpersonen',
      empty: 'Es sind noch keine juristischen Personen oder Privatpersonen vorhanden',
    },
    arrangements: {
      title: 'Gestaltungen',
      empty: 'Es sind noch keine Gestaltungen vorhanden',
    },
    disclosures: {
      title: 'Offenlegungen / Meldungen',
      empty: 'Es sind noch keine Meldungen vorhanden',
    },
  },

  /**
   * actions
   */
  action: {
    signUp: 'Registrieren',
    signIn: 'Anmelden',
    recover: 'Wiederherstellen',
    back: 'Zurück',
    next: 'Weiter',
    verify: 'Überprüfen',
    edit: 'Ändern',
    save: 'Speichern',
    apply: 'Übernehmen',
    cancel: 'Abbrechen',
    remove: 'Entfernen',
    invite: 'Einladen',
    continue: 'Weiter',
    reset: 'Zurücksetzen',
    show: 'Anzeigen',
    create: 'Erzeugen',
    addAccount: 'Neue Konto',
    addPerson: 'Neue Person',
    addArrangement: 'Neue Gestaltung',
    createDisclosure: 'Meldung erzeugen',
    createSummary: 'Zusammenfassung erzeugen',
    createFullReport: 'Vollständiger Bericht erzeugen',
    createXMLde: 'XML-Datensatz für Deutschland',
    upgrade: 'Upgrade',
    downgrade: 'Downgrade',
    pdfGenerate: 'PDF generieren',
    uploadDocument: "Dokument hochladen",
    download: 'Herunterladen'
  },

  /**
   * storage locations
   */
  storageLocation: {
    ch: 'Schweiz',
    de: 'Deutschland',
    fr: 'Frankreich',
    no: 'Norwegen',
  },

  /**
   * languages
   */
  language: {
    de: 'Deutsch',
    en: 'English',
  },

  /**
   * person kind
   */
  personKind: {
    organization: 'Juristische Person',
    individual: 'Privatperson',
  },

  /**
   * person role
   */
  personRole: {
    intermediary: 'Intermediär',
    taxpayer: 'Nutzer (Steuerzahler)',
    other: 'Andere',
  },

  /**
   * Person
   */
  person: {
    label: {
      singular: 'Person',
      plural: 'Personen',
    },
    header: {
      displayName: 'Name',
      kind: 'Art',
      role: 'Rolle',
      assessed: 'Letzte Prüfung',
      file: 'Meldepflicht',
      action: 'Aktion',
    },
    personDetails: {
      label: 'Angaben zur juristischen Person oder Privatperson',
    },
    displayName: {
      label: 'Anzeigename',
      placeholder: 'Aussagekräftiger Name',
    },
    personKind: {
      label: 'Art',
      placeholder: 'Art der Person',
    },
    personRole: {
      label: 'Rolle',
      placeholder: 'Rolle der Person',
    },
    intermediary: {
      label: 'Intermediär',
      placeholder: 'Intermediär',
    },
    additionalInformation: {
      label: 'Zusätzliche Informationen zur Person',
    },
    countriesOfResidence: {
      label: 'Ansässigkeitsstaat(en)',
      placeholder: 'Bitte wählen Sie mindestens einen Ansässigkeitsstaat aus',
    },
    tin: {
      label: 'Steueridentifikationsnummer',
      placeholder: 'Steueridentifikationsnummer',
    },
    email: {
      label: 'E-Mail-Adresse',
      placeholder: 'E-Mail-Adresse',
    },
    organizationDetails: {
      label: 'Organisationsdaten',
    },
    organizationName: {
      label: 'Name der Organisation',
      placeholder: 'Name der Organisation',
    },
    individualDetails: {
      label: 'Angaben zur Privatperson',
    },
    firstName: {
      label: 'Vorname',
      placeholder: 'Vorname',
    },
    middleName: {
      label: 'Zweiter Vorname',
      placeholder: 'Zweiter Vorname',
    },
    lastName: {
      label: 'Nachname',
      placeholder: 'Nachname',
    },
    dateOfBirth: {
      label: 'Geburtsdatum',
      placeholder: 'Geburtsdatum',
    },
    placeOfBirth: {
      label: 'Geburtsort',
      placeholder: 'Geburtsort',
    },
    addressDetails: {
      label: 'Adressangaben',
    },
    street: {
      label: 'Strasse',
      placeholder: 'Strasse und Hausnummer',
    },
    poBox: {
      label: 'Postfach',
      placeholder: 'Postfach',
    },
    postCode: {
      label: 'Postleitzahl',
      placeholder: 'Postleitzahl',
    },
    city: {
      label: 'Ort',
      placeholder: 'Ort',
    },
    country: {
      label: 'Land',
      placeholder: 'Land',
    },
    assessment: {
      label: 'Bewertung',
      placeholder: 'Bewertung',
    },
    deleteButton: {
      label: 'Person löschen',
    },
  },

  /**
   * account
   */
  account: {
    label: {
      singular: 'Konto',
      plural: 'Konten',
    },
    header: {
      displayName: 'Name',
      accountType: 'Kontotyp',
      company: 'Firma',
      contact: 'Kontakt',
      phone: 'Telefon',
      email: 'E-Mail',
      since: 'Seit',
      premium: 'Premium',
      action: 'Aktion',
    },
    credentials: {
      username: 'Administrator ID',
      password: 'Administrator Passwort',
    },
    type: {
      free: 'Kostenlos',
      premium: 'Premium',
    },
    noAccount: {
      label: 'Es ist kein Konto vorhanden',
    },
    noAccounts: {
      label: 'Es sind keine Konten vorhanden',
    },
    deleteAccount: {
      label: 'Möchten Sie das Konto wirklich löschen?',
    },
    checkoutMember: {
      label: 'Möchten Sie die Person wirklich aus dem Nutzerkreis entfernen?',
    },
    maxReached: {
      label: 'Sie haben mit der von Ihnen gewählten Kontoart die Höchstzahl der möglichen Gestaltungen erreicht'
    },
    accountDetails: {
      label: 'Kontoangaben',
      placeholder: 'Kontoangaben',
    },
    displayName: {
      label: 'Anzeigename',
      placeholder: 'Aussagekräftiger Name',
    },
    plan: {
      label: 'Abonnement',
      placeholder: 'Auswahl des Abonnements',
    },
    activationKey: {
      label: 'Aktivierungsschlüssel',
      placeholder: 'Aktivierungsschlüssel',
    },
    storageAccount: {
      label: 'Speicherort',
      placeholder: 'Speicherort',
    },
    publicBusinessName: {
      label: 'Firmenname',
      placeholder: 'Offizieller Firmenname',
    },
    contactDetails: {
      label: 'Kontaktangaben',
      placeholder: 'Kontaktangaben',
    },
    contactName: {
      label: 'Kontaktperson',
      placeholder: 'zuständige Kontaktperson',
    },
    email: {
      label: 'E-Mail-Adresse',
      placeholder: 'E-Mail-Adresse der Kontaktperson',
    },
    phone: {
      label: 'Telefon',
      placeholder: 'Telefonnummer der Kontaktperson',
    },
    language: {
      label: 'Sprache',
      placeholder: 'Sprache der Kontaktperson',
    },
    addressDetails: {
      label: 'Adressangaben',
      placeholder: 'Adressangaben',
    },
    street: {
      label: 'Strasse',
      placeholder: 'Strasse und Hausnummer',
    },
    postCode: {
      label: 'Postleitzahl',
      placeholder: 'Postleitzahl',
    },
    city: {
      label: 'Ort',
      placeholder: 'Ort',
    },
    country: {
      label: 'Land',
      placeholder: 'Land',
    },
    plan: {
      label: 'Abonnement',
      placeholder: 'Abonnement',
    },
    deleteButton: {
      label: 'Konto löschen',
    },
  },

  /**
   * member
   */
  member: {
    label: {
      singular: 'Nutzer',
      plural: 'Nutzer',
    },
    deleteMember: {
      label: 'Möchten Sie Ihren Nutzer wirklich löschen?',
    },
    profile: {
      label: 'Nutzerprofil',
      placeholder: 'Nutzerprofil',
    },
    userDetails: {
      label: 'Nutzerangaben',
      placeholder: 'Nutzerangaben',
    },
    username: {
      label: 'Nutzername',
      placeholder: 'Nutzername',
    },
    uniqueUsername: {
      label: 'Eindeutiger Nutzername (kann nicht mehr geändert werden)',
      placeholder: 'Eindeutiger Nutzername',
    },
    memberName: {
      label: 'Vollständiger Name',
      placeholder: 'Vollständiger Name',
    },
    email: {
      label: 'E-Mail-Adresse',
      placeholder: 'E-Mail-Adresse',
    },
    mobile: {
      label: 'Mobiltelefonnummer',
      placeholder: 'Mobiltelefonnummer',
    },
    language: {
      label: 'Sprache',
      placeholder: 'Sprache',
    },
    deleteButton: {
      label: 'Nutzer löschen',
    },
  },

  /**
   * arrangement
   */
  arrangement: {
    label: {
      singular: 'Gestaltung',
      plural: 'Gestaltungen',
    },
    header: {
      displayName: 'Name',
      taxpayer: 'Nutzer',
      assessed: 'Letzte Prüfung',
      file: 'Meldepflicht',
      phase: 'Phase',
      action: 'Aktion',
    },
    arrangementDetails: {
      label: 'Angaben zur Gestaltung',
    },
    displayName: {
      label: 'Anzeigename',
      placeholder: 'Aussagekräftiger Name',
    },
    reference: {
      label: 'Interne Referenz',
      placeholder: 'Interne Referenz',
    },
    taxpayer: {
      label: 'Nutzer',
      placeholder: 'Nutzer',
    },
    comment: {
      label: 'Interner Kommentar',
      placeholder: 'Interner Kommentar',
    },
    assessment: {
      label: 'Bewertung',
      placeholder: 'Bewertung',
    },
    deleteArrangement: {
      label: 'Möchten Sie die Gestaltung wirklich löschen?',
    },
    deleteButton: {
      label: 'Gestaltung löschen',
    },
    documentsTitle: {
      label: 'Dokumente'
    },
    documentName : {
      label: 'Dateiname'
    },
    documentUploadDate : {
      label: 'Hochladedatum'
    },
    documentAction : {
      label: 'Aktion'
    },
    documentsPresentWarning: {
      label: 'Es gibt noch vorhandene Dokumente. Bitte entfernen Sie diese, um das Arrangement zu löschen.'
    }
  },

  /**
   * disclosure
   */
  disclosure: {
    label: {
      singular: 'Meldung',
      plural: 'Meldungen',
    },
    report: {
      summary: 'Zusammenfassung',
      full: 'Komplett',
    },
    header: {
      displayName: 'Name',
      taxpayer: 'Nutzer',
      implementingDate: '1. Implementierung',
      action: 'Aktion',
    },
    arrangementSelection: {
      label: 'Auswahl der meldepflichtigen Gestaltung',
      placeholder: 'Auswahl der meldepflichtigen Gestaltung',
    },
    arrangement: {
      label: 'Gestaltung',
      placeholder: 'Gestaltung',
    },
    disclosureDetails: {
      label: 'Angaben zur Meldung',
    },
    messageId: {
      label: 'Mitteilungsnummer',
      placeholder: 'Mitteilungsnummer',
    },
    arrangementId: {
      label: 'Gestaltungsnummer',
      placeholder: 'Gestaltungsnummer',
    },
    disclosureId: {
      label: 'Offenlegungsnummer',
      placeholder: 'Offenlegungsnmmer',
    },
    initialDisclosure: {
      label: 'Initialmeldung marktfähige Gestaltung',
      placeholder: 'Initialmeldung marktfähige Gestaltung',
    },
    receivingCountries: {
      label: 'Empfängerstaaten',
      placeholder: 'Empfängerstaaten',
    },
    discloserDetails: {
      label: 'Angaben zur meldenden Person',
      placeholder: 'Angaben zur meldenden Person',
    },
    discloser: {
      label: 'Meldende Person',
      placeholder: 'Meldende Person',
    },
    intermediaryNexus: {
      label: 'Verbindung zum Mitgliedstaat',
      placeholder: 'Verbindung zum Mitgliedstaat',
    },
    intermediaryCapacity: {
      label: 'Rolle des Intermediärs',
      placeholder: 'Rolle des Intermediärs',
    },
    taxpayerNexus: {
      label: 'Verbindung zum Mitgliedstaat',
      placeholder: 'Verbindung zum Mitgliedstaat',
    },
    taxpayerCapacity: {
      label: 'Grund der Meldepflicht des Nutzers',
      placeholder: 'Grund der Meldepflicht des Nutzers',
    },
    taxpayerList: {
      label: 'Liste der Nutzers',
      placeholder: 'Liste der Nutzers',
    },
    implementingDate: {
      label: 'Beginn der Umsetzung',
      placeholder: 'Beginn der Umsetzung',
    },
    relevantTaxpayers: {
      label: 'Relevante Nutzer',
      placeholder: 'Relevante Nutzer',
    },
    intermediaryList: {
      label: 'Liste der beteiligten Intermediäre',
      placeholder: 'Liste der beteiligten Intermediäre',
    },
    involvedIntermediaries: {
      label: 'Beteiligte Intermediäre',
      placeholder: 'Beteiligte Intermediäre',
    },
    affectedPersonList: {
      label: 'Liste der betroffenen Personen',
      placeholder: 'Liste der betroffenen Personen',
    },
    affectedPersons: {
      label: 'Betroffene Personen',
      placeholder: 'Betroffene Personen',
    },
    messageReason: {
      label: 'Grund der Meldung',
      placeholder: 'Grund der Meldung',
    },
    reason: {
      label: 'Grund',
      placeholder: 'Grund',
    },
    arrangementDetails: {
      label: 'Angaben zur Gestaltung',
      placeholder: 'Angaben zur Gestaltung',
    },
    arrangementName: {
      label: 'Name',
      placeholder: 'Name',
    },
    description: {
      label: 'Beschreibung',
      placeholder: 'Beschreibung',
    },
    language: {
      label: 'Sprache',
      placeholder: 'Sprache',
    },
    economicValue: {
      label: 'Wirtschaftlicher Wert',
      placeholder: 'Wirtschaftlicher Wert',
    },
    currency: {
      label: 'Währung',
      placeholder: 'Währung',
    },
    dac6D1OtherInfo: {
      label: 'Beschreibung des Kennzeichens DAC6D1Others',
      placeholder: 'Beschreibung des Kennzeichens DAC6D1Others',
    },
    mainBenefit: {
      label: 'Durch die Gestaltung wird ein Steuervorteil erlangt',
      placeholder: 'Durch die Gestaltung wird ein Steuervorteil erlangt',
    },
    deleteDisclosure: {
      label: 'Möchten Sie die Meldung wirklich löschen?',
    },
    deleteButton: {
      label: 'Meldung löschen',
    },
  },

  xmlReportDE: {
    header: 'XML-Datensatz für BZSt Deutschland',
    elmakomHeader: 'ELMAKOM Informationen',
    accountId: {
      label: 'Portalkontonummer',
      placeholder: '10-stellige Konto-ID',
    } ,
    authTaxNumber: {
      label: 'BZSt-Nummer',
      placeholder: 'BZSt-Nummer, beginnt mit BZ der BX gefolgt von 9 Ziffern',
    },
    customerId: {
      label: 'Kundeneigene ID',
      placeholder: 'Zuordnung zum eigenen Informationssystem',
    },
  },

  /**
   * legal forms
   */
  legalForm: {
    publicLimitedCompany: 'Aktiengesellschaft',
    limitedLiabilityCompany: 'Gesellschaft mit beschränkter Haftung',
    establishment: 'Anstalt',
    foundation: 'Stiftung',
    other: 'Andere',
  },

  /**
   * assessment
   */
  assessment: {
    section: {
      intermediary: 'Intermediär',
      taxpayer: 'Kunde (Steuerzahler)',
      activity: 'Aktivität',
    },
    result: {
      intermediary_reporting_obligation_yes: 'meldepflichtig',
      intermediary_reporting_obligation_no: 'keine Meldepflicht',
    },
  },

  /**
   * warnings
   */
  warning: {
    exitWithoutSaving: {
      label:
        'Die Eingaben sind unvollständig oder noch nicht gespeichert. Wollen Sie die Seite trotzdem verlassen?',
    },
  },

  /**
   * errors
   */

  error: {
    system: {
      unexpected: 'An unexpected error occurred: ',
    },
    signUp: {
      memberName: {
        invalid: 'Bitte geben Sie Ihren Vornamen und Nachnamen ein',
      },
      email: {
        invalid: 'Bitte geben Sie eine korrekte E-Mail-Adresse ein',
      },
      password: {
        invalid:
          'Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Gross- und einen Kleinbuchstaben, eine Ziffer und ein Sonderzeichen enthalten',
      },
      passwordConfirm: {
        invalid: 'Bitte wiederholen Sie das Passwort',
      },
      language: {
        invalid: 'Bitte wählen Sie eine Sprache aus',
      },
      acceptTerms: {
        invalid: 'Bitte akzeptieren Sie die Nutzungsbestimmungen',
      },
      username: {
        alreadyInUse:
          'Der gewählte Nutzername ist bereits vergeben. Bitte wählen Sie einen anderen eindeutigen Nutzernamen.',
      },
    },
    signIn: {
      failed:
        'Die Anmeldung war leider nicht erfolgreich. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es nochmals',
    },
    recover: {
      emergencyKey: {
        invalid:
          'Bitte geben Sie Ihren Notfallschlüssel im Format XXXXX-XXXXX-XXXXX-XXXXXX-XXXXXX ein',
      },
    },
    profile: {
      password: {
        same: 'Das neue Passwort muss sich vom alten unterscheiden',
      },
    },
  },

  toaster: {
    documentUploaded: 'Dokument hochgeladen!',
    documentDeleted: 'Dokument entfernt!'
  }
}
