<script>
  import {
    AdminQueryAccountsInteractor,
    AdminUpdatePlanInteractor,
  } from '@axelity/dac6trackerjs'

  import { onMount } from 'svelte'

  // Transition
  import { fade } from 'svelte/transition'
  import { sineInOut } from 'svelte/easing'

  // Routing
  import { push } from 'svelte-spa-router'

  // I18N
  import { _, locale } from 'svelte-i18n'

  // Navigation;
  import Topnav from '../navigation/Topnav.svelte'

  // Components
  import ActionRibbon from '../components/ActionRibbon.svelte'

  // Elements
  import Page from '../elements/Page.svelte'
  import InputField from '../elements/InputField.svelte'
  import Button from '../elements/Button.svelte'

  let menuId = ''

  let accounts = []

  let fields = {
    username: '',
    password: '',
  }

  let errors = {
    username: '',
    password: '',
    system: '',
  }

  // Handler
  const handleCancel = () => {
    menuId = ''
    push('/dashboard')
  }

  const handleUpgrade = async (e) => {
    const id = e.target.dataset.key
    await new AdminUpdatePlanInteractor().execute({
      username: fields.username,
      password: fields.password,
      id,
      upgrade: true,
    })

    // Update view
    handleShowAccounts()
    handleToggleContextMenu()
  }

  const handleDowngrade = async (e) => {
    const id = e.target.dataset.key
    await new AdminUpdatePlanInteractor().execute({
      username: fields.username,
      password: fields.password,
      id,
      upgrade: false,
    })

    // Update view
    handleShowAccounts()
    handleToggleContextMenu()
  }

  const handleShowAccounts = async () => {
    accounts = await new AdminQueryAccountsInteractor($locale).execute({
      username: fields.username,
      password: fields.password,
    })
  }

  const handleToggleContextMenu = (e) => {
    if (menuId === '') {
      menuId = e.target.dataset.key
    } else {
      menuId = ''
    }
  }

  onMount(async () => {
    try {
    } catch (error) {
      errors.system = error
    }
  })
</script>

<style>
  .grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: 10% 10% 15% 15% 15% 15% 10% 5% 5%;
    background-color: transparent;
    font-size: 90%;
    padding: 0;
  }

  .grid-header {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .grid-row {
    display: flex;
    background-color: #fff;
    height: 40px;
    align-items: center;
  }

  .grid-row:hover {
    cursor: pointer;
    background-color: #fff8f3;
  }

  .right-align {
    text-align: right;
  }

  .grid-item {
    padding: 0;
    font-size: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .action-icon {
    font-size: 120%;
  }

  .dropdown {
    position: relative;
    text-align: right;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    left: -80px;
    background-color: #f1f1f1;
    width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown-content li {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown li:hover {
    background-color: #ddd;
  }

  .show-context-menu {
    display: block;
  }

  .credentials {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  .credentials .username {
    width: 35%;
  }

  .credentials .password {
    width: 35%;
  }

  .credentials .action {
    width: 20%;
    margin-top: 20px;
  }

  .good {
    color: green;
  }

  @media (max-width: 640px) {
    .grid-item {
      font-size: 80%;
    }
    .action-icon {
      font-size: 100%;
    }
  }
</style>

<Topnav />
<ActionRibbon title={$_('account.label.plural')} on:cancel={handleCancel} />

<Page>

  <div class="error">{errors.system}</div>

  <div class="credentials">

    <div class="username">
      <InputField
        type="text"
        name="username"
        label={$_('account.credentials.username')}
        placeholder={$_('account.credentials.username')}
        bind:value={fields.username}
        required={true} />
      <div class="error">{errors.username}</div>
    </div>

    <div class="password">
      <InputField
        type="password"
        name="password"
        label={$_('account.credentials.password')}
        placeholder={$_('account.credentials.password')}
        bind:value={fields.password}
        required={false} />
      <div class="error">{errors.password}</div>
    </div>

    <div class="action">
      <Button outline={false} on:click={handleShowAccounts}>
        {$_('action.show')}
      </Button>
    </div>

  </div>

  <div class="grid-container">
    <div class="grid-item grid-header">{$_('account.header.displayName')}</div>
    <div class="grid-item grid-header">{$_('account.header.accountType')}</div>
    <div class="grid-item grid-header">{$_('account.header.company')}</div>
    <div class="grid-item grid-header">{$_('account.header.contact')}</div>
    <div class="grid-item grid-header">{$_('account.header.phone')}</div>
    <div class="grid-item grid-header">{$_('account.header.email')}</div>
    <div class="grid-item grid-header">{$_('account.header.since')}</div>
    <div class="grid-item grid-header">{$_('account.header.premium')}</div>
    <div class="grid-item grid-header right-align">
      {$_('account.header.action')}
    </div>
  </div>

  {#each accounts as account}
    <div class="grid-row">
      <div class="grid-container">
        <div class="grid-item">{account.displayName}</div>
        <div class="grid-item">{account.accountType}</div>
        <div class="grid-item">{account.company}</div>
        <div class="grid-item">{account.contact}</div>
        <div class="grid-item">{account.phone}</div>
        <div class="grid-item">{account.email}</div>
        <div class="grid-item">{account.since}</div>
        <div
          class="material-icons"
          class:good={account.premium === 'verified'}>
          {account.premium}
        </div>

        <div class="dropdown">
          <div
            data-key={account.id}
            class="material-icons grid-item right-align action-icon"
            on:click={handleToggleContextMenu}>
            more_vert
          </div>
          <div
            id={`contextMenu_${account.id}`}
            class="dropdown-content"
            class:show-context-menu={menuId === account.id}>
            <li data-key={account.id} on:click={handleUpgrade}>
              {$_('action.upgrade')}
            </li>
            <li data-key={account.id} on:click={handleDowngrade}>
              {$_('action.downgrade')}
            </li>
          </div>
        </div>
      </div>
    </div>
  {:else}
    <p>{$_('account.noAccounts.label')}</p>
  {/each}

</Page>
