import App from './App.svelte'

import { State } from '@axelity/dac6trackerjs'

// Specify where to go
State.setBaseUrl('__BASE_URL__')

// All language related steps
import { addMessages, init, getLocaleFromNavigator } from 'svelte-i18n'

import en from './locales/en.js'
import de from './locales/de.js'

addMessages('en', en)
addMessages('de', de)

init({
  fallbackLocale: 'en',
  initialLocale: getLocaleFromNavigator(),
})

// instantiate the web application
const app = new App({
  target: document.body,
  props: {},
})

export default app
