<script>
  // Dispatcher
  import { createEventDispatcher, onMount, tick } from 'svelte'

  import { State } from '@axelity/dac6trackerjs'

  import {
    Umfrage,
    QuestionType,
    useIntermediaryQuestions,
    useCustomerQuestions,
    useServiceQuestions,
  } from '@axelity/umfragejs'

  // Transition
  import { fade } from 'svelte/transition'
  import { sineInOut } from 'svelte/easing'

  // I18N
  import { _ } from 'svelte-i18n'

  // Components
  import ResultComponent from '../components/ResultComponent.svelte'

  // Elements
  import InputField from '../elements/InputField.svelte'
  import Button from '../elements/Button.svelte'
  import RadioButton from '../elements/RadioButton.svelte'
  import Checkbox from '../elements/Checkbox.svelte'

  // Stores
  import { mixinTags, resultTags, restart } from '../stores/AssessmentStore'

  export let assignTo = ''
  export let locale = 'de'

  let dispatch = createEventDispatcher()

  $: $restart && initializeSurvey()

  // Parameters
  let survey
  let currentQuestion = {
    path: '',
    type: 'choice',
    required: false,
    title: '',
    description: '',
    choice: [],
  }
  let selectedChoice = ''
  let completed = false

  // Handler
  const handleChoiceSelection = (e) => {
    // e.detail returns the id of the selected option
    selectedChoice = e.detail
  }

  const handleChoiceAndNext = async (e) => {
    // e.detail returns the id of the selected option
    selectedChoice = e.detail
    showNextQuestion()
  }

  const showPreviousQuestion = () => {
    currentQuestion = Umfrage.getPreviousQuestion({
      locale,
      survey,
    })
  }

  const showNextQuestion = async () => {
    let question = Umfrage.getNextQuestion({
      currentQuestion,
      locale,
      survey,
      selectedChoiceId: selectedChoice,
    })

    currentQuestion = undefined
    await tick()
    selectedChoice = ''

    if (question) {
      currentQuestion = question
    } else {
      resultTags.set(survey.getSurveyTags())

      // Mark as completed to show result
      completed = true
      currentQuestion = undefined

      // Signal completion to parent
      dispatch('completed')
    }
  }

  const initializeSurvey = () => {
    let questions
    let labelValue

    if (assignTo === 'INTERMEDIARY') {
      questions = [...useIntermediaryQuestions]
      labelValue = $_('component.assessment.intermediary')
    } else if (assignTo === 'TAXPAYER') {
      questions = [...useCustomerQuestions]
      labelValue = $_('component.assessment.taxpayer')
    } else {
      questions = [...useServiceQuestions]
      labelValue = $_('component.assessment.service')
    }

    survey = Umfrage.createSurvey({
      locale,
      mixinTags: $mixinTags,
      questionaryLabel: labelValue,
      questions,
      sectionLabel: labelValue,
    })

    currentQuestion = Umfrage.getFirstQuestion({
      locale,
      survey,
    })

    completed = false
    $restart = false
  }

  const handleReset = () => {
    resultTags.set([])
    initializeSurvey()
  }

  onMount(() => {
    if ($resultTags && $resultTags.length > 0) {
      completed = true
    } else {
      initializeSurvey()
    }
  })
</script>

<style>
  .survey {
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .survey:before,
  .survey:after {
    content: '';
    display: table;
  }
  /* This is necessary to have covered the whole columns in the row */
  .survey:after {
    clear: both;
  }

  .question-card {
    width: 100%;
    padding: 10px;
    box-shadow: 1px 1px 5px 1px #d3d3d3;
  }

  .question-card > .header {
    padding: 10px;
    border-bottom: 1px solid #eee;
  }

  .question {
    display: grid;
    grid-template-columns: auto 10%;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    margin: 10px 0;
  }

  h2,
  h3,
  h4,
  p {
    margin: 1px;
    padding: 1px;
    color: #383432;
  }

  h4 {
    color: #888;
  }

  .question > p {
    float: left;
    font-weight: 500;
    align-self: center;
  }

  .explanation {
    margin: 10px 0;
  }

  .explanation > p {
    font-size: 80%;
    font-weight: 200;
  }

  .choice {
    border-bottom: 1px solid #eee;
    padding: 10px;
  }

  .answer {
    font-size: 100%;
    color: #383432;
  }

  .answer {
    margin: 10px 0;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }

  .action-buttons {
    display: flex;
    margin-top: 20px;
  }

  .action-buttons > div {
    margin-right: 10px;
  }
</style>

<article class="survey">
  {#if !completed}
    <div
      class="question-card"
      transition:fade={{ duration: 200, easing: sineInOut }}>
      <div class="header">
        {#if currentQuestion}
          <!-- content here -->
          <div class="question">
            {#if currentQuestion.section && currentQuestion.section !== ''}
              <div class="section">
                <h2>{currentQuestion.section}</h2>
              </div>
              <p>&nbsp;</p>
            {/if}
            {#if currentQuestion.category && currentQuestion.category !== ''}
              <div class="section">
                <h4>{currentQuestion.category}</h4>
              </div>
              <p>&nbsp;</p>
            {/if}
            <div class="title">
              <h3>{currentQuestion.id}</h3>
              <h3>{currentQuestion.title}</h3>
            </div>
            <!--i class="fas fa-check completed-sign" / -->
            <p>&nbsp;</p>
            {#if currentQuestion.description && currentQuestion.description !== ''}
              <div class="explanation">
                <p>{currentQuestion.description}</p>
              </div>
              <p>&nbsp;</p>
            {/if}
          </div>
        {/if}
      </div>
      <div class="choice">
        {#if currentQuestion}
          <!-- content here -->
          {#if currentQuestion.type === QuestionType.CHOICE}
            {#each currentQuestion.choice as choice}
              <div class="answer">
                <RadioButton
                  id={`${currentQuestion.path}#${choice.id}`}
                  name={`${currentQuestion.path}`}
                  value={choice.id}
                  checked={choice.checked}
                  on:click={handleChoiceSelection}
                  on:dblclick={handleChoiceAndNext}>
                  {choice.label}
                </RadioButton>
              </div>
            {/each}
          {:else if currentQuestion.type === QuestionType.MULTIPLE}
            {#each currentQuestion.choice as choice}
              <div class="answer">
                <Checkbox
                  id={`${currentQuestion.path}#${choice.id}`}
                  name={`${currentQuestion.path}#${choice.id}`}
                  value={choice.id}
                  checked={choice.checked}
                  on:click={handleChoiceSelection}
                  on:dblclick={handleChoiceAndNext}>
                  {choice.label}
                </Checkbox>
              </div>
            {/each}
          {:else if currentQuestion.type === QuestionType.FORM}
            {#each currentQuestion.choice as choice}
              <div class="answer">
                <InputField
                  type="text"
                  name={`${currentQuestion.path}#${choice.id}`}
                  placeholder={choice.description}
                  label={choice.label}
                  required={true} />
              </div>
            {/each}
          {/if}
        {/if}
      </div>
      <div class="actions">
        <Button outline={true} on:click={showPreviousQuestion}>
          {$_('action.back')}
        </Button>
        <Button
          disabled={selectedChoice === ''}
          outline={false}
          on:click={showNextQuestion}>
          {$_('action.next')}
        </Button>
      </div>
    </div>
  {:else}
    <!-- else content here -->
    <div
      class="question-card"
      transition:fade={{ duration: 200, easing: sineInOut }}>
      <h2>{$_('component.assessment.done')}</h2>
      <ResultComponent {assignTo} {locale} />

      <div class="action-buttons">
        <div>
          <Button outline={false} on:click={handleReset}>{$_('action.reset')}</Button>
        </div>
      </div>
    </div>
  {/if}
</article>
