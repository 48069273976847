<script>
  import { State } from '@axelity/dac6trackerjs'

  // Transition
  import { fade } from 'svelte/transition'
  import { sineInOut } from 'svelte/easing'

  // Routing
  import { replace } from 'svelte-spa-router'

  // I18N
  import { _ } from 'svelte-i18n'

  // Navigation;
  import Topnav from '../navigation/Topnav.svelte'

  // Components
  import AccountTile from '../components/AccountTile.svelte'
  import PersonTile from '../components/PersonTile.svelte'
  import ArrangementTile from '../components/ArrangementTile.svelte'
  import DisclosureTile from '../components/DisclosureTile.svelte'

  let memberName = ''
  if (State && State.member && State.member.memberName) {
    memberName = State.member.memberName
  }

  if (!State.isAuthenticated) {
    replace('/signin')
  }
</script>

<style>
  .page {
    width: 100%;
    display: block;
    padding: 5%;
    padding-top: 100px;
  }

  .tile-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
    margin: 3rem 0;
  }

  @media only screen and (max-width: 768px) {
    .tile-list {
      grid-template-columns: repeat(1, 1fr);
      gap: 3rem;
    }
  }
</style>

<Topnav />
<div class="page" transition:fade={{ duration: 200, easing: sineInOut }}>
  <div class="heading">
    <h4>{$_('section.dashboard.header.label')}</h4>
    <h1>{memberName}</h1>
  </div>
  <div class="tile-list">
    {#if State && State.context}
      <PersonTile />
      <ArrangementTile />
      <DisclosureTile />
    {/if}
    <AccountTile />
  </div>
</div>
