<script>
  import { createEventDispatcher } from 'svelte'

  export let direction = 'right'

  const dispatch = createEventDispatcher()

  const handleClick = (e) => {
    e.preventDefault()
    dispatch('click')
  }
</script>

<style>
  i {
    border: solid #ec6907;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
  }

  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
</style>

<i
  class:right={direction === 'right'}
  class:left={direction === 'left'}
  class:up={direction === 'up'}
  class:down={direction === 'down'}
  on:click={handleClick} />
