<script>
  import { State } from '@axelity/dac6trackerjs'

  // I18N
  import { _ } from 'svelte-i18n'

  // Routing
  import { push } from 'svelte-spa-router'

  // Parameters
  export let route = '/account'
  export let icon = 'domain'

  // Handler
  const routeTo = (e) => {
    push(e.target.dataset.route)
  }
</script>

<style>
  .tile {
    width: 100%;
    height: 150px;
    box-shadow: 0 .5rem 1rem #00000026;
    border-radius: 6px;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .tile:hover {
    background-color: #fff8f3;
    cursor: pointer;
  }

  .tile h4,
  p {
    margin: 0;
    padding: 0;
  }

  .tile h4 {
    color: #aaa;
  }

  .col-left {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  .col-right {
    width: 100%;
    padding: 0 10px;
  }

  .col-right h4 {
    text-transform: uppercase;
  }

  .col-right p {
    font-size: 200%;
    padding: 5px 0;
  }

  .circle {
    display: flex;
    width: 50px;
    height: 50px;
    background-color: #ec6907;
    color: #fff;
    justify-content: center;
    align-items: center;
    border-radius: 45px;
  }

  .circle > div {
    font-size: 200%;
  }

  .notice {
    font-size: 100%;
    padding: 5px 0;
  }

  .action-icon {
    margin: 0;
    padding: 0;
    display: block;
    float: right;
    cursor: pointer;
    visibility: hidden;
  }
</style>

<div class="tile" on:click={routeTo} data-route={route}>
  <div data-route={route} class="col-left">
    <div class="circle">
      <div data-route={route} class="material-icons">{icon}</div>
    </div>
  </div>
  <div data-route={route} class="col-right">
    <div data-route={route} id="editAction" class="material-icons action-icon">
      more_horiz
    </div>
    <h4 data-route={route}>{$_('dashboard.account.title')}</h4>
    {#if State.account}
      <p data-route={route}>{State.account.naming.displayName}</p>
    {:else}
      <div data-route={route} class="notice">
        {$_('dashboard.account.empty')}
      </div>
    {/if}
  </div>
</div>
