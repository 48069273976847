<script>
  export let outline = false
  export let href = 'javascript:void(0);'
  export let type = 'button'
  export let disabled = false
  export let fullWidth = false

  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  const buttonClicked = (e) => {
    e.preventDefault()
    dispatch('click')
  }
</script>

<style>
  .btn {
    display: inline-block;
    padding: 10px 15px;
    font-weight: 300;
    font-size: 120%;
    text-decoration: none;
    border-radius: 0px;
    color: #fff;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
  }

  .btn-full-width {
    width: 100%;
  }

  .btn:focus {
    outline: none;
  }

  .btn:link,
  .btn:visited {
    display: inline-block;
    padding: 10px 15px;
    font-weight: 300;
    font-size: 120%;
    text-decoration: none;
    border-radius: 0px;
    color: #fff;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
  }

  .btn:hover,
  .btn:active {
    background-color: #f9842d;
  }

  .btn-full {
    background-color: #ec6907;
    border: 1px solid #ec6907;
    color: #fff;
  }

  .btn-full:link,
  .btn-full:visited {
    background-color: #ec6907;
    border: 1px solid #ec6907;
    color: #fff;
  }

  .btn-full:hover,
  .btn-full:active {
    background-color: #f9842d;
    border: 1px solid #f9842d;
    color: #fff;
    cursor: pointer;
  }

  .btn-full:disabled {
    background-color: #c1bcb9;
    border: 1px solid #c1bcb9;
    color: #fff;
    cursor: default;
  }

  .btn-outline {
    border: 1px solid #ec6907;
    background-color: #fff;
    color: #ec6907;
  }

  .btn-outline:disabled {
    border: 1px solid #c1bcb9;
    color: #c1bcb9;
    cursor: default;
  }

  .btn-outline:link,
  .btn-outline:visited {
    border: 1px solid #ec6907;
    color: #ec6907;
  }

  .btn-outline:hover,
  .btn-outline:active {
    border: 1px solid #ec6907;
    background-color: #fff8f3;
    color: #ec6907;
    cursor: pointer;
  }

  @media screen and (max-width: 540px) {
    .btn {
      font-size: 100%;
    }
  }
</style>

<button
  class="btn"
  class:btn-full={!outline}
  class:btn-outline={outline}
  class:btn-full-width={fullWidth}
  {type}
  {disabled}
  {href}
  on:click={buttonClicked}>
  <slot />
</button>
