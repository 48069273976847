<script>
  // Transition
  import { fade } from 'svelte/transition'
  import { sineInOut } from 'svelte/easing'

  // Navigation;
  import Topnav from '../navigation/Topnav.svelte'

  // Components
  import ActionRibbon from '../components/ActionRibbon.svelte'

  // Handler
  const handleCancel = () => {
    window.close()
  }
</script>

<style>
  .page {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 100px 0 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
  }

  h1 {
    text-align: left;
    padding-top: 5%;
  }

  h2 {
    text-align: left;
    color: #5c5552;
    font-weight: 500;
    padding-top: 2%;
  }

  h3 {
    text-align: left;
    color: #5c5552;
    font-weight: 500;
    padding-top: 1%;
  }

  ul {
    list-style-position: outside;
  }

  .page-content {
    width: 100%;
    padding: 0 5% 5% 5%;
  }
</style>

<Topnav />
<ActionRibbon title="" actionDisabled={false} on:cancel={handleCancel} />

<div class="page" transition:fade={{ duration: 200, easing: sineInOut }}>
  <div class="page-content">
    <h1>User agreement</h1>
    <h2>1 Conclusion and parties</h2>
    <p>
      This contract for the use of the DAC6 Tracker (hereinafter "Contract") is concluded between
    </p>
    <p>
      Red Leafs lnnoTax AG (CHE-451.497.742), Reichsgasse 65, 7000 Chur
      (hereinafter 
      <strong>"Red Leafs"</strong>
      )
    </p>
    <p>and</p>
    <p>
      of the company you represent (hereinafter referred to as 
      <strong>"Customer"</strong>
      ),
    </p>
    <p>
      as soon as you check the box "I agree to the contract for the use of the DAC6 Tracker".
    </p>
    <p>
      (Red Leafs and the Customer individually hereinafter referred to as 
      <strong>"Party"</strong>
      and collectively hereinafter referred to as
      <strong>"Parties"</strong>
      )
    </p>
    <h2>2 Services</h2>
    <h3>2.1 Background</h3>
    <p>
      he EU has adopted Directive 2018/822 of 25 May 2018 amending Directive 2011/16/EU with regard to the mandatory automatic exchange of information in the field of taxation on notifiable cross-border arrangements (hereinafter
      <strong>"EU Directive DAC6"</strong>
      ).
    </p>
    <p>
      With the EU Directive DAC6, the EU wants to create tax transparency for the EU Member states and requires a notification if certain hallmarks are given. The audit procedures, notifications or non-notifications must be fully documented.
    </p>
    <p>
      The individual EU states must implement the EU Directive DAC6 into national law.
    </p>
    <p>
      Red Leafs operates a web application called DAC6 Tracker (hereinafter referred to as "DAC6 Tracker"), which enables the customer to manage services for her customers, determine any reporting requirements, document the designs and, if necessary, send reports.
    </p>
    <h3>2.2 Data collection and management</h3>
    <p>
      The customer can record and manage the following data on customers, services and arrangements:
    </p>
    <ul>
      <li>
        Answers (usually yes and no) to questions of various categories at the level of intermediary, client and services
      </li>
      <li>Organization name and legal form</li>
      <li>Address details</li>
      <li>
        Personal details (including first name, surname, date of birth, place of birth or home, email address)
      </li>
      <li>Language settings</li>
      <li>
        Data required to submit the reports, including tax identification numbers, currencies and amounts (financial data), countries involved and/or receiving countries, dates of implementation, justifications and summaries, persons involved and/or affected, hallmark contract names
      </li>
    </ul>
    <h3>2.3 Review, results and reporting</h3>
    <p>
      The DAC6 Tracker can be used as a free, restricted version or as an unlimited full version
    </p>
    <p>
      With both versions, i.e. with the free and with the full version:
    </p>
    <ul>
      <li>
        the DAC6 Tracker checks the data collected from the customer with regard to characteristics of the EU DAC6 directive, but not the (implementation) legislation of the individual EU states. This results in non-binding information about the existence of characteristics according to which the transaction may have to be reported under national (implementation) legislation, or that no such characteristics are apparent.
      </li>
    </ul>
    <p>
      This information does not mean that the transaction has to be reported or not reported in accordance with EU Directive DAC6. The DAC6 Tracker merely offers the customer the opportunity to document that she has complied with her obligation to check designs according to a specific procedure.
    </p>
    <p>With the full version:</p>
    <ul>
      <li>
        the check and its results are stored in encrypted form as machine-interpretable data and can be downloaded by the customer during the contract period as a PDF document or as structured data.
      </li>
      <li>
        the DAC6 Tracker provides the basis for the customer to transfer the recorded data directly into the registration form. The prerequisite for this is that the information required for the design is recorded truthfully and completely.
      </li>
    </ul>
    <h2>3 Customer data and/or user data</h2>
    <h3>3.1 Definition</h3>
    <p>
      <strong>"Customer data"</strong>
      is data that relates to the customer or the customer's clientele or is processed by the customer in connection with the use of the DAC6 Tracker. A list of customer data can be found in section 2.2, Data collection and management.
    </p>
    <p>
      <strong>"User data"</strong>
      is data relating to a person using the DAC6 Tracker, such as first name, last name, e-mail address, user name, password and language.
    </p>
    <h3>3.2 Data Compliance and Indemnification</h3>
    <p>The parties may process data lawfully.</p>
    <p>
      The customer assures that she is authorised to process the customer data and user data for the purpose of using the DAC6 Tracker.
    </p>
    <p>
      The customer assures that the customer data and/or user data are collected and processed in a lawful manner and that no legal regulations or rights of third parties are violated. The customer is aware that Red Leafs does not and cannot check, validate or change customer data and/or user data.
    </p>
    <p>
      The customer shall indemnify Red Leafs on first demand and in full against all claims by third parties and the costs and expenses (including reasonable attorney's fees) incurred by Red Leafs as a result of the customer's unlawful processing of data, violation of statutory provisions or rights of third parties.
    </p>
    <h3>3.3 Encryption and emergency keys</h3>
    <p>
      The customer's data relating to her clientele are stored in encrypted form. The encryption takes place in the customer's browser. The user key is known exclusively to the customer and must be treated confidentially. With this end-to-end encryption it is impossible for Red Leafs or the platform provider to evaluate the customer data.
    </p>
    <p>
      If the customer forgets the user key with which the customer can decrypt the encrypted data, this data can only be reconstructed with the unique emergency key. The emergency key is displayed once after registration. The customer must treat this emergency key confidentially and keep it in a safe place. Without this emergency key, access to the DAC6 Tracker can no longer be restored and the stored data is irretrievably lost. In this case Red Leafs cannot provide assistance.
    </p>
    <h3>3.4 Data storage</h3>
    <p>
      The customer data is stored in a data repository of a computer centre of the platform operator, which can be selected by the customer. User data as well as other metadata for the administration and allocation of the data are kept in a computer centre in Switzerland.
    </p>
    <h3>3.5 Data processing</h3>
    <p>Red Leafs only processes personal data of the customer:</p>
    <ul>
      <li>
        to fulfil their contractual or legal obligations (Art. 4 para. 3 and 13 para. 2 let. a DSG, Art. 6 para. 1 let. b and c DSGVO);
      </li>
      <li>
        to send information about their new services (Art. 13 para. 2 DPA and Art. 6 para. 1 let. f DPAO);
      </li>
      <li>
        manage the DAC6 Tracker and ensure the error-free provision of the DAC6 Tracker (Art. 13 para. 2 DSG and Art. 6 para. 1 let. f DSGVO).
      </li>
    </ul>
    <p>
      Red Leafs does not process any personal data of the customer's clientele, as these data are exclusively encrypted.
    </p>
    <h3>3.6 Availability of customer data and user data</h3>
    <p>
      Red Leafs will use commercially reasonable care to ensure that customer and/or user information is available at all times, with the exception of planned downtime. Red Leafs notifies the customer of such planned downtime via the possible channels, e.g. e-mail or publication on the DAC6 Tracker start/login page.
    </p>
    <p>
      Red Leafs 
      <strong>does not</strong>
      guarantee that customer and/or user data is always available, as this depends, among other things, on the availability of the platform operator on which the DAC6 Tracker is operated, the connection to the Internet and the functionality of the device used.
    </p>
    <h3>3.7 Data retrieval and data transfer</h3>
    <p>
      The customer can retrieve or take over ("data retrieval") the customer data during the operating hours of the DAC6 Tracker.
    </p>
    <h2>4 Confidentiality and security</h2>
    <h3>4.1 Confidentiality</h3>
    <p>
      Each Party receiving confidential information from the other Party shall
    </p>
    <ul>
      <li>to keep confidential information confidential;</li>
      <li>
        not to disclose confidential information to any third party without the prior consent of the other party; and
      </li>
      <li>
        not to use confidential information for any purpose other than the performance of this Agreement.
      </li>
    </ul>
    <p>
      The Party receiving Confidential Information may disclose the Confidential Information to its employees if and to the extent that such employees have signed an appropriate confidentiality agreement and they need to know the Confidential Information in order to perform this Agreement. The Party receiving Confidential Information shall be liable for its employees' compliance with this obligation of confidentiality.
    </p>
    <h3>4.2 Exceptions</h3>
    <p>
      The provisions on confidentiality shall not apply to confidential information which
    </p>
    <ul>
      <li>
        are not processed in encrypted form, although the DAC6 Tracker provides encryption; or
      </li>
      <li>
        were already lawfully and unreservedly known to the party receiving confidential information at the time of receipt; or
      </li>
      <li>
        the Party receiving Confidential Information, after disclosure by the other Party, has been lawfully transmitted by an independent third party without restriction and without breach of this Agreement; or
      </li>
      <li>
        are or become publicly known without any unlawful act or omission or any act contrary to the terms of the contract by one of the parties; or
      </li>
      <li>
        are independently developed by one party without reference to any confidential information of the other party
      </li>
    </ul>
    <h3>4.3 Security</h3>
    <p>The Parties shall implement appropriate security measures to</p>
    <ul>
      <li>
        to ensure the confidentiality and integrity of confidential information
      </li>
      <li>
        prevent unauthorised access to or use of such confidential information.
      </li>
    </ul>
    <h2>5 Using the DAC6 Tracker</h2>
    <h3>5.1 Use of intellectual property rights</h3>
    <p>
      To the extent necessary for the use of the DAC6 Tracker (free version and full version), Red Leafs hereby grants the customer a limited, revocable, non-exclusive and non-transferable right to use Red Leafs' intellectual property rights for the duration of this agreement.
    </p>
    <h3>5.2 Using the full version of the DAC6 Tracker</h3>
    <p>
      Use of the full version of the DAC6 Tracker is granted for a fixed period of one year and is automatically extended for a further year, unless the customer terminates the use of the full version with a 3-month notice period.
    </p>
    <h3>5.3 Technical requirements</h3>
    <p>
      To use the DAC6 Tracker, the following technical requirements must be met:
    </p>
    <ul>
      <li>
        the latest two versions of Google Chrome, Mozilla Firefox or Apple Safari (macOS) must be installed Microsoft Edge can only be used to a limited extent. Older browsers, such as Internet Explorer, are not supported;
      </li>
      <li>Activation of Javascript in the browser;</li>
      <li>Switch off the incognito mode;</li>
      <li>Adequate, uninterrupted Internet connection.</li>
    </ul>
    <h3>5.4 User accounts</h3>
    <p>
      To use the DAC6 Tracker, the customer must create one or more user accounts. The free version allows to create a single user account, while the full version allows to create several user accounts as members of teams.
    </p>
    <p>
      Only employees of the customer may create a user account. In particular, employees of the customer's clientele are not allowed to create user accounts or use the DAC6 Tracker. For each user account, the customer must register an e-mail address, choose a unique username and enter a strong password. Each user needs a separate user account to use the DAC6 Tracker.
    </p>
    <p>
      The customer or user must choose a secure password that meets the usual security standards, change it regularly and keep it confidential.
    </p>
    <h3>5.5 Prohibited use of the DAC6 Tracker</h3>
    <p>The DAC6 Tracker must not be used to</p>
    <ul>
      <li>to violate legal regulations;</li>
      <li>
        interfere with a third party computer, service, system, device or technology
      </li>
      <li>
        violate the rights of third parties, such as the right to privacy or the intellectual property of third parties;
      </li>
      <li>
        import or distribute malicious software (e.g. scripts, viruses, malware, Trojans)
      </li>
      <li>to grant third parties access to the DAC6 Tracker;</li>
      <li>
        To compete with Red Leafs by using a user account for commercial purposes outside the scope of Red Leafs.
      </li>
    </ul>
    <h3>5.6 Blocking access to the DAC6 Tracker</h3>
    <p>
      If the customer does not process the customer data and/or the user data in accordance with the contract or the law, or if the customer violates the rights of third parties, or if Red Leafs has reason to believe that the customer does not process the customer data and/or the user data in accordance with the contract or the law, or if the customer violates the rights of third parties, Red Leafs is entitled to immediately block the customer's or their employees' access to the DAC6 Tracker.
    </p>
    <p>
      If the customer does not pay the annual fee within the payment period (see clauses 6.1 and 6.3) Red Leafs is entitled to block the customer's access to the full version of the DAC6 Tracker.
    </p>
    <h3>5.7 Availability of the DAC6 Tracker</h3>
    <p>
      Red Leafs uses commercially reasonable care to ensure that the DAC6 Tracker is available at all times, with the exception of planned or previously announced downtime. Red Leafs notifies the customer of such planned downtime via the possible channels, e.g. e-mail or publication on the DAC6 Tracker start/login page.
    </p>
    <p>
      Red Leafs
      <strong>does not</strong>
      guarantee that the DAC6 Tracker will be available at all times.
    </p>
    <h2>6 Fee for using the DAC6 Tracker</h2>
    <h3>6.1 Annual fee for the full version of the DAC6 Tracker</h3>
    <p>
      For the use of the full version of the DAC6 Tracker, the customer undertakes to pay an annual fee of EUR 4,800 in advance.
    </p>
    <p>
      On payment of the annual fee, the DAC6 Tracker can be used without restriction and the customer's employees can set up and use as many customer accounts as they wish.
    </p>
    <h3>6.2 Taxes and duties</h3>
    <p>
      All prices are exclusive of value added tax, sales tax, transaction tax, withholding tax or any other tax or duty of this kind in any jurisdiction. If any such tax or duty is legally owed by Red Leafs, Red Leafs will charge it additionally. Where such a tax or duty is due by a customer under applicable law (e.g. reverse charge), the customer will be responsible for billing and paying the tax.
    </p>
    <h3>6.3 Methods of payment</h3>
    <p>
      The customer undertakes to pay the invoices within 30 days of receipt.
    </p>
    <h2>7 Training and support</h2>
    <p>
      Upon request, Red Leafs will provide training and support to the customer in connection with the EU DAC6 directive and the use of the DAC6 Tracker. These services will be invoiced separately.
    </p>
    <h2>8 Limitation of liability</h2>
    <p>
      To the extent permitted by law, the liability of Red Leafs is excluded.
    </p>
    <p>
      Upon request, Red Leafs will provide training and support to the customer in connection with the EU DAC6 directive and the use of the DAC6 Tracker. These services will be invoiced separately.
    </p>
    <p>
      The client expressly acknowledges that the audit procedures of the DAC6 Tracker and the documentation are for information purposes only and are in no way a substitute for individual legal and tax advice. Furthermore, the Client acknowledges and agrees that the DAC6 Tracker does not include any auditing action with regard to the (implementation) legislation of individual EU countries.
    </p>
    <p>
      Red Leafs is expressly not liable if the customer's encrypted data can no longer be reconstructed.
    </p>
    <h2>9 Duration of contract and termination</h2>
    <h3>9.1 Duration of contract</h3>
    <p>
      This contract comes into force at the moment the customer clicks on the box "I agree to the contract for the use of the DAC6 Tracker" in the DAC6 Tracker. It is valid for an indefinite period of time and can be terminated with a notice period of three months.
    </p>
    <p>
      The useful life of the full version of the DAC6 Tracker is regulated in Section 5.2.
    </p>
    <h3>9.2 Termination for good cause</h3>
    <p>
      If one party breaches the contract, the other party may at its option either adhere to the contract and waive performance or terminate the contract at any time with immediate effect if the other party breaches the contract and does not remedy the breach within 14 days of notification by the party not in breach of the contract.
    </p>
    <p>
      In the event of insolvency of one party, the opening of bankruptcy proceedings or a composition agreement or similar circumstances, the other party may terminate this agreement with immediate effect.
    </p>
    <h3>9.3 Consequences of termination</h3>
    <p>
      Upon termination of the contract, the right to use the DAC6 Tracker expires and the customer loses access to the encrypted data. The customer must therefore transfer the customer data before termination of the contract, otherwise Red Leafs is entitled to delete this data.
    </p>
    <p>
      Red Leafs will not refund any annual fees if the customer cancels the contract before the end of the full version's usage period. The same applies if Red Leafs terminates the contract for good cause.
    </p>
    <h2>10 Applicable law and place of jurisdiction</h2>
    <p>
      This contract shall be governed by Swiss substantive law, excluding its conflict of laws rules. The exclusive place of jurisdiction is at the registered office of Red Leafs.
    </p>
  </div>
</div>
