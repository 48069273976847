<script>
  import {
    State,
    QueryDisclosuresInteractor,
    FullReport,
    SummaryReport,
    UseDisclosures,
    ROBOTO_FONTS,
  } from '@axelity/dac6trackerjs'
  import pdfMake from 'pdfmake/build/pdfmake'

  import { onMount } from 'svelte'

  // Transition
  import { fade } from 'svelte/transition'
  import { sineInOut } from 'svelte/easing'

  // Routing
  import { push, pop, replace } from 'svelte-spa-router'

  // I18N
  import { _ } from 'svelte-i18n'

  // Time
  import moment from 'moment'

  // Navigation;
  import Topnav from '../navigation/Topnav.svelte'

  // Components
  import ActionRibbon from '../components/ActionRibbon.svelte'

  // Elements
  import SearchField from '../elements/SearchField.svelte'
  import Loader from '../elements/Loader.svelte'

  if (!State.isAuthenticated) {
    replace('/signin')
  }

  let menuId = ''

  let disclosures = []
  let disclosuresAll = [] // used for reset filter only

  let sortByNameAsc = false
  let sortByTaxpayerAsc = false
  let sortByDateAsc = false

  let searchArgument = ""

  let errors = {
    system: '',
  }

  let inProcess = false

  // Handler
  const handleCancel = () => {
    menuId = ''
    pop()
  }

  const handleNew = () => {
    State.setCurrentDisclosure(undefined)
    menuId = ''
    push('/disclosure')
  }

  const handleEdit = (e) => {
    menuId = ''
    State.setCurrentDisclosure(State.getDisclosure(e.target.dataset.key))
    push('/disclosure')
  }

  const handleSummary = (e) => {
    menuId = ''
    State.setCurrentDisclosure(State.getDisclosure(e.target.dataset.key))

    const dd = new SummaryReport().create({
      disclosure: State.currentDisclosure,
      locale: State.locale,
    })
    pdfMake.fonts = ROBOTO_FONTS

    const pdfDocGenerator = pdfMake.createPdf(dd)
    pdfDocGenerator.getBlob((blob) => {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `${State.currentDisclosure.messageId}_${$_(
          'disclosure.report.summary'
        )}.pdf`
      )
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }

  const handleFullReport = (e) => {
    menuId = ''
    State.setCurrentDisclosure(State.getDisclosure(e.target.dataset.key))

    const dd = new FullReport().create({
      disclosure: State.currentDisclosure,
      locale: State.locale,
    })
    pdfMake.fonts = ROBOTO_FONTS

    const pdfDocGenerator = pdfMake.createPdf(dd)
    pdfDocGenerator.getBlob((blob) => {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `${State.currentDisclosure.messageId}_${$_(
          'disclosure.report.full'
        )}.pdf`
      )
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }

  const handleXMLdeRecord = (e) => {
    menuId = ''
    State.setCurrentDisclosure(State.getDisclosure(e.target.dataset.key))
    push('/xmlderecord')
  }

  const handleToggleContextMenu = (e) => {
    if (menuId === '') {
      menuId = e.target.dataset.key
    } else {
      menuId = ''
    }
  }

  const sortByNameHandler = () => {
    if (sortByNameAsc) {
      // Ascending
      sortByNameAsc = false
      disclosures = disclosures.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1
        }
        return 0
      })
    } else {
      // Descending
      sortByNameAsc = true
      disclosures = disclosures.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return -1
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return 1
        }
        return 0
      })
    }
  }

  const sortByTaxpayerHandler = () => {
    if (sortByTaxpayerAsc) {
      // Ascending
      sortByTaxpayerAsc = false
      disclosures = disclosures.sort((a, b) => {
        if (a.taxpayer.toLowerCase() > b.taxpayer.toLowerCase()) {
          return 1
        }
        if (a.taxpayer.toLowerCase() < b.taxpayer.toLowerCase()) {
          return -1
        }
        return 0
      })
    } else {
      // Descending
      sortByTaxpayerAsc = true
      disclosures = disclosures.sort((a, b) => {
        if (a.taxpayer.toLowerCase() > b.taxpayer.toLowerCase()) {
          return -1
        }
        if (a.taxpayer.toLowerCase() < b.taxpayer.toLowerCase()) {
          return 1
        }
        return 0
      })
    }
  }

  const handleSearch = () => {
    if (searchArgument !== "") {
      disclosures = disclosuresAll.filter((p) => {
        if (p.name.toLowerCase().includes(searchArgument.toLowerCase()) || p.taxpayer.toLowerCase().includes(searchArgument.toLowerCase())) {
          return true
        } else {
          return false
        }
      })
    } else {
      disclosures = [...disclosuresAll]
    }
  }

  const sortByDateHandler = () => {
    if (sortByDateAsc) {
      // Ascending
      sortByDateAsc = false
      disclosures = disclosures.sort((a, b) => {
        if (a.implementingDate === "") {
          return 1
        }
        if (moment(a.implementingDate, 'DD.MM.YYYY').toDate().getTime() > moment(b.implementingDate, 'DD.MM.YYYY').toDate().getTime()) {
          return 1
        }
        if (moment(a.implementingDate, 'DD.MM.YYYY').toDate().getTime() < moment(b.implementingDate, 'DD.MM.YYYY').toDate().getTime()) {
          return -1
        }
        return 0
      })
    } else {
      // Descending
      sortByDateAsc = true
      disclosures = disclosures.sort((a, b) => {
        if (a.implementingDate === "") {
          return 1
        }
        if (moment(a.implementingDate, 'DD.MM.YYYY').toDate().getTime() > moment(b.implementingDate, 'DD.MM.YYYY').toDate().getTime()) {
          return -1
        }
        if (moment(a.implementingDate, 'DD.MM.YYYY').toDate().getTime() < moment(b.implementingDate, 'DD.MM.YYYY').toDate().getTime()) {
          return 1
        }
        return 0
      })
    }
  }

  const handleRenew = async () => {
    try {
      inProcess = true
      await new QueryDisclosuresInteractor().execute()
      disclosures = new UseDisclosures().list()
      sortByNameAsc = true
      sortByNameHandler()
      disclosuresAll = [...disclosures]
    } catch (error) {
      console.log(error.message)
    } finally {
      inProcess = false
    }
  }

  onMount(async () => {
    try {
      // await new QueryDisclosuresInteractor().execute()
      disclosures = new UseDisclosures().list()
      sortByNameAsc = true
      sortByNameHandler()
      disclosuresAll = [...disclosures]
    } catch (error) {
      errors.system = error
    }
  })
</script>

<style>
  .page {
    width: 100%;
    display: block;
    padding: 5%;
    padding-top: 150px;
  }

  .grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: 30% 30% 30% 10%;
    background-color: transparent;
    padding: 0;
  }

  .search {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    align-items: center;
  }

  .search-field {
    width: 100%;
  }

  .renew-button {
    cursor: pointer;
    font-size: 150%;
    margin-left: 20px;
  }

  .grid-header {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .grid-header:hover {
    cursor: pointer;
  }

  .grid-row {
    display: flex;
    background-color: #fff;
    height: 40px;
    align-items: center;
  }

  .grid-row:hover {
    cursor: pointer;
    background-color: #fff8f3;
  }

  .right-align {
    text-align: right;
  }

  .grid-item {
    padding: 0;
    font-size: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .action-icon {
    font-size: 120%;
  }

  .dropdown {
    position: relative;
    text-align: right;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    left: -170px;
    background-color: #f1f1f1;
    width: fit-content;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown-content li {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown li:hover {
    background-color: #ddd;
  }

  .show-context-menu {
    display: block;
  }

  .error {
    font-weight: bold;
    color: red;
  }

  @media (min-width: 640px) {
    .page {
      max-width: none;
    }
  }

  @media (max-width: 640px) {
    .page {
      margin-bottom: 100px;
    }
    .grid-item {
      font-size: 80%;
    }
    .action-icon {
      font-size: 100%;
    }
  }
</style>

<Topnav />
<ActionRibbon
  title={$_('section.disclosures.header.label')}
  actionLabel={$_('action.createDisclosure')}
  actionIcon="add"
  on:cancel={handleCancel}
  on:action={handleNew} />

<div class="page" transition:fade={{ duration: 200, easing: sineInOut }}>

  <div class="error">{errors.system}</div>

  <div class="search">
    <div class="search-field">
      <SearchField
        name="searchField"
        placeholder=""
        bind:value={searchArgument}
        on:input={handleSearch} />
    </div>
    <div
      class="material-icons renew-button"
      on:click={handleRenew}>
      autorenew
    </div>
  </div>

  <div class="grid-container">
    <div class="grid-item grid-header" on:click={sortByNameHandler}>{$_('disclosure.header.name')}</div>
    <div class="grid-item grid-header" on:click={sortByTaxpayerHandler}>{$_('disclosure.header.taxpayer')}</div>
    <div class="grid-item grid-header right-align" on:click={sortByDateHandler}>
      {$_('disclosure.header.implementingDate')}
    </div>
    <div class="grid-item grid-header right-align">
      {$_('disclosure.header.action')}
    </div>
  </div>

  {#each disclosures as disclosure}
    <div class="grid-row">
      <div class="grid-container">
        <div class="grid-item">{disclosure.name}</div>
        <div class="grid-item">{disclosure.taxpayer}</div>
        <div class="grid-item right-align">{disclosure.implementingDate}</div>

        <div class="dropdown">
          <div
            data-key={disclosure.id}
            class="material-icons grid-item right-align action-icon"
            on:click={handleToggleContextMenu}>
            more_vert
          </div>
          <div
            id={`contextMenu_${disclosure.id}`}
            class="dropdown-content"
            class:show-context-menu={menuId === disclosure.id}>
            <li data-key={disclosure.id} on:click={handleEdit}>
              {$_('action.edit')}
            </li>
            <li data-key={disclosure.id} on:click={handleSummary}>
              {$_('action.createSummary')}
            </li>
            <li data-key={disclosure.id} on:click={handleFullReport}>
              {$_('action.createFullReport')}
            </li>
            <li data-key={disclosure.id} on:click={handleXMLdeRecord}>
              {$_('action.createXMLde')}
            </li>
          </div>
        </div>
      </div>
    </div>
  {:else}
    <p>{$_('section.disclosures.empty')}</p>
  {/each}
</div>

{#if inProcess}
  <Loader />
{/if}
