<script>
  import { State } from '@axelity/dac6trackerjs'

  // Transition
  import { fade } from 'svelte/transition'
  import { sineInOut } from 'svelte/easing'

  // I18N
  import { _ } from 'svelte-i18n'

  // Routing
  import { push } from 'svelte-spa-router'

  // Stores
  import { showSidebar } from '../stores/AppStore'

  const routeTo = async (e) => {
    closeSidebar()
    push(e.target.dataset.route)
  }

  const closeSidebar = () => {
    showSidebar.set(false)
  }
</script>

<style>
  .sidebar {
    width: 100%; /* Full width */
    height: 100%; /* Full height */
  }

  .sidebar-modal {
    display: flex; /* center the content */
    justify-content: center; /* ...horizontally */
    align-items: center; /* ...vertically */
    position: fixed; /* Stay in place */
    z-index: 11; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
  }

  .sidebar-menulist {
    height: 100%; /* full height */
    width: 250px;
    position: fixed; /* stay in place */
    z-index: 11; /* on top of modal */
    top: 0;
    left: 0;
    background-color: #fff;
    overflow-x: hidden; /* trim text on the right */
    padding-top: 60px;
    transition: 0.3s;
  }

  .sidebar-menulist li {
    padding: 15px 8px;
    text-decoration: none; /* hide underline */
    font-size: 100%;
    display: grid;
    grid-template-columns: 40px auto;
    cursor: pointer;
    white-space: nowrap; /* avoid line break when expand/collapse */
    transition: 0.2s;
  }

  .sidebar-menulist li:hover {
    background-color: #fff8f3;
  }

  .sidebar-menulist .closebtn {
    position: absolute;
    color: #383432;
    top: 0;
    right: 25px;
    font-size: 180%;
    margin-left: 50px;
    text-decoration: none;
  }

  .closebtn {
    cursor: pointer;
  }

  .bottom-items {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
</style>

{#if $showSidebar}
  <div class="sidebar" transition:fade={{ duration: 200, easing: sineInOut }}>
    <div class="sidebar-modal" on:click={closeSidebar} />

    <div class="sidebar-menulist">
      <div class="closebtn" on:click={closeSidebar}>&times;</div>
      {#if State.isAuthenticated}
        <li on:click={routeTo} data-route="/dashboard">
          <span data-route="/dashboard" class="material-icons">dashboard</span>
          {$_('menu.dashboard')}
        </li>
        {#if State.account}
          <li on:click={routeTo} data-route="/persons">
            <span data-route="/persons" class="material-icons">
              corporate_fare
            </span>
            {$_('menu.persons')}
          </li>
          <li on:click={routeTo} data-route="/arrangements">
            <span data-route="/arrangements" class="material-icons">work</span>
            {$_('menu.arrangements')}
          </li>
          <li on:click={routeTo} data-route="/disclosures">
            <span data-route="/disclosures" class="material-icons">send</span>
            {$_('menu.disclosures')}
          </li>
        {/if}
        <div class="bottom-items">
          {#if State.member}
            <li on:click={routeTo} data-route="/profile">
              <span data-route="/profile" class="material-icons">
                account_circle
              </span>
              {$_('menu.profile')}
            </li>
          {/if}
          <li on:click={routeTo} data-route="/account">
            <span data-route="/account" class="material-icons">domain</span>
            {$_('menu.account')}
          </li>
          <li on:click={routeTo} data-route="/signoff">
            <span data-route="/" class="material-icons">exit_to_app</span>
            {$_('menu.signOff')}
          </li>
        </div>
      {/if}
    </div>
  </div>
{/if}
