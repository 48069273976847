<script>
  import { State } from '@axelity/dac6trackerjs'

  // Transition
  import { fade } from 'svelte/transition'
  import { sineInOut } from 'svelte/easing'

  // Routing
  import { push } from 'svelte-spa-router'

  // I18N
  import { _ } from 'svelte-i18n'

  // Elements
  import Button from '../elements/Button.svelte'

  // Handler
  const handleContinue = () => {
    let yes = confirm($_('section.recoveryKey.header.confirmation'))

    if (!yes) return

    State.setVault(undefined)
    push('/signin')
  }
</script>

<style>
  .page {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
  }

  .page-header {
    display: block;
    width: 100%;
    padding: 10% 20% 60px 20%;
  }

  .logo img {
    width: 100%;
    height: auto;
  }

  h1 {
    text-align: center;
    padding-top: 5%;
  }

  h2 {
    text-align: center;
    color: #5c5552;
    font-weight: 300;
    padding-top: 5%;
  }

  h3 {
    text-align: center;
    color: #5c5552;
    margin: 0;
    padding: 0;
  }

  .page-content {
    width: 100%;
    padding: 0 25% 5% 25%;
  }

  .recovery-key {
    padding-top: 10%;
    padding-bottom: 10%;
    font-size: 300%;
    font-weight: 700;
    text-align: center;
  }

  .not-available {
    padding-bottom: 10%;
    font-size: 145%;
    text-align: center;
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  @media (max-width: 640px) {
    .page-content {
      width: 100%;
      padding: 0 10% 5% 10%;
      margin-bottom: 15%;
    }
  }
</style>

<div class="page" transition:fade={{ duration: 200, easing: sineInOut }}>
  <div class="page-header">
    <div class="logo">
      <img src="images/dac6tracker_logo.png" alt="DAC6Tracker logo" />
    </div>
    <h1>{$_('section.recoveryKey.header.label')}</h1>
    {#if State.vault}
      <h2>{$_('section.recoveryKey.header.summary')}</h2>
    {/if}
  </div>
  <div class="page-content">
    {#if State.vault}
      <h3>{State.vault.username}</h3>
      <div class="recovery-key">{State.vault.getEmergencyKey()}</div>
    {:else}
      <div class="not-available">
        {$_('section.recoveryKey.header.notAvailable')}
      </div>
    {/if}
    <div class="actions">
      <div class="continue">
        <Button outline={false} on:click={handleContinue}>
          {$_('action.continue')}
        </Button>
      </div>
    </div>
  </div>
</div>
