<script>
  import {
    State,
    UseLanguages,
    UsePlans,
    AccountInput,
    AccountInputCheckFlags,
    AccountInputErrors,
    AddAccountInteractor,
    UpdateAccountInteractor,
    DeleteAccountInteractor,
    QueryCircleInteractor,
    CheckInMemberInteractor,
    CheckOutMemberInteractor,
  } from '@axelity/dac6trackerjs'
  import { equals, clone } from 'ramda'

  // Transition
  import { fade } from 'svelte/transition'
  import { sineInOut } from 'svelte/easing'

  // Routing
  import { pop, replace } from 'svelte-spa-router'

  // I18N
  import { _, locale } from 'svelte-i18n'

  // Navigation;
  import Topnav from '../navigation/Topnav.svelte'

  // Components
  import ActionRibbon from '../components/ActionRibbon.svelte'

  // Elements
  import Page from '../elements/Page.svelte'
  import Segment from '../elements/Segment.svelte'
  import InputField from '../elements/InputField.svelte'
  import Button from '../elements/Button.svelte'
  import Dropdown from '../elements/Dropdown.svelte'
  import SearchField from '../elements/SearchField.svelte'
  import Loader from '../elements/Loader.svelte'

  import locales from '../locales/de'

  if (!State.isAuthenticated) {
    replace('/signin')
  }

  let canEdit = false
  let inProcess = false

  const useLanguageList = new UseLanguages($locale).list()
  const usePlanList = new UsePlans($locale).list()

  const useStorageList = Object.entries(locales.storageLocation)
    .map(([key]) => {
      const s = `storageLocation.${key}`
      return {
        key: '__STORAGE_LOCATION_PREFIX__' + key.toLowerCase(),
        value: `${$_(s)}`,
        selected: false,
      }
    })
    .sort((a, b) => {
      return a.value.localeCompare(b.value)
    })

  let fields = new AccountInput({
    naming: {},
    address: {},
    contact: {},
    plan: {},
  })

  // Just used to identify input changes
  let _fields

  let errors = new AccountInputErrors()

  let searchArgument = ''

  // members of premium account
  let circle = []

  const initialize = async () => {
    if (State.account) {
      fields = new AccountInput(clone(State.account))
    }
    if (State.context) {
      fields.storageAccount = State.context.storage.account
    }
    _fields = new AccountInput(clone(fields))

    if (State.account && State.context) {
      // Query all members assigned to the current context
      circle = await new QueryCircleInteractor().execute()
      handleUserRights()
    }
  }
  initialize()

  // Handler
  const handleCancel = () => {
    if (!equals(fields, _fields)) {
      let yes = confirm($_('warning.exitWithoutSaving.label'))
      if (!yes) return
    }
    pop()
  }

  const handleSave = async () => {
    const flags = new AccountInputCheckFlags({})

    // if (fields.plan.type === 'FREE') {
    //   flags.naming.displayName = true
    //   flags.plan.type = true
    //   flags.storageAccount = true
    // } else {
      flags.naming.displayName = true
      flags.plan.type = true
      flags.storageAccount = true
      flags.contact.email = true
      flags.contact.phone = true
      flags.contact.language = true
      flags.naming.publicBusinessName = true
      flags.naming.contactName = true
    // }

    errors = await fields.validate(flags)

    if (!fields.valid) {
      return
    }

    try {
      inProcess = true
      if (!State.account) {
        await new AddAccountInteractor().execute(fields)
      } else {
        await new UpdateAccountInteractor().execute(fields)
      }

      pop()
    } catch (error) {
      errors.system = error
      inProcess = false
    } finally {
      inProcess = false
    }
  }

  const handleDelete = async () => {
    let yes = confirm($_('account.deleteAccount.label'))

    if (!yes) return

    try {
      if (State.account) {
        inProcess = true
        await new DeleteAccountInteractor().execute()
      }
      pop()
    } catch (error) {
      errors.system = error
      inProcess = false
    } finally {
      inProcess = false
    }
  }

  const handleCheckOut = async (e) => {
    let yes = confirm($_('account.checkoutMember.label'))

    if (!yes) return

    try {
      if (State.context) {
        inProcess = true
        await new CheckOutMemberInteractor().execute(e.target.dataset.key)
        circle = await new QueryCircleInteractor().execute()
      }
    } catch (error) {
      errors.system = error
      inProcess = true
    } finally {
      inProcess = false
    } 
  }

  const handleCheckIn = async () => {
    try {
      if (State.context) {
        inProcess = true
        await new CheckInMemberInteractor().execute(searchArgument.toLowerCase())
        circle = await new QueryCircleInteractor().execute()
        searchArgument = ''
      }
    } catch (error) {
      errors.system = error
      inProcess = false
    } finally {
      inProcess = false
    }   
  }

  const handleUserRights = () => {
    circle.forEach(member => {
      if (member.contact.email === State.member.contact.email) {
        if (member.accessLevel >= 1024) {
          canEdit = true
        }
      }
    })        
  }
</script>

<style>
  .search {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .search-field {
    width: 80%;
  }

  .participants-list {
    display: block;
    width: 100%;
    transition: display 0.5s;
    margin-top: 10px;
  }

  .participants-list li {
    display: grid;
    grid-template-columns: 80% 20%;
    width: 100%;
    font-size: 100%;
    font-weight: 300;
    background-color: transparent;
    line-height: 40px;
    border-radius: 0px;
    border: 1px solid #eee;
    padding: 0 0 0 10px;
    margin-bottom: 10px;
    height: 40px;
    outline: none;
    list-style: none;
    vertical-align: middle;
  }

  .participants-list li div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .participants {
    transition: 0.2s;
  }

  .participants-list li:hover {
    background-color: #fff8f3;
  }

  .participants-actions {
    float: right;
    font-size: 200%;
    margin-right: 10px;
    text-align: right;
  }

  .remove-button {
    cursor: pointer;
    margin-top: 7px;
  }
</style>

<Topnav />
<ActionRibbon
  title={$_('section.account.header.label')}
  actionLabel={$_('action.save')}
  actionIcon="save_alt"
  on:cancel={handleCancel}
  on:action={handleSave} />

<Page>

  <div class="error">{errors.system}</div>

  <Segment title={$_('account.accountDetails.label')} expand={true}>

    <InputField
      type="text"
      name="displayName"
      placeholder={$_('account.displayName.placeholder')}
      label={$_('account.displayName.label')}
      required={true}
      bind:value={fields.naming.displayName} />
    <div class="error">{errors.naming.displayName}</div>

    <Dropdown
      name="accountType"
      placeholder={$_('account.plan.placeholder')}
      label={$_('account.plan.label')}
      options={usePlanList}
      bind:value={fields.plan.type} />
    <div class="error">{errors.plan.type}</div>

    <Dropdown
      name="storageAccount"
      placeholder={$_('account.storageAccount.placeholder')}
      label={$_('account.storageAccount.label')}
      options={useStorageList}
      disabled={State.account}
      bind:value={fields.storageAccount} />
    <div class="error">{errors.storageAccount}</div>

  </Segment>

  <Segment title={$_('account.contactDetails.label')} expand={true}>

    <InputField
      type="text"
      name="publicBusinessName"
      placeholder={$_('account.publicBusinessName.placeholder')}
      label={$_('account.publicBusinessName.label')}
      required={false}
      bind:value={fields.naming.publicBusinessName} />
    <div class="error">{errors.naming.publicBusinessName}</div>

    <InputField
      type="text"
      name="contactName"
      placeholder={$_('account.contactName.placeholder')}
      label={$_('account.contactName.label')}
      required={false}
      bind:value={fields.naming.contactName} />
    <div class="error">{errors.naming.contactName}</div>

    <InputField
      type="email"
      name="email"
      placeholder={$_('account.email.placeholder')}
      label={$_('account.email.label')}
      required={false}
      bind:value={fields.contact.email} />
    <div class="error">{errors.contact.email}</div>

    <InputField
      type="phone"
      name="phone"
      placeholder={$_('account.phone.placeholder')}
      label={$_('account.phone.label')}
      required={false}
      bind:value={fields.contact.phone} />
    <div class="error">{errors.contact.phone}</div>

    <Dropdown
      name="language"
      placeholder={$_('account.language.placeholder')}
      label={$_('account.language.label')}
      options={useLanguageList}
      bind:value={fields.contact.language} />
    <div class="error">{errors.contact.language}</div>

  </Segment>

  {#if State.premium > 0}
    <Segment title={$_('component.participants.label')} expand={true}>

      {#if canEdit}
        <div class="search">
          <div class="search-field">
            <SearchField
              name="searchField"
              placeholder={$_('component.participants.placeholder')}
              bind:value={searchArgument}
              on:execute={handleCheckIn} />
          </div>
          <div class="search-button">
            <Button outline={false} on:click={handleCheckIn}>
              {$_('action.invite')}
            </Button>
          </div>
        </div>
      {/if}

      <div class="participants-list">
        {#each circle as member}
          <li
            class="participants"
            data-key={member.id}
            transition:fade={{ duration: 200, easing: sineInOut }}>
            <div>{member.memberName} ({member.contact.email})</div>
            <div class="participants-actions">
              {#if canEdit && member.contact.email !== State.member.contact.email}
                 <div
                   data-key={member.id}
                   class="material-icons remove-button"
                   on:click={handleCheckOut}>
                   clear
                 </div>
              {/if}
            </div>
          </li>
        {:else}
          <p>{$_('component.participants.empty')}</p>
        {/each}
      </div>

    </Segment>
  {/if}

  {#if State.account && canEdit}
    <Segment title={$_('account.deleteButton.label')} expand={false}>

      <div class="delete-button">
        <Button outline={false} on:click={handleDelete}>
          {$_('action.remove')}
        </Button>
      </div>
    </Segment>
  {/if}

</Page>

{#if inProcess}
  <Loader />
{/if}