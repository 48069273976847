<script>
  import {
    State,
    QueryPersonsInteractor,
    UsePersonsList,
  } from '@axelity/dac6trackerjs'

  import { onMount } from 'svelte'

  // Transition
  import { fade } from 'svelte/transition'
  import { sineInOut } from 'svelte/easing'

  // Routing
  import { push, pop, replace } from 'svelte-spa-router'

  // I18N
  import { _ } from 'svelte-i18n'

  // Time
  import moment from 'moment'

  // Navigation;
  import Topnav from '../navigation/Topnav.svelte'

  // Components
  import ActionRibbon from '../components/ActionRibbon.svelte'

  // Elements
  import SearchField from '../elements/SearchField.svelte'
  import Loader from '../elements/Loader.svelte'

  if (!State.isAuthenticated) {
    replace('/signin')
  }

  let menuId = ''

  let sortByNameAsc = false
  let sortByRoleAsc = false
  let sortByKindAsc = false
  let sortByDateAsc = false

  let searchArgument = ""

  let persons = []
  let personsAll = [] // used for reset filter only

  let errors = {
    system: '',
  }

  let inProcess = false

  // Handler
  const handleCancel = () => {
    menuId = ''
    pop()
  }

  const handleNew = () => {
    State.setCurrentPerson(undefined)
    menuId = ''
    push('/person')
  }

  const handleEdit = (e) => {
    menuId = ''
    State.setCurrentPerson(State.getPerson(e.target.dataset.key))
    push('/person')
  }

  const handleToggleContextMenu = (e) => {
    if (menuId === '') {
      menuId = e.target.dataset.key
    } else {
      menuId = ''
    }
  }

  const sortByNameHandler = () => {
    if (sortByNameAsc) {
      // Ascending
      sortByNameAsc = false
      persons = persons.sort((a, b) => {
        if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
          return 1
        }
        if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
          return -1
        }
        return 0
      })
    } else {
      // Descending
      sortByNameAsc = true
      persons = persons.sort((a, b) => {
        if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
          return -1
        }
        if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
          return 1
        }
        return 0
      })
    }
  }

  const sortByKindHandler = () => {
    if (sortByKindAsc) {
      // Ascending
      sortByKindAsc = false
      persons = persons.sort((a, b) => {
        if (a.kind > b.kind) {
          return 1
        }
        if (a.kind < b.kind) {
          return -1
        }
        return 0
      })
    } else {
      // Descending
      sortByKindAsc = true
      persons = persons.sort((a, b) => {
        if (a.kind > b.kind) {
          return -1
        }
        if (a.kind < b.kind) {
          return 1
        }
        return 0
      })
    }
  }

  const sortByRoleHandler = () => {
    if (sortByRoleAsc) {
      // Ascending
      sortByRoleAsc = false
      persons = persons.sort((a, b) => {
        if (a.role > b.role) {
          return 1
        }
        if (a.role < b.role) {
          return -1
        }
        return 0
      })
    } else {
      // Descending
      sortByRoleAsc = true
      persons = persons.sort((a, b) => {
        if (a.role > b.role) {
          return -1
        }
        if (a.role < b.role) {
          return 1
        }
        return 0
      })
    }
  }

  const handleSearch = () => {
    if (searchArgument !== "") {
      persons = personsAll.filter((p) => {
        if (p.displayName.toLowerCase().includes(searchArgument.toLowerCase())) {
          return true
        } else {
          return false
        }
      })
    } else {
      persons = [...personsAll]
    }
  }

  const sortByDateHandler = () => {
    if (sortByDateAsc) {
      // Ascending
      sortByDateAsc = false
      persons = persons.sort((a, b) => {
        if (a.assessed === "") {
          return 1
        }
        if (moment(a.assessed, 'DD.MM.YYYY').toDate().getTime() > moment(b.assessed, 'DD.MM.YYYY').toDate().getTime()) {
          return 1
        }
        if (moment(a.assessed, 'DD.MM.YYYY').toDate().getTime() < moment(b.assessed, 'DD.MM.YYYY').toDate().getTime()) {
          return -1
        }
        return 0
      })
    } else {
      // Descending
      sortByDateAsc = true
      persons = persons.sort((a, b) => {
        if (a.assessed === "") {
          return 1
        }
        if (moment(a.assessed, 'DD.MM.YYYY').toDate().getTime() > moment(b.assessed, 'DD.MM.YYYY').toDate().getTime()) {
          return -1
        }
        if (moment(a.assessed, 'DD.MM.YYYY').toDate().getTime() < moment(b.assessed, 'DD.MM.YYYY').toDate().getTime()) {
          return 1
        }
        return 0
      })
    }
  }

  const handleRenew = async () => {
    try {
      inProcess = true
      await new QueryPersonsInteractor().execute()
      persons = new UsePersonsList().list()
      sortByNameAsc = true
      sortByNameHandler()
      personsAll = [...persons]      
    } catch (error) {
      console.log(error.message)
    } finally {
      inProcess = false
    }
  }

  onMount(async () => {
    try {
      //await new QueryPersonsInteractor().execute()
      persons = new UsePersonsList().list()
      sortByNameAsc = true
      sortByNameHandler()
      personsAll = [...persons]
    } catch (error) {
      errors.system = error
    }
  })
</script>

<style>
  .page {
    width: 100%;
    display: block;
    padding: 5%;
    padding-top: 150px;
  }

  .grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: 30% 15% 15% 15% 15% 10%;
    background-color: transparent;
    padding: 0;
  }

  .search {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    align-items: center;
  }

  .search-field {
    width: 100%;
  }

  .renew-button {
    cursor: pointer;
    font-size: 150%;
    margin-left: 20px;
  }

  .grid-header {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .grid-header:hover {
    cursor: pointer;
  }

  .grid-row {
    display: flex;
    background-color: #fff;
    height: 40px;
    align-items: center;
  }

  .grid-row:hover {
    cursor: pointer;
    background-color: #fff8f3;
  }

  .right-align {
    text-align: right;
  }

  .grid-item {
    padding: 0;
    font-size: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .action-icon {
    font-size: 120%;
  }

  .dropdown {
    position: relative;
    text-align: right;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    left: -80px;
    background-color: #f1f1f1;
    width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown-content li {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown li:hover {
    background-color: #ddd;
  }

  .show-context-menu {
    display: block;
  }

  .error {
    font-weight: bold;
    color: red;
  }

  @media (min-width: 640px) {
    .page {
      max-width: none;
    }
  }

  @media (max-width: 640px) {
    .page {
      margin-bottom: 100px;
    }
    .grid-item {
      font-size: 80%;
    }
    .action-icon {
      font-size: 100%;
    }
  }
</style>

<Topnav />
<ActionRibbon
  title={$_('section.persons.header.label')}
  actionLabel={$_('action.addPerson')}
  actionIcon="add"
  on:cancel={handleCancel}
  on:action={handleNew} />

<div class="page" transition:fade={{ duration: 200, easing: sineInOut }}>

  <div class="error">{errors.system}</div>

  <div class="search">
    <div class="search-field">
      <SearchField
        name="searchField"
        placeholder=""
        bind:value={searchArgument}
        on:input={handleSearch} />
    </div>
    <div
      class="material-icons renew-button"
      on:click={handleRenew}>
      autorenew
    </div>
  </div>

  <div class="grid-container">
    <div class="grid-item grid-header" on:click={sortByNameHandler}>{$_('person.header.displayName')}</div>
    <div class="grid-item grid-header" on:click={sortByKindHandler}>{$_('person.header.kind')}</div>
    <div class="grid-item grid-header" on:click={sortByRoleHandler}>{$_('person.header.role')}</div>
    <div class="grid-item grid-header" on:click={sortByDateHandler}>{$_('arrangement.header.assessed')}</div>
    <div class="grid-item grid-header">{$_('arrangement.header.file')}</div>
    <div class="grid-item grid-header right-align">
      {$_('person.header.action')}
    </div>
  </div>

  {#each persons as person}
    <div class="grid-row">
      <div class="grid-container">
        <div class="grid-item">{person.displayName}</div>

        <div class="grid-item">
          {#if person.kind === 'ORGANIZATION'}
            {$_('personKind.organization')}
          {:else}{$_('personKind.individual')}{/if}
        </div>

        <div class="grid-item">
          {#if person.role === 'INTERMEDIARY'}
            {$_('personRole.intermediary')}
          {:else if person.role === 'TAXPAYER'}
            {$_('personRole.taxpayer')}
          {:else}{$_('personRole.other')}{/if}
        </div>

        <div class="grid-item">{person.assessed}</div>

        {#if person.file}
          <span class="material-icons warn-sign">warning</span>
        {:else}
          <span class="material-icons good-sign">check_circle</span>
        {/if}

        <div class="dropdown">
          <div
            data-key={person.id}
            class="material-icons grid-item right-align action-icon"
            on:click={handleToggleContextMenu}>
            more_vert
          </div>
          <div
            id={`contextMenu_${person.id}`}
            class="dropdown-content"
            class:show-context-menu={menuId === person.id}>
            <li data-key={person.id} on:click={handleEdit}>
              {$_('action.edit')}
            </li>
          </div>
        </div>
      </div>
    </div>
  {:else}
    <p>{$_('section.persons.empty')}</p>
  {/each}
</div>

{#if inProcess}
  <Loader />
{/if}
