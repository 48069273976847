<script>
  import {
    State,
    Disclosure,
    DisclosureErrors,
    DisclosureCheckFlags,
    UpdateDisclosureInteractor,
    DeleteDisclosureInteractor,
    ResultInterpreter,
    UseArrangements,
    UseMemberStates,
    UsePersons,
    UseReasons,
    UseTaxpayers,
    UseIntermediaryNexuses,
    UseIntermediaryCapacities,
    UseTaxpayerNexuses,
    UseTaxpayerCapacities,
    UseIntermediaries,
    UseLanguages,
    UseCurrencies,
    AddDisclosureInteractor,
  } from '@axelity/dac6trackerjs'
  import { equals, clone } from 'ramda'

  import moment from 'moment'

  // Routing
  import { pop, replace } from 'svelte-spa-router'

  // I18N
  import { _, locale } from 'svelte-i18n'

  // Navigation;
  import Topnav from '../navigation/Topnav.svelte'

  // Components
  import ActionRibbon from '../components/ActionRibbon.svelte'

  // Elements
  import Button from '../elements/Button.svelte'
  import Segment from '../elements/Segment.svelte'
  import Page from '../elements/Page.svelte'
  import InputField from '../elements/InputField.svelte'
  import ToggleSwitch from '../elements/ToggleSwitch.svelte'
  import MultiSelect from '../elements/MultiSelect.svelte'
  import Dropdown from '../elements/Dropdown.svelte'

  if (!State.isAuthenticated) {
    replace('/signin')
  }

  let useReceivingCountries = new UseMemberStates($locale.substr(0, 2)).list()
  let useDiscloserList = new UsePersons($locale).list()
  let useAffectedPersonList = new UsePersons($locale).list()
  let useTaxpayerList = new UseTaxpayers().list()
  let useIntermediaryList = new UseIntermediaries().list()
  let useIntermediaryNexusList = new UseIntermediaryNexuses($locale).list()
  let useIntermediaryCapacityList = new UseIntermediaryCapacities(
    $locale
  ).list()
  let useTaxpayerNexusList = new UseTaxpayerNexuses($locale).list()
  let useTaxpayerCapacityList = new UseTaxpayerCapacities($locale).list()
  let useReasonList = new UseReasons($locale).list()
  let useLanguageList = new UseLanguages($locale).list()
  let useCurrencyList = new UseCurrencies($locale).list()
  let useArrangementlist = new UseArrangements($locale).list(true).map((a) => {
    return {
      key: a.id,
      value: `${a.displayName} (${a.taxpayer})`,
      selected: false,
    }
  })

  let selectedCountries = []
  let selectedTaxpayers = []
  let selectedIntermediaries = []
  let selectedAffectedPersons = []
  let selectedArrangement
  let selectedDiscloser
  let discloserRole = ''

  // used for change detection only
  let _selectedCountries = []
  let _selectedTaxpayers = []
  let _selectedIntermediaries = []
  let _selectedAffectedPersons = []
  let _selectedArrangement
  let _selectedDiscloser
  let _discloserRole = ''

  let fields = new Disclosure({})
  let errors = new DisclosureErrors()

  const initialize = () => {
    if (State.currentDisclosure) {
      // Prepare receciving countries
      for (
        let i = 0;
        i < State.currentDisclosure.receivingCountries.length;
        i++
      ) {
        const country = State.currentDisclosure.receivingCountries[i]
        for (let j = 0; j < useReceivingCountries.length; j++) {
          const element = useReceivingCountries[j]
          if (element.key === country) {
            selectedCountries = [...selectedCountries, element]
            break
          }
        }
      }

      // Prepare selected taxpayer
      for (
        let i = 0;
        i < State.currentDisclosure.relevantTaxpayers.length;
        i++
      ) {
        const taxpayer = State.currentDisclosure.relevantTaxpayers[i]
        for (let j = 0; j < useTaxpayerList.length; j++) {
          const element = useTaxpayerList[j]
          if (element.key === taxpayer.id) {
            selectedTaxpayers = [...selectedTaxpayers, element]
            break
          }
        }
      }

      // Prepare intermediaries
      for (let i = 0; i < State.currentDisclosure.intermediaries.length; i++) {
        const intermediary = State.currentDisclosure.intermediaries[i]
        for (let j = 0; j < useIntermediaryList.length; j++) {
          const element = useIntermediaryList[j]
          if (element.key === intermediary.id) {
            selectedIntermediaries = [...selectedIntermediaries, element]
            break
          }
        }
      }

      // Prepare affected person
      for (let i = 0; i < State.currentDisclosure.affectedPersons.length; i++) {
        const affectedPerson = State.currentDisclosure.affectedPersons[i]
        for (let j = 0; j < useAffectedPersonList.length; j++) {
          const element = useAffectedPersonList[j]
          if (element.key === affectedPerson.id) {
            selectedAffectedPersons = [...selectedAffectedPersons, element]
            break
          }
        }
      }

      // Prepare discloser
      selectedDiscloser = State.currentDisclosure.discloser.person.id
      const discloser = State.getPerson(selectedDiscloser)
      discloserRole = discloser.role

      // Clone selected values for detecting changes
      _selectedCountries = clone(selectedCountries)
      _selectedTaxpayers = clone(selectedTaxpayers)
      _selectedIntermediaries = clone(selectedIntermediaries)
      _selectedAffectedPersons = clone(selectedAffectedPersons)
      _selectedArrangement = clone(selectedArrangement)
      _selectedDiscloser = clone(selectedDiscloser)
      _discloserRole = clone(discloserRole)

      // fill fields
      fields = State.currentDisclosure

      // Interpret arrangement tags and store outcome as result and hallmarks
      const { outcome, hallmarks } = new ResultInterpreter($locale).execute({
        tags: fields.arrangement.assessments[0].tags,
        assignTo: 'SERVICE',
      })
      fields.result = outcome
      fields.hallmarks = hallmarks

      if (!fields.mainBenefit) {
        fields.mainBenefit = outcome.consultingServices.mainBenefitFulfilled
      }

      // Now, we clone fields from the state, because it has been assigned by reference on purpose first.
      fields = new Disclosure(clone(State.currentDisclosure))
    }
  }
  initialize()

  // Handler
  const handleCancel = () => {
    if (
      !equals(fields, State.currentDisclosure) ||
      !equals(selectedCountries, _selectedCountries) ||
      !equals(selectedTaxpayers, _selectedTaxpayers) ||
      !equals(selectedIntermediaries, _selectedIntermediaries) ||
      !equals(selectedAffectedPersons, _selectedAffectedPersons) ||
      !equals(selectedArrangement, _selectedArrangement) ||
      !equals(selectedDiscloser, _selectedDiscloser) ||
      !equals(discloserRole, _discloserRole)
    ) {
      let yes = confirm($_('warning.exitWithoutSaving.label'))
      if (!yes) return
    }
    State.setCurrentDisclosure(undefined)
    pop()
  }

  const handleChangeDiscloser = () => {
    const discloser = State.getPerson(selectedDiscloser)
    discloserRole = discloser.role
  }

  const handleSelectedArrangement = () => {
    const arrangement = State.getArrangement(selectedArrangement)
    fields.setArrangement(arrangement)
    fields.summary.name = arrangement.displayName
    fields.setIdentifiers('__')
  }

  const handleSave = async () => {
    fields.receivingCountries = selectedCountries.map((v) => {
      return v.key
    })
    fields.relevantTaxpayers = selectedTaxpayers.map((v) => {
      return State.getPerson(v.key)
    })
    fields.intermediaries = selectedIntermediaries.map((v) => {
      return State.getPerson(v.key)
    })
    fields.affectedPersons = selectedAffectedPersons.map((v) => {
      return State.getPerson(v.key)
    })
    fields.implementingDate = moment(fields.implementingDate).format(
      'YYYY-MM-DD'
    )
    fields.result.consultingServices.mainBenefitFulfilled = fields.mainBenefit

    fields.discloser.person = State.getPerson(selectedDiscloser)

    console.log(fields.discloser.person)

    const flags = new DisclosureCheckFlags({
      arrangementId: true,
      messageId: true,
      disclosureId: true,
      initialDisclosure: true,
      receivingCountries: true,
      discloser: true,
      implementingDate: true,
      relevantTaxpayers: true,
      reason: true,
      summary: true,
      economicValue: true,
      dac6D1OtherInfo: true,
    })

    errors = await fields.validate(flags)

    if (!fields.valid) {
      return
    }

    try {
      if (!fields.box || fields.box === '') {
        await new AddDisclosureInteractor().execute(fields)
      } else {
        await new UpdateDisclosureInteractor().execute(fields)
      }

      State.setCurrentDisclosure(undefined)

      pop()
    } catch (error) {
      errors.system = error
    }
  }

  const handleDelete = async () => {
    let yes = confirm($_('disclosure.deleteArrangement.label'))

    if (!yes) return

    try {
      await new DeleteDisclosureInteractor().execute(State.currentDisclosure)
      State.setCurrentDisclosure(undefined)

      pop()
    } catch (error) {
      errors.system = error
    }
  }
</script>

<style>
  .initial-disclosure,
  .main-benefit {
    display: flex;
    margin-top: 20px;
    align-items: center;
  }

  .initial-disclosure p,
  .main-benefit p {
    margin-left: 10px;
    font-size: 90%;
    font-weight: 700;
  }

  .error {
    font-weight: bold;
    color: red;
  }
</style>

<Topnav />
<ActionRibbon
  title={$_('section.disclosure.header.label')}
  actionLabel={$_('action.save')}
  actionIcon="save_alt"
  on:cancel={handleCancel}
  on:action={handleSave} />

<Page>

  <div class="error">{errors.system}</div>

  {#if !fields.arrangement}
    <Segment title={$_('disclosure.arrangementSelection.label')} expand={true}>

      <Dropdown
        name="arrangement"
        label={$_('disclosure.arrangement.label')}
        placeholder={$_('disclosure.arrangement.placeholder')}
        options={useArrangementlist}
        on:change={handleSelectedArrangement}
        bind:value={selectedArrangement} />

      <div class="error">{errors.discloser.person}</div>

    </Segment>
  {/if}

  {#if fields.arrangement}
    <Segment title={$_('disclosure.disclosureDetails.label')} expand={true}>

      <InputField
        type="text"
        name="messageId"
        placeholder={$_('disclosure.messageId.placeholder')}
        label={$_('disclosure.messageId.label')}
        required={true}
        disabled={true}
        bind:value={fields.messageId} />
      <div class="error">{errors.messageId}</div>

      <InputField
        type="text"
        name="arrangementId"
        placeholder={$_('disclosure.arrangementId.placeholder')}
        label={$_('disclosure.arrangementId.label')}
        required={true}
        disabled={true}
        bind:value={fields.arrangementId} />
      <div class="error">{errors.arrangementId}</div>

      <InputField
        type="text"
        name="disclosureId"
        placeholder={$_('disclosure.disclosureId.placeholder')}
        label={$_('disclosure.disclosureId.label')}
        required={true}
        disabled={true}
        bind:value={fields.disclosureId} />
      <div class="error">{errors.disclosureId}</div>

      <div class="initial-disclosure">
        <ToggleSwitch
          name="initialDisclosure"
          bind:checked={fields.initialDisclosure} />
        <p>{$_('disclosure.initialDisclosure.label')}</p>
      </div>

      <MultiSelect
        bind:availableOptions={useReceivingCountries}
        bind:selectedOptions={selectedCountries}
        name="receivingCountries"
        placeholder={$_('disclosure.receivingCountries.placeholder')}
        label={$_('disclosure.receivingCountries.label')} />
      <div class="error">{errors.receivingCountries}</div>

    </Segment>

    <Segment title={$_('disclosure.discloserDetails.label')} expand={true}>

      <Dropdown
        name="discloser"
        label={$_('disclosure.discloser.label')}
        placeholder={$_('disclosure.discloser.placeholder')}
        options={useDiscloserList}
        on:change={handleChangeDiscloser}
        bind:value={selectedDiscloser} />

      <div class="error">{errors.discloser.person}</div>

      {#if discloserRole === 'INTERMEDIARY'}
        <Dropdown
          name="intermediaryNexus"
          label={$_('disclosure.intermediaryNexus.label')}
          placeholder={$_('disclosure.intermediaryNexus.placeholder')}
          options={useIntermediaryNexusList}
          bind:value={fields.discloser.nexus} />

        <div class="error">{errors.discloser.nexus}</div>

        <Dropdown
          name="intermediaryCapacity"
          label={$_('disclosure.intermediaryCapacity.label')}
          placeholder={$_('disclosure.intermediaryCapacity.placeholder')}
          options={useIntermediaryCapacityList}
          bind:value={fields.discloser.capacity} />

        <div class="error">{errors.discloser.capacity}</div>
      {:else if discloserRole === 'TAXPAYER'}
        <Dropdown
          name="taxpayerNexus"
          label={$_('disclosure.taxpayerNexus.label')}
          placeholder={$_('disclosure.taxpayerNexus.placeholder')}
          options={useTaxpayerNexusList}
          bind:value={fields.discloser.nexus} />

        <div class="error">{errors.discloser.nexus}</div>

        <Dropdown
          name="taxpayerCapacity"
          label={$_('disclosure.taxpayerCapacity.label')}
          placeholder={$_('disclosure.taxpayerCapacity.placeholder')}
          options={useTaxpayerCapacityList}
          bind:value={fields.discloser.capacity} />

        <div class="error">{errors.discloser.capacity}</div>
      {/if}

    </Segment>

    <Segment title={$_('disclosure.taxpayerList.label')} expand={true}>

      <InputField
        type="date"
        name="implementingDate"
        placeholder={$_('disclosure.implementingDate.placeholder')}
        label={$_('disclosure.implementingDate.label')}
        required={true}
        bind:value={fields.implementingDate} />
      <div class="error">{errors.implementingDate}</div>

      <MultiSelect
        bind:availableOptions={useTaxpayerList}
        bind:selectedOptions={selectedTaxpayers}
        name="relevantTaxpayers"
        placeholder={$_('disclosure.relevantTaxpayers.placeholder')}
        label={$_('disclosure.relevantTaxpayers.label')} />

      <div class="error">{errors.relevantTaxpayers}</div>

    </Segment>

    <Segment title={$_('disclosure.intermediaryList.label')} expand={true}>

      <MultiSelect
        bind:availableOptions={useIntermediaryList}
        bind:selectedOptions={selectedIntermediaries}
        name="involvedIntermediaries"
        placeholder={$_('disclosure.involvedIntermediaries.placeholder')}
        label={$_('disclosure.involvedIntermediaries.label')} />

      <div class="error">{errors.intermediaries}</div>

    </Segment>

    <Segment title={$_('disclosure.affectedPersonList.label')} expand={true}>

      <MultiSelect
        bind:availableOptions={useAffectedPersonList}
        bind:selectedOptions={selectedAffectedPersons}
        name="affectedPersons"
        placeholder={$_('disclosure.affectedPersons.placeholder')}
        label={$_('disclosure.affectedPersons.label')} />

      <div class="error">{errors.affectedPersons}</div>

    </Segment>

    <Segment title={$_('disclosure.messageReason.label')} expand={true}>

      <Dropdown
        name="reason"
        label={$_('disclosure.reason.label')}
        placeholder={$_('disclosure.reason.placeholder')}
        options={useReasonList}
        bind:value={fields.reason} />

      <div class="error">{errors.reason}</div>

    </Segment>

    <Segment title={$_('disclosure.arrangementDetails.label')} expand={true}>

      <InputField
        type="text"
        name="arrangementName"
        placeholder={$_('disclosure.arrangementName.placeholder')}
        label={$_('disclosure.arrangementName.label')}
        required={true}
        bind:value={fields.summary.name} />
      <div class="error">{errors.summary.name}</div>

      <InputField
        type="textarea"
        name="description"
        placeholder={$_('disclosure.description.placeholder')}
        label={$_('disclosure.description.label')}
        required={true}
        bind:value={fields.summary.description} />
      <div class="error">{errors.summary.description}</div>

      <Dropdown
        name="language"
        label={$_('disclosure.language.label')}
        placeholder={$_('disclosure.language.placeholder')}
        options={useLanguageList}
        bind:value={fields.summary.language} />
      <div class="error">{errors.summary.language}</div>

      <InputField
        type="text"
        name="economicValue"
        placeholder={$_('disclosure.economicValue.placeholder')}
        label={$_('disclosure.economicValue.label')}
        required={true}
        bind:value={fields.economicValue.amount} />
      <div class="error">{errors.economicValue.amount}</div>

      <Dropdown
        name="currency"
        label={$_('disclosure.currency.label')}
        placeholder={$_('disclosure.currency.placeholder')}
        options={useCurrencyList}
        bind:value={fields.economicValue.currency} />
      <div class="error">{errors.economicValue.currency}</div>

      {#if fields.result.consultingServices.hallmarks.DAC6D1Others}
        <InputField
          type="textarea"
          name="dac6D1OtherInfo"
          placeholder={$_('disclosure.dac6D1OtherInfo.placeholder')}
          label={$_('disclosure.dac6D1OtherInfo.label')}
          required={false}
          bind:value={fields.dac6D1OtherInfo} />
        <div class="error">{errors.dac6D1OtherInfo}</div>
      {/if}

      <div class="main-benefit">
        <ToggleSwitch name="mainBenefit" bind:checked={fields.mainBenefit} />
        <p>{$_('disclosure.mainBenefit.label')}</p>
      </div>

    </Segment>

    {#if State.currentDisclosure}
      <Segment title={$_('disclosure.deleteButton.label')} expand={false}>

        <div class="delete-button">
          <Button outline={false} on:click={handleDelete}>
            {$_('action.remove')}
          </Button>
        </div>

      </Segment>
    {/if}
  {/if}

</Page>
