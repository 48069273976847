<script>
  import { createEventDispatcher } from 'svelte'
  export let id = ''
  export let name = ''
  export let checked = false

  const dispatch = createEventDispatcher()

  const handleClick = () => {
    checked = !checked
    dispatch('click')
  }
</script>

<style>
  /* This code is from https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_switch */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #ec6907;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #ec6907;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
 */
</style>

<label class="switch">
  <input type="checkbox" {checked} {id} {name} on:click={handleClick} />
  <span class="slider" />
</label>
