<script>
  import {
    RecoverInteractor,
    RecoverInput,
    RecoverInputCheckFlags,
    RecoverErrors,
  } from '@axelity/dac6trackerjs'

  // Routing
  import { push } from 'svelte-spa-router'

  // I18N
  import { _ } from 'svelte-i18n'

  // Elements
  import InputField from '../elements/InputField.svelte'
  import Button from '../elements/Button.svelte'
  import Loader from '../elements/Loader.svelte'

  let fields = new RecoverInput({})
  let errors = new RecoverErrors()

  let inProcess = false

  // Handler
  const handleRecover = async () => {
    errors = await fields.validate(
      new RecoverInputCheckFlags({
        emergencyKey: true,
        password: true,
        passwordConfirm: true,
      })
    )

    if (!fields.valid) return

    try {
      inProcess = true

      await new RecoverInteractor().execute(fields)

      inProcess = false

      push('/recoverykey')
    } catch (error) {
      inProcess = false
      errors.system = $_('error.system.unexpected') + error
    }
  }
</script>

<style>
  .component {
    width: 100%;
    padding: 0 10%;
  }

  .input-field {
    width: 100%;
    margin: 2% 0;
  }

  .navigate {
    display: block;
    width: 100%;
    margin: 5% 0;
  }

  .navigate .next {
    float: right;
  }

  .error {
    font-weight: bold;
    color: red;
  }
</style>

<div class="component">
  <div class="error">{errors.system}</div>

  <div class="input-field">
    <InputField
      type="text"
      name="emergencyKey"
      placeholder={$_('elements.emergencyKey.placeholder')}
      label={$_('elements.emergencyKey.label')}
      required={true}
      bind:value={fields.emergencyKey} />
    <div class="error">{errors.emergencyKey}</div>
  </div>

  <div class="input-field">
    <InputField
      type="password"
      name="password"
      label={$_('elements.password.label')}
      placeholder={$_('elements.password.placeholder')}
      required={true}
      bind:value={fields.password} />
    <div class="error">{errors.password}</div>
  </div>

  <div class="input-field">
    <InputField
      type="password"
      name="passwordConfirm"
      label={$_('elements.passwordConfirm.label')}
      placeholder={$_('elements.passwordConfirm.placeholder')}
      required={true}
      bind:value={fields.passwordConfirm} />
    <div class="error">{errors.passwordConfirm}</div>
  </div>

  <div class="navigate">
    <div class="next">
      <Button outline={false} on:click={handleRecover}>
        {$_('action.recover')}
      </Button>
    </div>
  </div>

</div>

{#if inProcess}
  <Loader />
{/if}
