<script>
  // Transition
  import { fade } from 'svelte/transition'
  import { sineInOut } from 'svelte/easing'

  // Routing
  import { pop } from 'svelte-spa-router'

  // I18N
  import { _ } from 'svelte-i18n'

  // Elements
  import Button from '../elements/Button.svelte'

  // Handler
  const handleBack = () => {
    pop()
  }
</script>

<style>
  .page {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
  }

  .page-header {
    display: block;
    width: 100%;
    padding: 10% 20% 60px 20%;
  }

  .logo img {
    width: 100%;
    height: auto;
  }

  h1 {
    color: #383432;
    text-align: center;
    padding: 5% 0 0 0;
  }

  .page-content {
    width: 100%;
    padding: 0 25% 5% 25%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 640px) {
    .page {
      max-width: none;
    }
  }
</style>

<div class="page" transition:fade={{ duration: 200, easing: sineInOut }}>
  <div class="page-header">
    <div class="logo">
      <img src="images/dac6tracker_logo.png" alt="DAC6Tracker logo" />
    </div>
    <h1>{$_('section.notFound.header.label')}</h1>
  </div>
  <div class="page-content">
    <Button outline={false} on:click={handleBack}>{$_('action.back')}</Button>
  </div>
</div>
