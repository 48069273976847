<script>
  import { onMount } from 'svelte'

  import { ResultInterpreter } from '@axelity/dac6trackerjs'

  // I18N
  import { locale } from 'svelte-i18n'

  // Stores
  import { resultTags } from '../stores/AssessmentStore'

  export let assignTo = ''

  // Process variables
  let result = []

  onMount(() => {
    const { output } = new ResultInterpreter($locale).execute({
      tags: $resultTags,
      assignTo,
    })
    result = output
  })
</script>

<style>
  .result-article {
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .result-list {
    display: grid;
    width: 100%;
    grid-template-columns: 10% auto;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    margin: 5px 0;
  }
  .result-list p {
    margin: 0;
  }
</style>

<article class="result-article">
  {#if result}
    <div class="result-list">
      {#each result as item}
        {#if item.sign === 'info'}
          <span class="material-icons info-sign">info</span>
        {:else if item.sign === 'good'}
          <span class="material-icons good-sign">check_circle</span>
        {:else}
          <span class="material-icons warn-sign">warning</span>
        {/if}
        <p>{item.text}</p>
      {/each}
    </div>
  {/if}
</article>
