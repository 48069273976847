<script>
  // Routing
  import { push } from 'svelte-spa-router'

  // Store
  import {
    showSidebar,
    showTopnavMenu,
    isAuthenticated,
  } from '../stores/AppStore'

  // Handler
  const openSidebar = () => {
    showSidebar.set(true)
  }

  const handleHome = () => {
    if (!$isAuthenticated) push('/')
  }
</script>

<style>
  .navigation {
    display: flex;
    align-items: center;
    height: 80px;
    width: 100%;
    background-color: #f8f8f8;
    border-bottom: solid 1px #eee;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .menu span {
    font-size: 180%;
    margin: 8px 20px 0px 20px;
    cursor: pointer;
  }

  .logo {
    width: 190px;
    vertical-align: middle;
  }

  .logo img {
    width: 100%;
  }

  .navigation li {
    display: block;
    float: left;
    text-align: center;
    text-decoration: none;
    list-style: none;
    cursor: pointer;
  }

  @media screen and (max-width: 300px) {
    .logo {
      display: none;
    }
  }
</style>

<div class="navigation">
  {#if showTopnavMenu}
    <li class="menu" on:click={openSidebar}>
      <span class="material-icons">menu</span>
    </li>
  {/if}
  <li>
    <div class="logo" on:click={handleHome}>
      <img src="images/dac6tracker_logo.png" alt="DAC6Tracker logo" />
    </div>
  </li>
</div>
