<script>
  import { createEventDispatcher } from 'svelte'
  export let type = 'text'
  export let name = ''
  export let placeholder = ''
  export let label = ''
  export let required = false
  export let disabled = false
  export let rows = 3
  export let value = ''

  const dispatch = createEventDispatcher()

  const handleInput = (e) => {
    value = e.target.value
    dispatch('input')
  }

  const handleEnterKey = (e) => {
    e.preventDefault()
    if (e.keyCode === 13) {
      dispatch('execute')
    }
  }
</script>

<style>
  .input-field {
    position: relative;
    width: 100%;
    user-select: none;
  }

  input,
  label,
  textarea {
    display: block;
    width: 100%;
  }

  label {
    font-size: 90%;
    font-weight: 700;
    margin: 0.3rem 0;
  }

  input,
  textarea {
    font-size: 100%;
    font-weight: 300;
    background-color: transparent;
    line-height: 20px;
    border: 1px solid #aaa;
    border-radius: 0px;
    padding: 0 0 0 10px;
    outline: none;
  }

  input {
    height: 40px;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ccc;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ccc;
  }

  @media screen and (max-width: 540px) {
    ::placeholder {
      font-size: 80%;
    }
  }
</style>

<div class="input-field">
  <label for={name}>{label}</label>
  {#if type === 'textarea'}
    <textarea
      {name}
      id={name}
      {placeholder}
      {required}
      {rows}
      {value}
      {disabled}
      on:input={handleInput} />
  {:else}
    <input
      {type}
      {name}
      id={name}
      {placeholder}
      {required}
      {disabled}
      {value}
      on:input={handleInput}
      on:keyup={handleEnterKey} />
  {/if}
</div>
