<script>
  import { onMount } from 'svelte'
  import Arrow from './Arrow.svelte'

  // Parameters
  export let name = ''
  export let placeholder = ''
  export let label = ''
  export let sortAvailableOptions = true
  export let sortSelectedOptions = false
  export let availableOptions = []
  export let selectedOptions = []

  // Variables
  let optionListVisible = false
  let availableOptionList = [...availableOptions]
  let selectedOptionList = [...selectedOptions]

  // Handler
  const toggleOptionList = () => {
    optionListVisible = !optionListVisible
    let style = getComputedStyle(
      document.querySelector('.selected-option-list')
    )
    let element = document.getElementById(`${name}-option-list`)
    if (optionListVisible) {
      element.style['display'] = 'flex'
      element.style['width'] = style.width
    } else {
      element.style['display'] = 'none'
    }
  }

  const addSelectedOption = () => {
    let selectedItem = parseInt(event.target.getAttribute('data-index'))
    let key = availableOptionList[selectedItem].key

    let newAvailableOptions = []
    let newSelectedOptions = []

    newSelectedOptions = [
      ...selectedOptionList,
      availableOptionList[selectedItem],
    ]

    newAvailableOptions = availableOptionList.filter((opt) => {
      return opt.key !== key
    })

    if (sortAvailableOptions) {
      availableOptionList = sortArray(newAvailableOptions)
    } else {
      availableOptionList = [...newAvailableOptions]
    }
    if (sortSelectedOptions) {
      selectedOptionList = sortArray(newSelectedOptions)
    } else {
      selectedOptionList = [...newSelectedOptions]
    }

    selectedOptions = [...selectedOptionList]
  }

  const removeSelectedOption = (e) => {
    // let key = event.target.getAttribute("data-value");
    // let key;
    // for (let i = 0; i < e.path.length; i++) {
    //   const element = e.path[i];
    //   try {
    //     key = element.getAttribute("data-key");
    //     if (key) break;
    //   } catch (error) {
    //     continue;
    //   }
    // }
    let key = e.target.getAttribute('data-key')

    let newAvailableOptions = []
    let newSelectedOptions = []
    for (let i = 0; i < selectedOptionList.length; i++) {
      const opt = selectedOptionList[i]
      if (opt.key === key) {
        newAvailableOptions = [...availableOptionList, opt]
      }
    }
    newSelectedOptions = selectedOptionList.filter((opt) => {
      return opt.key !== key
    })

    if (sortAvailableOptions) {
      availableOptionList = sortArray(newAvailableOptions)
    } else {
      availableOptionList = [...newAvailableOptions]
    }
    if (sortSelectedOptions) {
      selectedOptionList = sortArray(newSelectedOptions)
    } else {
      selectedOptionList = [...newSelectedOptions]
    }

    selectedOptions = [...selectedOptionList]
  }

  const sortArray = (options) => {
    return options.sort((a, b) => {
      return a.value.localeCompare(b.value)
    })
  }

  // Mount
  onMount(() => {
    // sort lists if requested
    if (sortAvailableOptions) {
      availableOptionList = sortArray(availableOptionList)
    }

    if (sortSelectedOptions) {
      selectedOptionsList = sortArray(selectedOptionsList)
    }
  })
</script>

<style>
  .multi-select {
    position: relative;
  }

  label {
    display: block;
    width: 100%;
    font-size: 90%;
    font-weight: 700;
    margin: 0.3rem 0;
  }

  .selected-option-list {
    font-size: 100%;
    display: grid;
    grid-template-columns: auto 10%;
    align-items: center;
    width: 100%;
    border: 1px solid #aaa;
    border-radius: 0px;
    min-height: 40px;
    cursor: pointer;
  }

  .arrow-sign {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }

  .selected-options {
    padding: 10px;
    line-height: 25px;
    color: #ddd;
  }

  .selected-options li {
    display: inline-block;
    list-style: none;
    background-color: #eee;
    color: #383432;
    margin: 2px;
    padding: 0px 10px;
    border-radius: 50px;
    font-weight: 500;
    text-overflow: ellipsis;
  }

  .selected-options li:hover {
    background-color: #fff8f3;
  }

  .available-options {
    display: none;
    position: absolute;
    width: 100%;
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: 200px;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    transition: display 0.5s;
  }

  .available-options li {
    width: 100%;
    font-size: 100%;
    font-weight: 300;
    color: #383432;
    background-color: transparent;
    line-height: 40px;
    border-radius: 0px;
    padding: 0 0 0 10px;
    height: 40px;
    outline: none;
    list-style: none;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .available-options li:hover {
    background-color: #fff8f3;
  }

  .remove-button {
    color: #888;
    font-weight: bold;
    font-size: 120%;
    cursor: pointer;
    text-align: center;
    border-radius: 15px;
    margin-left: 5px;
    padding: 0;
  }

  @media screen and (max-width: 540px) {
    .selected-options {
      font-size: 85%;
    }
  }
</style>

<div class="multi-select">
  <label for={name}>{label}</label>
  <div class="selected-option-list" on:click={toggleOptionList}>
    <div class="selected-options">
      {#each selectedOptionList as opt}
        <li>
          {opt.value}
          <span
            data-key={opt.key}
            class="remove-button"
            on:click={removeSelectedOption}>
            &times;
          </span>
        </li>
      {:else}{placeholder}{/each}
    </div>
    <div class="arrow-sign">
      <Arrow direction={optionListVisible ? 'up' : 'down'} />
    </div>
  </div>
  <div id={`${name}-option-list`} class="available-options">
    {#each availableOptionList as { key, value, selected }, i}
      <li data-index={i} on:click={addSelectedOption}>{value}</li>
    {/each}
  </div>
</div>
