<script>
  // Transition
  import { fade } from 'svelte/transition'
  import { sineInOut } from 'svelte/easing'

  // Navigation;
  import Topnav from '../navigation/Topnav.svelte'

  // Components
  import ActionRibbon from '../components/ActionRibbon.svelte'

  // Handler
  const handleCancel = () => {
    window.close()
  }
</script>

<style>
  .page {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 100px 0 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
  }

  h1 {
    text-align: left;
    padding-top: 5%;
  }

  h2 {
    text-align: left;
    color: #5c5552;
    font-weight: 500;
    padding-top: 2%;
  }

  h3 {
    text-align: left;
    color: #5c5552;
    font-weight: 500;
    padding-top: 1%;
  }

  ul {
    list-style-position: outside;
  }

  .page-content {
    width: 100%;
    padding: 0 5% 5% 5%;
  }
</style>

<Topnav />
<ActionRibbon title="" actionDisabled={false} on:cancel={handleCancel} />

<div class="page" transition:fade={{ duration: 200, easing: sineInOut }}>
  <div class="page-content">
    <h1>Nutzungsvertrag</h1>
    <h2>1 Zustandekommen und Parteien</h2>
    <p>
      Dieser Vertrag über die Nutzung des DAC6 Trackers (nachfolgend "Vertrag")
      kommt zwischen
    </p>
    <p>
      Red Leafs lnnoTax AG (CHE-451.497.742), Reichsgasse 65, 7000 Chur
      (nachfolgend
      <strong>"Red Leafs"</strong>
      )
    </p>
    <p>und</p>
    <p>
      der Gesellschaft, die Sie vertreten (nachfolgend
      <strong>"Kundin"</strong>
      ),
    </p>
    <p>
      zustande, sobald Sie das Kästchen "Ich stimme dem Vertrag über die Nutzung
      des DAC6 Trackers zu" anklicken.
    </p>
    <p>
      (Red Leafs und die Kundin einzeln nachfolgend
      <strong>"Partei"</strong>
      und gemeinsam nachfolgend
      <strong>"Parteien"</strong>
      genannt)
    </p>
    <h2>2 Leistungen</h2>
    <h3>2.1 Hintergrund</h3>
    <p>
      Die EU hat die Richtlinie 2018/822 vom 25. Mai 2018 zur Änderung der
      Richtlinie 2011/16/EU bezüglich des verpflichtenden automatischen
      Informationsaustauschs im Bereich der Besteuerung über meldepflichtige
      grenzüberschreitende Gestaltungen (nachfolgend
      <strong>"EU-Richtlinie DAC6"</strong>
      ) verabschiedet.
    </p>
    <p>
      Mit der EU-Richtlinie DAC6 will die EU Steuertransparenz für die
      EU-Staaten schaffen und verlangt eine Meldung, wenn gewisse Kennzeichen
      (hallmarks) gegeben sind. Die Prüfungshandlungen, Meldungen bzw.
      Nichtmeldungen müssen lückenlos dokumentiert werden.
    </p>
    <p>
      Die einzelnen EU-Staaten haben die EU-Richtlinie DAC6 ins nationale Recht
      umzusetzen.
    </p>
    <p>
      Red Leafs betreibt eine Web-Applikation mit Namen DAC6 Tracker
      (nachfolgend "DAC6 Tracker"), mit welcher die Kundin Dienstleistungen für
      Ihre Kunden verwalten, allfällige Meldepflichten ermitteln, die
      Gestaltungen dokumentieren und gegebenenfalls Meldungen absetzen kann.
    </p>
    <h3>2.2 Erfassung und Verwaltung von Daten</h3>
    <p>
      Die Kundin kann folgende Daten zu Kunden, Dienstleistungen und
      Gestaltungen erfassen und verwalten:
    </p>
    <ul>
      <li>
        Beantwortung (in der Regel mit ja und nein) von Fragestellungen diverser
        Kategorien auf Stufe Intermediär, Kunde und Dienstleistungen
      </li>
      <li>Organisationsnamen und Rechtsform</li>
      <li>Adressangaben</li>
      <li>
        Angaben zur Person (u.a. Vorname, Nachname, Geburtsdatum, Geburts- oder
        Heimatort, Email-Adresse)
      </li>
      <li>Spracheinstellungen</li>
      <li>
        Erforderliche Daten zur Absetzung der Meldungen, u.a.
        Steueridentifikationsnummern, Währungen und Beträge (Finanzdaten),
        involvierte und/oder empfangende Länder, Datumsangaben zur
        Implementierung, Begründungen und Zusammenfassungen, involvierte
        und/oder betroffene Personen, Kennzeichen (hallmarks)
        Vertragsbezeichnungen
      </li>
    </ul>
    <h3>2.3 Überprüfung, Ergebnisse und Meldung</h3>
    <p>
      Der DAC6 Tracker kann als kostenlose, eingeschränkte Version oder als
      uneingeschränkte Vollversion genutzt werden
    </p>
    <p>
      Mit beiden Versionen, d.h. mit der kostenlosen und mit der Vollversion:
    </p>
    <ul>
      <li>
        überprüft der DAC6 Tracker die von der Kundin erfassten Daten
        hinsichtlich Kennzeichen der EU-Richtlinie DAC6, nicht aber die
        (Umsetzungs-)Gesetzgebungen der einzelnen EU-Staaten. Daraus ergibt sich
        eine unverbindliche Auskunft, wonach Kennzeichen bestehen, gemäss
        welchen die Transaktion unter der nationalen (Umsetzungs-) Gesetzgebung
        möglicherweise gemeldet werden muss, respektive dass keine solchen
        Kennzeichen ersichtlich sind.
      </li>
    </ul>
    <p>
      Die Auskunft bedeutet nicht, dass die Transaktion gemäss der EU-Richtlinie
      DAC6 gemeldet, respektive nicht gemeldet werden muss. Der DAC6 Tracker
      bietet der Kundin lediglich die Möglichkeit zu dokumentieren, dass sie
      ihrer Pflicht, Gestaltungen nach ei-nem bestimmten Verfahren zu
      überprüfen, nachgekommen ist.
    </p>
    <p>Mit der Vollversion:</p>
    <ul>
      <li>
        werden die Überprüfung und deren Ergebnisse als maschinell
        interpretierbare Da-ten verschlüsselt gespeichert und können von der
        Kundin während der Vertragsdauer als PDF-Dokument oder als strukturierte
        Daten heruntergeladen werden.
      </li>
      <li>
        liefert der DAC6 Tracker die Basis, damit die Kundin die erfassten Daten
        direkt in das Meldeformular übertragen kann. Voraussetzung dafür ist,
        dass die erforderlichen Angaben zur Gestaltung wahrheitsgetreu und
        vollständig erfasst werden.
      </li>
    </ul>
    <h2>3 Kundendaten und/oder Benutzerdaten</h2>
    <h3>3.1 Definition</h3>
    <p>
      <strong>"Kundendaten"</strong>
      sind Daten, die sich auf die Kundin oder auf die Kundschaft der Kundin
      beziehen oder von der Kundin im Zusammenhang mit der Nutzung des DAC6
      Trackers bearbeitet werden. Eine Auflistung von Kundendaten ist unter
      Ziffer 2.2, Erfassung und Verwaltung von Daten, ersichtlich.
    </p>
    <p>
      <strong>"Benutzerdaten"</strong>
      sind Daten, die sich auf eine Person beziehen, die den DAC6 Tracker
      benutzt, wie Vorname, Nachname, E-Mail-Adresse, Benutzername, Passwort und
      Sprache.
    </p>
    <h3>3.2 Daten Compliance und Schadloshaltung</h3>
    <p>Die Parteien dürfen Daten rechtmässig bearbeiten.</p>
    <p>
      Die Kundin sichert zu, dass sie berechtigt ist, die Kundendaten und
      Benutzerdaten zum Zweck der Nutzung des DAC6 Tracker zu bearbeiten.
    </p>
    <p>
      Die Kundin sichert zu, dass die Kundendaten und/oder die Benutzerdaten
      rechtmässig erhoben und bearbeitet werden und keine gesetzliche Bestimmung
      oder Rechte Dritter verletzt werden. Die Kundin ist sich bewusst, dass Red
      Leafs Kundendaten und/oder Benutzerdaten nicht prüft, validiert oder
      ändert und dies auch nicht vornehmen kann.
    </p>
    <p>
      Die Kundin hält Red Leafs von allen Ansprüchen Dritter und die damit
      verbundenen Kosten und Auslagen (einschliesslich angemessener
      Anwaltskosten) auf erste Aufforderung vollumfänglich schadlos, welche der
      Red Leafs auf Grund der unrechtmässigen Daten-bearbeitung, einer
      Verletzung gesetzlicher Bestimmung oder Rechte Dritter durch die Kundin
      entstehen.
    </p>
    <h3>3.3 Verschlüsselung und Notfallschlüssel</h3>
    <p>
      Die Daten der Kundin, die sich auf ihre Kundschaft beziehen, werden
      verschlüsselt gespeichert. Die Verschlüsselung erfolgt im Browser der
      Kundin. Der Benutzerschlüssel ist ausschliesslich der Kundin bekannt und
      muss vertraulich behandelt werden. Mit dieser Ende-zu-Ende-Verschlüsselung
      ist es ausgeschlossen, dass Red Leafs oder der Plattformanbieter die
      Kundendaten auswerten kann.
    </p>
    <p>
      Vergisst die Kundin den Benutzerschlüssel, mit welchem die Kundin die
      verschlüsselten Daten entschlüsseln kann, sind diese Daten einzig mit dem
      einmaligen Notfallschlüssel rekonstruierbar. Der Notfallschlüssel wird
      einmalig nach der Registrierung angezeigt. Diesen Notfallschlüssel muss
      die Kundin vertraulich behandeln und an einem sicheren Ort aufbewahren.
      Ohne diesen Notfallschlüssel kann der Zugang zum DAC6 Tracker nicht mehr
      wiederhergestellt werden und die gespeicherten Daten sind
      unwiederbringlich verloren. In diesem Fall kann auch Red Leafs keine
      Hilfestellung leisten.
    </p>
    <h3>3.4 Datenspeicherung</h3>
    <p>
      Die Speicherung der Kundendaten erfolgt in einer Datenablage (Repository)
      eines Rechenzentrums des Plattformbetreibers, das von der Kundin
      ausgewählt werden kann. Benutzerdaten sowie weitere Metadaten zur
      Verwaltung und Zuweisung der Daten werden in einem Rechenzentrum in der
      Schweiz gehalten.
    </p>
    <h3>3.5 Datenbearbeitung</h3>
    <p>Red Leafs bearbeitet ausschliesslich Personendaten der Kundin um:</p>
    <ul>
      <li>
        ihre vertraglichen oder gesetzlichen Verpflichtungen zu erfüllen (Art. 4
        Abs. 3 und 13 Abs. 2 Bst. a DSG, Art. 6 Abs. 1 Bst. b und c DSGVO);
      </li>
      <li>
        Informationen über ihre neuen Dienste zu versenden (Art. 13 Abs. 2 DSG
        und Art. 6 Abs. 1 Bst. f DSGVO);
      </li>
      <li>
        den DAC6 Tracker zu verwalten und die fehlerfreie Bereitstellung des
        DAC6 Trackers sicherzustellen (Art. 13 Abs. 2 DSG und Art. 6 Abs. 1 Bst.
        f DSGVO).
      </li>
    </ul>
    <p>
      Red Leafs bearbeitet keine Personendaten der Kundschaft der Kundin, da
      diese ausschliesslich verschlüsselt vorliegen.
    </p>
    <h3>3.6 Verfügbarkeit der Kundendaten und Benutzerdaten</h3>
    <p>
      Red Leafs wendet wirtschaftlich angemessene Sorgfalt an, damit die
      Kundendaten und/oder Benutzerdaten jederzeit verfügbar sind, mit der
      Ausnahme geplanter Ausfallzeiten. Red Leafs teilt der Kundin solche
      geplanten Ausfallzeiten über die möglichen Kanäle, z.B. E-Mail oder
      Publikation auf der Start-/Login-Seite des DAC6 Trackers mit.
    </p>
    <p>
      Red Leafs sichert
      <strong>nicht</strong>
      zu, dass die Kundendaten und/oder Benutzerdaten jederzeit verfügbar sind,
      da dies u.a. von der Verfügbarkeit des Plattformbetreibers, worauf der
      DAC6 Tracker betrieben wird, als auch von der Verbindung ins Internet
      sowie der Funktionstüchtigkeit des benutzten Geräts abhängt.
    </p>
    <h3>3.7 Datenabruf und Datenübernahme</h3>
    <p>
      Die Kundin kann die Kundendaten zu den Betriebszeiten des DAC6 Trackers
      abrufen, resp. übernehmen ("data retrieval").
    </p>
    <h2>4 Vertraulichkeit und Sicherheit</h2>
    <h3>4.1 Vertraulichkeit</h3>
    <p>
      Jede Partei, die vertrauliche Information von der anderen Partei erhält,
      ist verpflichtet
    </p>
    <ul>
      <li>vertrauliche Information vertraulich zu behandeln;</li>
      <li>
        vertrauliche Information keinem Dritten ohne vorherige Zustimmung der
        anderen Partei zu offenbaren; und
      </li>
      <li>
        vertrauliche Information nicht für andere Zwecke als die Erfüllung
        dieses Vertrags zu verwenden.
      </li>
    </ul>
    <p>
      Die Partei, die vertrauliche Information erhält, kann die vertrauliche
      Information ihren Mitarbeitenden offenbaren, sofern und soweit diese eine
      entsprechende Vertraulichkeitsvereinbarung unterzeichnet haben und sie die
      vertrauliche Information kennen müssen, um diesen Vertrag zu erfüllen. Die
      Partei, die vertrauliche Information erhält, ist für die Einhaltung dieser
      Verpflichtung zur Vertraulichkeit durch ihre Mitarbeitende haftbar.
    </p>
    <h3>4.2 Ausnahmen</h3>
    <p>
      Die Bestimmungen über die Vertraulichkeit gelten nicht für vertrauliche
      Information, die
    </p>
    <ul>
      <li>
        nicht verschlüsselt bearbeitet werden, obwohl der DAC6 Tracker eine
        Verschlüsselung vorsieht; oder
      </li>
      <li>
        der Partei, die vertrauliche Information erhalten hat, zum Zeitpunkt des
        Empfangs bereits rechtmässig und ohne Einschränkung bekannt waren; oder
      </li>
      <li>
        der Partei, die vertrauliche Information erhalten hat, nach Offenlegung
        durch die andere Partei rechtmässig von einem unabhängigen Dritten ohne
        Auferlegung von Einschränkungen und ohne Verletzung dieses Vertrags
        übermittelt wurden; oder
      </li>
      <li>
        ohne unrechtmässige oder vertragswidrige Handlung einer der Parteien
        bereits öffentlich bekannt sind oder werden; oder
      </li>
      <li>
        von einer Partei ohne Bezug auf eine vertrauliche Information der
        anderen Partei unabhängig entwickelt werden.
      </li>
    </ul>
    <h3>4.3 Sicherheit</h3>
    <p>Die Parteien implementieren angemessene Sicherheitsmassnahmen, um</p>
    <ul>
      <li>
        die Vertraulichkeit und Integrität der vertraulichen Information zu
        gewährleisten,
      </li>
      <li>
        den unbefugten Zugang zu oder die unbefugte Nutzung von solcher
        vertraulichen Information zu verhindern.
      </li>
    </ul>
    <h2>5 Nutzung des DAC6 Trackers</h2>
    <h3>5.1 Nutzung der Immaterialgüterrechte</h3>
    <p>
      Soweit für die Nutzung des DAC6 Trackers erforderlich (kostenlose Version
      und Vollversion), gewährt Red Leafs der Kundin hiermit ein begrenztes,
      widerrufliches, nicht exklusives und nicht übertragbares Recht zur Nutzung
      der Immaterialgüterrechte von Red Leafs für die Dauer dieses Vertrages.
    </p>
    <h3>5.2 Nutzung der Vollversion des DAC6 Trackers</h3>
    <p>
      Nutzung der Vollversion des DAC6 Trackers wird für eine fixe Dauer von
      einem Jahr gewährt und wird für ein weiteres Jahr automatisch verlängert,
      sofern die Kundin die Nutzung der Vollversion nicht mit einer
      Kündigungsfrist von 3 Monaten kündigt.
    </p>
    <h3>5.3 Technische Voraussetzungen</h3>
    <p>
      Um den DAC6 Tracker nutzen zu können, müssen die folgenden technischen
      Voraussetzungen erfüllt sein:
    </p>
    <ul>
      <li>
        die aktuellsten zwei Versionen von Google Chrome, Mozilla Firefox oder
        Apple Safari (macOS) müssen installiert sein. Microsoft Edge kann nur
        eingeschränkt verwendet werden. Ältere Browser, wie z.B. Internet
        Explorer, werden nicht unterstützt;
      </li>
      <li>Aktivierung von Javascript im Browser;</li>
      <li>Ausschalten des Inkognito-Modus;</li>
      <li>Angemessene, unterbruchsfreie Internetverbindung.</li>
    </ul>
    <h3>5.4 Benutzerkonten</h3>
    <p>
      Um den DAC6 Tracker nutzen zu können, muss die Kundin ein oder mehrere
      Benutzerkonten anlegen. Die kostenlose Version erlaubt ein einziges
      Benutzerkonto anzulegen, während mit der Vollversion mehrere
      Benutzerkonten als Mitglieder von Teams eingerichtet werden können.
    </p>
    <p>
      Ausschliesslich Mitarbeitende der Kundin dürfen ein Benutzerkonto anlegen.
      Insbesondere dürfen Mitarbeitende der Kundschaft der Kundin keine
      Benutzerkonten anlegen und den DAC6 Tracker benutzen. Für jedes
      Benutzerkonto muss die Kundin eine E-Mail-Adresse registrieren, einen
      eindeutigen Benutzernamen wählen als auch ein starkes Passwort eingeben.
      Jeder Benutzer benötigt ein eigenes Benutzerkonto, um den DAC6 Tracker
      nutzen zu können.
    </p>
    <p>
      Die Kundin, resp. die Benutzer müssen ein sicheres Passwort wählen, das
      den üblichen Sicherheitsstandards entspricht, es regelmässig ändern und es
      vertraulich behandeln.
    </p>
    <h3>5.5 Verbotene Nutzung des DAC6 Trackers</h3>
    <p>Der DAC6 Tracker darf nicht benutzt werden, um</p>
    <ul>
      <li>rechtliche Bestimmungen zu verletzen;</li>
      <li>
        einen Computer, einen Dienst, ein System, ein Gerät oder eine
        Technologie eines Dritten zu stören;
      </li>
      <li>
        die Rechte Dritter zu verletzen, wie z.B. das Recht auf Privatsphäre
        oder das geistige Eigentum Dritter;
      </li>
      <li>
        Schadsoftware (z.B. Skripte, Viren, Malware, Trojaner) einzuspielen oder
        zu verteilen;
      </li>
      <li>Dritten den Zugang zum DAC6 Tracker zu gewähren;</li>
      <li>
        Red Leafs zu konkurrenzieren, indem ein Benutzerkonto für gewerbsmässige
        Zwecke ausserhalb des von Red Leafs vorgegebenen Rahmens genutzt wird.
      </li>
    </ul>
    <h3>5.6 Sperren des Zugangs zum DAC6 Tracker</h3>
    <p>
      Bearbeitet die Kundin die Kundendaten und/oder die Benutzerdaten nicht
      vertrags- oder gesetzeskonform oder verletzt sie Rechte Dritter, oder hat
      Red Leafs Anlass anzunehmen, dass die Kundin die Kundendaten und/oder die
      Benutzerdaten nicht vertrags- oder gesetzeskonform bearbeitet oder Rechte
      Dritter verletzt, ist Red Leafs berechtigt, den Zugang der Kundin resp.
      deren Mitarbeitenden zum DAC6-Tracker sofort zu sperren.
    </p>
    <p>
      Bezahlt die Kundin die Jahresgebühr nicht innert der Zahlungsfrist (siehe
      Ziffer 6.1 und 6.3) ist Red Leafs berechtigt, den Zugang der Kundin zur
      Vollversion des DAC6 Trackers zu sperren.
    </p>
    <h3>5.7 Verfügbarkeit des DAC6 Trackers</h3>
    <p>
      Red Leafs wendet wirtschaftlich angemessene Sorgfalt an, damit der DAC6
      Tracker jederzeit verfügbar ist, mit der Ausnahme geplanter oder vorgängig
      angekündigter Ausfallzeiten. Red Leafs teilt der Kundin solche geplanten
      Ausfallzeiten über die möglichen Kanäle, z.B. E-Mail oder Publikation auf
      der Start-/Login-Seite des DAC6 Trackers mit.
    </p>
    <p>
      Red Leafs sichert
      <strong>nicht</strong>
      zu, dass der DAC6 Tracker jederzeit verfügbar ist.
    </p>
    <h2>6 Entgelt für die Nutzung des DAC6 Trackers</h2>
    <h3>6.1 Jahresgebühr für die Vollversion des DAC6 Trackers</h3>
    <p>
      Für die Nutzung der Vollversion des DAC6 Trackers verpflichtet sich die
      Kundin, eine Jahresgebühr von EUR 4’800 im Voraus zu bezahlen.
    </p>
    <p>
      Mit Bezahlung der Jahresgebühr kann der DAC6 Tracker unbeschränkt benutzt
      werden und Mitarbeitende der Kundin können beliebig viele Kundenkonten
      einrichten und benutzen.
    </p>
    <h3>6.2 Steuern und Abgaben</h3>
    <p>
      Alle Preise verstehen sich exklusiv der gesetzlichen Mehrwertsteuer,
      Umsatzsteuer, Transaktionssteuer, Quellensteuer oder irgendeiner anderen
      Steuer respektive Abgabe dieser Art in irgendeiner Jurisdiktion. Falls
      eine derartige Steuer oder Abgabe gesetzlich von der Red Leafs geschuldet
      ist, wird Red Leafs diese zusätzlich in Rechnung stellen. Ist eine
      derartige Abgabe gemäss anwendbarem Gesetz von einem Kunden geschuldet
      (z.B. Reverse Charge Verfahren) ist die entsprechende Abrechnung und
      Bezahlung der Steuer Sache des Kunden.
    </p>
    <h3>6.3 Zahlungsmodalitäten</h3>
    <p>
      Die Kundin verpflichtet sich, die Rechnungen innerhalb von 30 Tagen nach
      Erhalt zu bezahlen.
    </p>
    <h2>7 Training und Unterstützung</h2>
    <p>
      Auf Anfrage bietet Red Leafs der Kundin Training und Unterstützung im
      Zusammenhang mit der EU-Richtlinie DAC6 und der Benutzung des DAC6
      Trackers an. Diese Leistungen werden separat in Rechnung gestellt.
    </p>
    <h2>8 Haftungsbegrenzung</h2>
    <p>
      Soweit gesetzlich zulässig ist die Haftung von Red Leafs ausgeschlossen.
    </p>
    <p>
      Insbesondere haftet Red Leafs nicht, falls die Kundin oder die Kundschaft
      der Kundin gestützt auf die Prüfungshandlungen des DAC6 Trackers keine
      Meldung erstattete, obwohl eine solche Meldung hätte erfolgen sollen, oder
      die Prüfungshandlungen nicht konform dokumentiert wurden.
    </p>
    <p>
      Die Kundin anerkennt ausdrücklich, dass die Prüfungshandlungen des DAC6
      Trackers sowie die Dokumentation lediglich informativen Charakter haben
      und eine individuelle Rechts- und Steuerberatung unter keinen Umständen
      ersetzt. Ferner nimmt die Kundin zustimmend zur Kenntnis, dass der DAC6
      Tracker keine Prüfungshandlung in Bezug auf die (Umsetzungs-)Gesetzgebung
      einzelner EU-Staaten beinhaltet.
    </p>
    <p>
      Red Leafs haftet ausdrücklich nicht, wenn die verschlüsselten Daten der
      Kundin nicht mehr rekonstruierbar sind.
    </p>
    <h2>9 Vertragsdauer und Kündigung</h2>
    <h3>9.1 Vertragsdauer</h3>
    <p>
      Dieser Vertrag tritt in Kraft im Zeitpunkt, indem die Kundin im DAC6
      Tracker das Kästchen "Ich stimme dem Vertrag über die Nutzung des DAC6
      Trackers zu" anklickt. Er dauert für eine unbestimmte Zeit und kann mit
      einer Kündigungsfrist von drei Monaten gekündigt werden.
    </p>
    <p>
      Die Nutzungsdauer der Vollversion des DAC6 Trackers wird in Ziffer 5.2
      geregelt.
    </p>
    <h3>9.2 Kündigung aus wichtigem Grund</h3>
    <p>
      Verletzt eine Partei den Vertrag, kann die andere Partei wahlweise am
      Vertrag festhalten und auf die Leistung verzichten oder den Vertrag
      jederzeit mit sofortiger Wirkung kündigen, wenn die andere Partei den
      Vertrag verletzt und die Verletzung nicht innert 14 Tagen nach deren
      Mitteilung durch die den Vertrag nicht verletzenden Partei behebt.
    </p>
    <p>
      Im Falle der Zahlungsunfähigkeit einer Partei, der Eröffnung eines
      Konkursverfahrens oder eines Nachlassvertrages oder eines ähnlichen
      Sachverhaltes kann die andere Partei diesen Vertrag mit sofortiger Wirkung
      kündigen.
    </p>
    <h3>9.3 Folgen der Kündigung</h3>
    <p>
      Mit Beendigung des Vertrages erlischt das Nutzungsrecht des DAC6 Trackers
      und die Kundin verliert den Zugang zu den verschlüsselten Daten. Die
      Kundin muss daher vor Beendigung des Vertrages die Kundendaten übernehmen,
      ansonsten Red Leafs berechtigt ist, diese Daten zu löschen.
    </p>
    <p>
      Red Leafs erstattet der Kundin keine Jahresgebühren zurück, wenn die
      Kundin den Vertrag vor Ende der Nutzungsdauer der Vollversion kündigt. Das
      Gleiche gilt, wenn Red Leafs den Vertrag aus wichtigem Grund kündigt.
    </p>
    <h2>10 Anwendbares Recht und Gerichtsstand</h2>
    <p>
      Dieser Vertrag unterliegt materiellem schweizerischem Recht, unter
      Ausschluss seiner Kollisionsnormen. Ausschliesslicher Gerichtsstand ist am
      Sitz von Red Leafs.
    </p>
  </div>
</div>
