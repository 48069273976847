<script>
  import {
    SignUpInput,
    SignUpErrors,
    SignUpInputCheckFlags,
    SignUpInteractor,
    UseLanguages,
  } from '@axelity/dac6trackerjs'

  // Routing
  import { push } from 'svelte-spa-router'

  // I18N
  import { _, locale } from 'svelte-i18n'

  // Elements
  import InputField from '../elements/InputField.svelte'
  import Button from '../elements/Button.svelte'
  import Checkbox from '../elements/Checkbox.svelte'
  import Dropdown from '../elements/Dropdown.svelte'
  import Loader from '../elements/Loader.svelte'

  let useLanguageList = new UseLanguages($locale).list()

  let fields = new SignUpInput({})
  let errors = new SignUpErrors()

  let inProcess = false

  const handleSignUp = async () => {
    const flags = new SignUpInputCheckFlags({
      username: true,
      memberName: true,
      email: true,
      password: true,
      passwordConfirm: true,
      language: true,
      acceptTerms: true,
    })

    errors = await fields.validate(flags)

    if (!fields.valid) return

    try {
      inProcess = true

      await new SignUpInteractor().execute(fields)

      inProcess = false

      push('/recoverykey')
    } catch (error) {
      if (error.message.includes('Status: 409')) {
        errors.username = $_('error.signUp.username.alreadyInUse')
      } else {
        errors.system = $_('error.system.unexpected') + error
      }
      inProcess = false
    }
  }
</script>

<style>
  .component {
    width: 100%;
    padding: 0 10%;
  }

  .input-field {
    width: 100%;
    margin: 2% 0;
  }

  .accept-terms p {
    margin-left: 30px;
    padding: 0;
  }

  .navigate {
    display: block;
    width: 100%;
    margin: 5% 0;
  }

  .navigate .next {
    float: right;
  }

  .error {
    font-weight: bold;
    color: red;
  }
</style>

<div class="component">
  <div class="error">{errors.system}</div>

  <div class="input-field">
    <InputField
      type="text"
      name="username"
      placeholder={$_('member.uniqueUsername.placeholder')}
      label={$_('member.uniqueUsername.label')}
      required={true}
      bind:value={fields.username} />
    <div class="error">{errors.username}</div>
  </div>

  <div class="input-field">
    <InputField
      type="text"
      name="fullname"
      placeholder={$_('elements.fullname.placeholder')}
      label={$_('elements.fullname.label')}
      required={true}
      bind:value={fields.memberName} />
    <div class="error">{errors.memberName}</div>
  </div>

  <div class="input-field">
    <InputField
      type="email"
      name="email"
      placeholder={$_('elements.email.placeholder')}
      label={$_('elements.email.label')}
      required={true}
      bind:value={fields.email} />
    <div class="error">{errors.email}</div>
  </div>

  <div class="input-field">
    <InputField
      type="password"
      name="password"
      label={$_('elements.password.label')}
      placeholder={$_('elements.password.placeholder')}
      required={true}
      bind:value={fields.password} />
    <div class="error">{errors.password}</div>
  </div>

  <div class="input-field">
    <InputField
      type="password"
      name="passwordConfirm"
      label={$_('elements.passwordConfirm.label')}
      placeholder={$_('elements.passwordConfirm.placeholder')}
      required={true}
      bind:value={fields.passwordConfirm} />
    <div class="error">{errors.passwordConfirm}</div>
  </div>

  <div class="input-field">
    <Dropdown
      name="language"
      label={$_('elements.language.label')}
      placeholder={$_('elements.language.placeholder')}
      options={useLanguageList}
      bind:value={fields.language} />
    <div class="error">{errors.language}</div>
  </div>

  <div class="accept-terms">
    <div class="checkbox">
      <Checkbox
        checked={fields.acceptTerms}
        name="terms"
        bind:value={fields.acceptTerms} />
    </div>
    <p>
      {$_('section.signUp.acceptTerms.label')} (
      <a target="_blank" rel="noopener noreferrer" href="/#/terms">
        {$_('section.signUp.userAgreement.label')}
      </a>
      )
    </p>

    <div class="error">{errors.acceptTerms}</div>
  </div>

  <div class="navigate">
    <div class="next">
      <Button
        disabled={!fields.acceptTerms}
        outline={false}
        on:click={handleSignUp}>
        {$_('action.signUp')}
      </Button>
    </div>
  </div>

</div>

{#if inProcess}
  <Loader />
{/if}
