<script>
  // I18N
  import { _, locale } from 'svelte-i18n'
</script>

<style>
  .bottomnav {
    background-color: transparent;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #383432;
    vertical-align: middle;
  }

  .bottomnav div li {
    float: left;
    display: block;
    text-align: left;
    padding: 12px 10px;
    text-decoration: none;
    font-size: 80%;
    color: #aaa;
  }

  a {
    color: #aaa;
    text-decoration: none;
  }

  a:hover {
    color: #aaa;
  }

  .first-line {
    width: 100%;
    height: 25px;
  }

  .first-line li {
    width: 100%;
  }

  .second-line {
    width: 100%;
    height: 25px;
  }

  li p {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 540px) {
    li p {
      margin: 0;
      padding: 0;
    }

    .bottomnav div li {
      padding: 8px 8px;
    }
  }
</style>

<div class="bottomnav">
  <div class="first-line">
    <li>
      <p class="copyright">
        &copy;{new Date().getFullYear()}
        <a href={$_('product.company.link')}>{$_('product.company.name')}</a>
        - {$_('product.ipRights')} (Version __VERSION__)
      </p>
    </li>
  </div>
  <div class="second-line">
    <li>
      <p class="terms">
        {#if $locale === 'de'}
           <a target="_blank" rel="noopener noreferrer" href="/#/terms">
             {$_('section.signUp.userAgreement.label')}
           </a>
        {:else}
           <a target="_blank" rel="noopener noreferrer" href="/#/terms_en">
            {$_('section.signUp.userAgreement.label')}
          </a>
       {/if}
      </p>
    </li>
  </div>
</div>
