<script>
  import {
    State,
    SignInInteractor,
    SignInInput,
    SignInErrors,
    SignInInputCheckFlags,
    GetAccountInteractor,
    GetContextInteractor,
    GetPassInteractor,
    QueryPersonsInteractor,
    QueryArrangementsInteractor,
    QueryDisclosuresInteractor,
  } from '@axelity/dac6trackerjs'

  // Routing
  import { push } from 'svelte-spa-router'

  // I18N
  import { _, locale } from 'svelte-i18n'

  // Elements
  import InputField from '../elements/InputField.svelte'
  import Button from '../elements/Button.svelte'
  import Loader from '../elements/Loader.svelte'

  let fields = new SignInInput({})
  let errors = new SignInErrors()

  let inProcess = false

  // Handler
  const handleSignIn = async () => {
    errors = await fields.validate(
      new SignInInputCheckFlags({
        username: true,
        password: true,
      })
    )

    if (!fields.valid) return

    try {
      inProcess = true

      await new SignInInteractor().execute(fields)

      if (State.isAuthenticated) {
        console.log('Authentication successful')
        locale.set(State.member.contact.language)
        await new GetAccountInteractor().execute()

        if (State.account) {
          console.log('Account found')
          await new GetContextInteractor().execute()

          if (State.context) {
            console.log('Context found')
            try {
              await new GetPassInteractor().execute()
              console.log('Passes loaded')
              await new QueryPersonsInteractor().execute()
              console.log('Persons loaded')
              await new QueryArrangementsInteractor().execute()
              console.log('Arrangements loaded')
              await new QueryDisclosuresInteractor().execute()
              console.log('Disclosures loaded')              
            } catch (error) {
              console.log(error.message)
            }
          } else {
            console.log('No context found')
          }
        } else {
          console.log('No account found')
        }

        inProcess = false

        push('/dashboard')
      } else {
        console.log('Authentication was not successful')
      }
    } catch (error) {
      errors.system = $_('error.signIn.failed')
      console.log(error.message)
      inProcess = false
    }
  }
</script>

<style>
  .component {
    width: 100%;
    padding: 0 10%;
  }

  .input-field {
    width: 100%;
    margin: 2% 0;
  }

  .navigate {
    display: block;
    width: 100%;
    margin: 5% 0;
  }

  .navigate .next {
    float: right;
  }

  .error {
    font-weight: bold;
    color: red;
  }
</style>

<div class="component">
  <div class="error">{errors.system}</div>

  <div class="input-field">
    <InputField
      type="text"
      name="username"
      placeholder={$_('member.username.placeholder')}
      label={$_('member.username.label')}
      required={true}
      bind:value={fields.username} />
    <div class="error">{errors.username}</div>
  </div>

  <div class="input-field">
    <InputField
      type="password"
      name="password"
      label={$_('elements.password.label')}
      placeholder={$_('elements.password.placeholder')}
      required={true}
      bind:value={fields.password}
      on:execute={handleSignIn} />
    <div class="error">{errors.password}</div>
  </div>

  <div class="navigate">
    <div class="next">
      <Button outline={false} on:click={handleSignIn}>
        {$_('action.signIn')}
      </Button>
    </div>
  </div>

</div>

{#if inProcess}
  <Loader />
{/if}
