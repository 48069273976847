<script>
  // Dispatcher
  import { createEventDispatcher } from 'svelte'

  // Elements
  import Arrow from './Arrow.svelte'

  // Parameter
  export let name = ''
  export let label = ''
  export let placeholder = ''
  export let value = ''
  export let options = []
  export let selectedItem = -1
  export let disabled = false

  let dispatch = createEventDispatcher()

  // Processing attributes
  let optionListVisible = false

  // If a value is available, we lookup the item and select it
  if (value !== '') {
    for (let i = 0; i < options.length; i++) {
      const option = options[i]
      if (option.key === value) {
        selectedItem = i
      }
    }
  }

  // Handler
  const toggleOptionList = () => {
    optionListVisible = !optionListVisible
    let style = getComputedStyle(document.querySelector('.selector'))
    let element = document.getElementById(`${name}-option-list`)
    if (optionListVisible) {
      element.style['display'] = 'flex'
      element.style['width'] = style.width
    } else {
      element.style['display'] = 'none'
    }
  }

  const changeValue = (event) => {
    selectedItem = parseInt(event.target.getAttribute('data-index'))
    value = options[selectedItem].key
    toggleOptionList()
    dispatch('change')
  }
</script>

<style>
  label {
    font-size: 90%;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  button {
    display: block;
    width: 100%;
    text-align: left;
    font-size: 100%;
    font-weight: 300;
    line-height: 40px;
    border: 1px solid #aaa;
    border-radius: 0px;
    margin-top: 0.2rem;
    padding: 0 0 0 10px;
    height: 40px;
    outline: none;
    background-color: #fff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .button-text {
    float: left;
    max-width: 90%;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .placeholder {
    color: #ccc;
  }

  .dropdown-option-list {
    display: none; /* hidden by default */
    position: absolute;
    width: 100%;
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: 200px;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    transition: display 0.5s;
  }

  .dropdown {
    position: relative;
  }

  .dropdown li {
    width: 100%;
    font-size: 100%;
    font-weight: 300;
    background-color: transparent;
    line-height: 40px;
    border-radius: 0px;
    padding: 0 0 0 10px;
    height: 40px;
    outline: none;
    list-style: none;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  /* Change color of dropdown links on hover */
  .dropdown li:hover {
    background-color: #fff8f3;
  }

  .arrow-sign {
    float: right;
    margin-right: 20px;
  }

  @media screen and (max-width: 540px) {
    .button-text {
      font-size: 85%;
    }
  }
</style>

<div class="dropdown">
  <label for={name}>{label}</label>
  <button
    class="selector"
    on:click={toggleOptionList}
    class:placeholder={selectedItem === -1}
    {disabled}>
    <span class="button-text">
      {selectedItem > -1 && options.length > 0 ? options[selectedItem].value : placeholder}
    </span>
    <div class="arrow-sign">
      <Arrow direction={optionListVisible ? 'up' : 'down'} />
    </div>
  </button>
  <div
    id={`${name}-option-list`}
    class="dropdown-option-list"
    class:options-visible={optionListVisible}>
    {#each options as { key, value, selected }, i}
      <li data-index={i} on:click={changeValue}>{value}</li>
    {/each}
  </div>
</div>
