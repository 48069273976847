<script>
  // Transition
  import { fade } from 'svelte/transition'
  import { sineInOut } from 'svelte/easing'
</script>

<style>
  .page {
    width: 100%;
    display: block;
    padding: 5% 10%;
    padding-top: 150px;
  }

  @media (max-width: 640px) {
    .page {
      margin-bottom: 100px;
    }
  }
</style>

<div class="page" transition:fade={{ duration: 200, easing: sineInOut }}>
  <slot />
</div>
