<script>
  import { onMount } from 'svelte'
  
  // Transition
  import { fade } from 'svelte/transition'
  import { sineInOut } from 'svelte/easing'

  // Routing
  import { push } from 'svelte-spa-router'

  // I18N
  import { _, locale } from 'svelte-i18n'

  // Navigation
  import Bottomnav from '../navigation/Bottomnav.svelte'

  // Elements
  import Button from '../elements/Button.svelte'

  // Handler
  const handleSignUp = () => {
    push('/signup')
  }

  const handleSignIn = () => {
    push('/signin')
  }

  const handleLocaleDE = () => {
    locale.set('de')
  }

  const handleLocaleEN = () => {
    locale.set('en')
  }

  onMount(() => {
    if ($locale === 'en') {
      locale.set('en')
    } else {
      locale.set('de')
    }
  })
</script>

<style>
  .locale {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    padding-top: 10px;
  }

  .locale-de {
    margin-right: 5px;
    cursor: pointer;
  }

  .locale-en {
    margin-left: 5px;
    cursor: pointer;
  }

  .locale-active {
    font-weight: bold;
  }

  .page {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    padding-bottom: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
  }

  .page-header {
    display: block;
    width: 100%;
    padding: 3% 20% 60px 20%;
    text-align: center;
  }

  .logo img {
    width: 100%;
    height: auto;
  }

  h1,
  h2 {
    text-align: center;
    padding: 7% 0 0 0;
    color: #383432;
  }

  h2 {
    color: #5c5552;
    font-weight: 300;
  }

  .page-content {
    width: 100%;
    padding: 5% 25% 5% 25%;
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  @media (min-width: 640px) {
    .page {
      max-width: none;
    }
  }

  @media (max-width: 540px) {
    .page {
      margin-bottom: 50px;
    }
    .page-header {
      padding: 3% 5% 30px 5%;
    }
    .sign-up {
      width: 100%;
      margin-bottom: 20px;
    }
    .sign-in {
      width: 100%;
    }
  }
</style>

<div class="page" transition:fade={{ duration: 200, easing: sineInOut }}>
  <div class="locale">
    <div class="locale-de" on:click={handleLocaleDE} class:locale-active={$locale === 'de'}>DE</div>
    <div class="locale-en" on:click={handleLocaleEN} class:locale-active={$locale === 'en'}>EN</div>
  </div>
  <div class="page-header">
    <div class="logo">
      <img src="images/dac6tracker_logo.png" alt="DAC6Tracker logo" />
    </div>
    <h1>{$_('section.home.heading.label')}</h1>
    <h2>{$_('section.home.subHeading.label')}</h2>
  </div>
  <div class="page-content">
    <div class="actions">
      <div class="sign-up">
        <Button outline={true} fullWidth={true} on:click={handleSignUp}>
          {$_('action.signUp')}
        </Button>
      </div>

      <div class="sign-in">
        <Button outline={false} fullWidth={true} on:click={handleSignIn}>
          {$_('action.signIn')}
        </Button>
      </div>
    </div>
  </div>
</div>
<Bottomnav />
