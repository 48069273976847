<script>
  // Dispatcher
  import { createEventDispatcher } from 'svelte'

  // Elements
  import Button from '../elements/Button.svelte'

  export let title = ''
  export let actionIcon
  export let actionLabel
  export let actionDisabled = false

  let dispatch = createEventDispatcher()

  const handleCancelClick = () => {
    dispatch('cancel')
  }

  const handleActionClick = () => {
    dispatch('action')
  }
</script>

<style>
  .action-ribbon {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #fff;
    position: fixed;
    top: 80px;
    border-bottom: 1px solid #eee;
    z-index: 1;
  }

  .cancel {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #eee;
    background-color: #fff;
  }

  .cancel:hover {
    background-color: #eee;
    cursor: pointer;
  }

  .title {
    width: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    background-color: #fff;
  }

  .action {
    width: calc(100% - 70% - 60px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
    background-color: #fff;
  }

  .button-text {
    display: flex;
    align-items: center;
  }

  .action-label {
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

<div class="action-ribbon">
  <div class="cancel" on:click={handleCancelClick}>
    <div class="material-icons">clear</div>
  </div>
  <div class="title">
    <h3>{title}</h3>
  </div>
  {#if actionIcon && actionLabel}
    <div class="action">
      <Button
        disabled={actionDisabled}
        outline={false}
        on:click={handleActionClick}>
        <div class="button-text">
          <span class="material-icons">{actionIcon}</span>
          <span class="action-label">{actionLabel}</span>
        </div>
      </Button>
    </div>
  {/if}
</div>
