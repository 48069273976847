<script>
  import { ChangePasswordInteractor } from '@axelity/dac6trackerjs'

  // Validator
  import validator from 'validator'

  // Transition
  import { fade } from 'svelte/transition'
  import { sineInOut } from 'svelte/easing'

  // I18N
  import { _ } from 'svelte-i18n'

  // Elements
  import InputField from '../elements/InputField.svelte'
  import Button from '../elements/Button.svelte'

  export let mode = 'enter' // enter | edit | view

  let fields = {
    password: '+++++++++++++++++', // just a fake value
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  }
  let valid = false
  let inProcess = false

  let errors = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
    system: '',
  }

  $: displayPassword = fields.password ? fields.password.replace(/./g, '*') : ''

  const toggleInProcess = () => {
    if (inProcess) {
      clearValues()
    } else {
      editValues()
    }
  }

  const editValues = () => {
    inProcess = true
  }

  const saveValues = async () => {
    valid = true
    errors.system = ''

    if (
      !validator.matches(
        fields.oldPassword,
        /(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/g
      )
    ) {
      valid = false
      errors.oldPassword = $_('error.signUp.password.invalid')
    } else {
      errors.oldPassword = ''
    }

    if (
      !validator.matches(
        fields.newPassword,
        /(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/g
      )
    ) {
      valid = false
      errors.newPassword = $_('error.signUp.password.invalid')
    } else {
      if (fields.newPassword === fields.oldPassword) {
        valid = false
        errors.newPassword = $_('error.profile.password.same')
      } else {
        errors.newPassword = ''
      }
    }

    if (fields.newPasswordConfirm !== fields.newPassword) {
      valid = false
      errors.newPasswordConfirm = $_('error.signUp.passwordConfirm.invalid')
    } else {
      errors.newPasswordConfirm = ''
    }

    if (!valid) return

    try {
      await new ChangePasswordInteractor().execute({
        oldPassword: fields.oldPassword,
        newPassword: fields.newPassword,
      })

      mode = 'edit'
      inProcess = false
    } catch (error) {
      errors.system = error
    }

    inProcess = false
  }

  const clearValues = () => {
    fields = {
      password: '+++++++++++++++++', // just a fake value
      newPassword: '',
      newPasswordConfirm: '',
      oldPassword: '',
    }
    inProcess = false
  }
</script>

<style>
  .property {
    width: 100%;
    color: #383432;
    margin-bottom: 20px;
  }

  .view {
    width: 100%;
    font-size: 100%;
    padding: 20px;
    box-shadow: 1px 1px 5px 1px #d3d3d3;
  }

  .view:hover {
    background-color: #fff8f3;
    cursor: pointer;
  }

  .view > p {
    margin: 0;
    padding: 0;
  }

  .action-icon {
    display: block;
    float: right;
    cursor: pointer;
  }

  .edit {
    padding: 20px;
    box-shadow: 1px 1px 5px 1px #efefef;
  }

  .edit > div {
    margin: 5px 0;
  }

  .edit .passwords {
    display: flex;
    justify-content: space-between;
  }

  .oldPassword {
    width: 49%;
  }

  .passwords .newPassword {
    width: 49%;
  }

  .action {
    padding-top: 10px;
    height: fit-content;
    display: flex;
    justify-content: space-between;
  }
</style>

<div class="property">

  <div class="error">{errors.system}</div>

  <div class="view" on:click={toggleInProcess}>
    {#if !inProcess && mode !== 'view'}
      <div id="editAction" class="material-icons action-icon">edit</div>
    {/if}
    {#if inProcess && mode !== 'view'}
      <div id="clearAction" class="material-icons action-icon">clear</div>
    {/if}
    {#if fields.password}
      <p>
        <span>{displayPassword}</span>
      </p>
    {:else}
      <p>
        <span>{$_('component.password.empty')}</span>
      </p>
    {/if}
  </div>

  {#if inProcess}
    <div
      id="property-edit"
      class="edit"
      transition:fade={{ duration: 200, easing: sineInOut }}>
      <div class="oldPassword">
        <InputField
          type="password"
          name="oldPassword"
          label={$_('elements.oldPassword.label')}
          placeholder={$_('elements.oldPassword.placeholder')}
          bind:value={fields.oldPassword}
          required={true} />
        <div class="error">{errors.oldPassword}</div>
      </div>
      <div class="passwords">
        <div class="newPassword">
          <InputField
            type="password"
            name="newPassword"
            label={$_('elements.newPassword.label')}
            placeholder={$_('elements.newPassword.placeholder')}
            bind:value={fields.newPassword}
            required={false} />
          <div class="error">{errors.newPassword}</div>
        </div>
        <div class="newPassword">
          <InputField
            type="password"
            name="newPasswordConfirm"
            label={$_('elements.newPassword.label')}
            placeholder={$_('elements.newPassword.placeholder')}
            bind:value={fields.newPasswordConfirm}
            required={false} />
          <div class="error">{errors.newPasswordConfirm}</div>
        </div>
      </div>
      <div class="action">
        <div class="cancel">
          <Button outline={true} on:click={clearValues}>
            {$_('action.cancel')}
          </Button>
        </div>
        <div class="save">
          <Button outline={false} on:click={saveValues}>
            {$_('action.save')}
          </Button>
        </div>
      </div>
    </div>
  {/if}
</div>
