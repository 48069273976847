<script>
  import {
    State,
    Person,
    PersonErrors,
    PersonCheckFlags,
    UseCountries,
    UsePersonKinds,
    UsePersonRoles,
    AddPersonInteractor,
    UpdatePersonInteractor,
    DeletePersonInteractor,
    UseIntermediaries,
  } from '@axelity/dac6trackerjs'
  import { equals, clone } from 'ramda'

  // Routing

  import { pop, replace } from 'svelte-spa-router'

  // I18N
  import { _, locale } from 'svelte-i18n'

  // Navigation;
  import Topnav from '../navigation/Topnav.svelte'

  // Components
  import ActionRibbon from '../components/ActionRibbon.svelte'
  import SurveyComponent from '../components/SurveyComponent.svelte'

  // Elements
  import InputField from '../elements/InputField.svelte'
  import Button from '../elements/Button.svelte'
  import Dropdown from '../elements/Dropdown.svelte'
  import MultiSelect from '../elements/MultiSelect.svelte'
  import Segment from '../elements/Segment.svelte'
  import Page from '../elements/Page.svelte'

  // Stores
  import { mixinTags, resultTags, restart } from '../stores/AssessmentStore'

  if (!State.isAuthenticated) {
    replace('/signin')
  }

  let useCountryList = new UseCountries($locale).list()
  let usePersonRoleList = new UsePersonRoles($locale).list()
  let usePersonKindList = new UsePersonKinds($locale).list()
  let useIntermediaryList = new UseIntermediaries().list()

  let fields = new Person({})
  let errors = new PersonErrors()

  let selectedCountriesOfResidence = []
  
  // used for change detection only
  let _selectedCountriesOfResidence = []

  const initialize = () => {
    if (State.currentPerson) {
      // Prepare receciving countries
      for (
        let i = 0;
        i < State.currentPerson.countriesOfResidence.length;
        i++
      ) {
        const country = State.currentPerson.countriesOfResidence[i]
        for (let j = 0; j < useCountryList.length; j++) {
          const element = useCountryList[j]
          if (element.key === country) {
            selectedCountriesOfResidence = [...selectedCountriesOfResidence, element]
            break
          }
        }
      }
      // Clone selected values for detecting changes
      _selectedCountriesOfResidence = clone(selectedCountriesOfResidence)

      fields = new Person(clone(State.currentPerson))
      if (
        fields.assessments &&
        fields.assessments.length > 0 &&
        fields.assessments[0].tags
      ) {
        resultTags.set(fields.assessments[0].tags)
      }
    } else {
      resultTags.set([])
    }
  }
  initialize()

  // Handler
  const handleCancel = () => {
    if (!equals(fields, State.currentPerson)) {
      let yes = confirm($_('warning.exitWithoutSaving.label'))
      if (!yes) return
    }
    State.setCurrentPerson(undefined)
    pop()
  }

  const handleRoleChange = () => {
    restart.set(true)
  }

  const handleIntermediaryChange = async () => {
    // This event is only fired if the person role is TAXPAYER
    // It prepares the intermediary tags for mixin (see SurveyComponent)
    if (fields.intermediary.trim() === '') {
      mixinTags.set([])
    } else {
      const person = State.getPerson(fields.intermediary)
      if (person.assessments && person.assessments.length > 0) {
        mixinTags.set(person.assessments[0].tags)
      } else {
        mixinTags.set([])
      }
    }
    restart.set(true)
  }

  const handleAssessmentCompleted = async () => {
    fields.assessments = [
      {
        assessed: new Date().toISOString(),
        tags: $resultTags,
      },
    ]
  }

  const handleSave = async () => {
    fields.countriesOfResidence = selectedCountriesOfResidence.map((v) => {
      return v.key
    })

    const flags = new PersonCheckFlags({
      displayName: true,
      role: true,
      kind: true,
      assessments: fields.role !== 'OTHER' ? true : false,
    })

    errors = await fields.validate(flags)

    if (!fields.valid) return

    try {
      if (!State.currentPerson) {
        await new AddPersonInteractor().execute(fields)
      } else {
        await new UpdatePersonInteractor().execute(fields)
      }
      State.setCurrentPerson(undefined)

      mixinTags.set([])
      resultTags.set([])
      restart.set(true)

      pop()
    } catch (error) {
      errors.system = error
    }
  }

  const handleDelete = async () => {
    let yes = confirm($_('person.deletePerson.label'))

    if (!yes) return

    try {
      await new DeletePersonInteractor().execute(State.currentPerson)
      State.setCurrentPerson(undefined)

      mixinTags.set([])
      resultTags.set([])
      restart.set(true)

      pop()
    } catch (error) {
      errors.system = error
    }
  }
</script>

<style>

</style>

<Topnav />
<ActionRibbon
  title={$_('section.person.header.label')}
  actionLabel={$_('action.save')}
  actionIcon="save_alt"
  on:cancel={handleCancel}
  on:action={handleSave} />

<Page>

  <div class="error">{errors.system}</div>

  <Segment title={$_('person.personDetails.label')} expand={true}>

    <InputField
      type="text"
      name="displayName"
      placeholder={$_('person.displayName.placeholder')}
      label={$_('person.displayName.label')}
      required={true}
      bind:value={fields.displayName} />
    <div class="error">{errors.displayName}</div>

    <Dropdown
      name="personKind"
      label={$_('person.personKind.label')}
      placeholder={$_('person.personKind.placeholder')}
      options={usePersonKindList}
      bind:value={fields.kind} />
    <div class="error">{errors.kind}</div>

    <Dropdown
      name="personRole"
      label={$_('person.personRole.label')}
      placeholder={$_('person.personRole.placeholder')}
      options={usePersonRoleList}
      on:change={handleRoleChange}
      bind:value={fields.role} />
    <div class="error">{errors.role}</div>

    {#if fields.role === 'TAXPAYER'}
      <Dropdown
        name="intermediary"
        label={$_('person.intermediary.label')}
        placeholder={$_('person.intermediary.placeholder')}
        options={useIntermediaryList}
        on:change={handleIntermediaryChange}
        bind:value={fields.intermediary} />
      <div class="error">{errors.intermediary}</div>
    {/if}
  </Segment>

  <Segment
    title={$_('person.assessment.label')}
    expand={fields.role.trim() !== '' && fields.role.trim() !== 'OTHER'}>
    <!-- It's necessary to separate the survey component because it cannot be repainted if role changes -->
    {#if fields.role === 'TAXPAYER'}
      <SurveyComponent
        assignTo={fields.role}
        locale={$locale.substr(0, 2)}
        on:completed={handleAssessmentCompleted} />
    {/if}
    {#if fields.role === 'INTERMEDIARY'}
      <SurveyComponent
        assignTo={fields.role}
        locale={$locale.substr(0, 2)}
        on:completed={handleAssessmentCompleted} />
    {/if}
  </Segment>

  <Segment title={$_('person.additionalInformation.label')} expand={false}>

    <MultiSelect
      availableOptions={useCountryList}
      bind:selectedOptions={selectedCountriesOfResidence}
      name="countriesOfResidence"
      label={$_('person.countriesOfResidence.label')}
      placeholder={$_('person.countriesOfResidence.placeholder')} />
    <div class="error">{errors.countriesOfResidence}</div>

    <InputField
      type="text"
      name="tin"
      placeholder={$_('person.tin.label')}
      label={$_('person.tin.label')}
      required={false}
      bind:value={fields.tin} />
    <div class="error">{errors.tin}</div>

    <InputField
      type="email"
      name="email"
      placeholder={$_('person.email.placeholder')}
      label={$_('person.email.label')}
      required={false}
      bind:value={fields.email} />
    <div class="error">{errors.email}</div>

  </Segment>

  {#if fields.kind === 'ORGANIZATION'}
    <Segment title={$_('person.organizationDetails.label')} expand={false}>

      <InputField
        type="text"
        name="organizationName"
        placeholder={$_('person.organizationName.placeholder')}
        label={$_('person.organizationName.label')}
        required={false}
        bind:value={fields.organization.name} />
      <div class="error">{errors.organization.name}</div>

    </Segment>
  {/if}

  {#if fields.kind === 'INDIVIDUAL'}
    <Segment title={$_('person.individualDetails.label')} expand={false}>

      <InputField
        type="text"
        name="firstName"
        placeholder={$_('person.firstName.placeholder')}
        label={$_('person.firstName.label')}
        required={false}
        bind:value={fields.individual.firstName} />
      <div class="error">{errors.individual.firstName}</div>

      <InputField
        type="text"
        name="middleName"
        placeholder={$_('person.middleName.placeholder')}
        label={$_('person.middleName.label')}
        required={false}
        bind:value={fields.individual.middleName} />
      <div class="error">{errors.individual.middleName}</div>

      <InputField
        type="text"
        name="lastName"
        placeholder={$_('person.lastName.placeholder')}
        label={$_('person.lastName.label')}
        required={false}
        bind:value={fields.individual.lastName} />
      <div class="error">{errors.individual.lastName}</div>

      <InputField
        type="date"
        name="dateOfBirth"
        placeholder={$_('person.dateOfBirth.placeholder')}
        label={$_('person.dateOfBirth.label')}
        required={false}
        bind:value={fields.individual.dateOfBirth} />
      <div class="error">{errors.individual.dateOfBirth}</div>

      <InputField
        type="text"
        name="placeOfBirth"
        placeholder={$_('person.placeOfBirth.placeholder')}
        label={$_('person.placeOfBirth.label')}
        required={false}
        bind:value={fields.individual.placeOfBirth} />
      <div class="error">{errors.individual.placeOfBirth}</div>

    </Segment>
  {/if}

  <Segment title={$_('person.addressDetails.label')} expand={false}>

    <InputField
      type="text"
      name="street"
      placeholder={$_('person.street.placeholder')}
      label={$_('person.street.label')}
      required={false}
      bind:value={fields.address.street} />
    <div class="error">{errors.address.street}</div>

    <InputField
      type="text"
      name="poBox"
      placeholder={$_('person.poBox.placeholder')}
      label={$_('person.poBox.label')}
      required={false}
      bind:value={fields.address.poBox} />
    <div class="error">{errors.address.poBox}</div>

    <InputField
      type="text"
      name="postCode"
      placeholder={$_('person.postCode.placeholder')}
      label={$_('person.postCode.label')}
      required={false}
      bind:value={fields.address.postCode} />
    <div class="error">{errors.address.postCode}</div>

    <InputField
      type="text"
      name="city"
      placeholder={$_('person.city.placeholder')}
      label={$_('person.city.label')}
      required={false}
      bind:value={fields.address.city} />
    <div class="error">{errors.address.city}</div>

    <Dropdown
      name="addressCountry"
      placeholder={$_('person.country.placeholder')}
      label={$_('person.country.label')}
      options={useCountryList}
      bind:value={fields.address.country} />
    <div class="error">{errors.address.country}</div>

  </Segment>

  {#if State.currentPerson}
    <Segment title={$_('person.deleteButton.label')} expand={false}>

      <div class="delete-button">
        <Button outline={false} on:click={handleDelete}>
          {$_('action.remove')}
        </Button>
      </div>

    </Segment>
  {/if}

</Page>
