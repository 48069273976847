export default {
  /**
   * product
   */
  product: {
    name: 'DAC6 Tracker',
    tagline:
      'Monitoring and reporting of cross-border reportable tax arrangements',
    company: {
      name: 'Red Leafs InnoTax AG',
      link: 'https://redleafsinnotax.com',
    },
    ipRights: 'All rights reserved',
    legal: 'Legal notice',
    privacy: 'Data policy',
    terms: 'Terms',
  },

  /**
   * elements
   */
  elements: {
    firstname: {
      placeholder: 'First name',
      label: 'First name',
    },
    lastname: {
      placeholder: 'Last name',
      label: 'Last name',
    },
    fullname: {
      placeholder: 'Full name',
      label: 'Full name',
    },
    nickname: {
      placeholder: 'Unique username',
      label: 'Username',
    },
    email: {
      placeholder: 'Valid email address',
      label: 'Email address',
    },
    password: {
      placeholder: 'Password (min. 8 characters)',
      label: 'Password',
    },
    passwordConfirm: {
      placeholder: 'Confirm password',
      label: 'Confirm password',
    },
    oldPassword: {
      placeholder: 'Current password',
      label: 'Current password',
    },
    newPassword: {
      placeholder: 'New password',
      label: 'New password',
    },
    emergencyKey: {
      placeholder: 'Format XXXXX-XXXXX-XXXXX-XXXXX-XXXXX',
      label: 'Emergency key',
    },
    language: {
      placeholder: 'Choose your language',
      label: 'Language',
    },
    displayName: {
      placeholder: 'Descriptive name',
      label: 'Name',
    },
    summary: {
      placeholder: 'Summary',
      label: 'Summary',
    },
    storageLocation: {
      placeholder: 'Select your storage location',
      label: 'Storage location (Cannot be changed afterwards)',
    },
    workspace: {
      placeholder: 'Select your workspace',
      label: 'Workspace',
    },
    recipients: {
      placeholder: 'Choose your recipients',
      label: 'Recipients',
    },
    personLabel: {
      placeholder: 'Label of the person',
      label: 'Label',
    },
    personKind: {
      placeholder: 'Kind of person',
      label: 'Kind',
    },
    personRole: {
      placeholder: 'Role of the person',
      label: 'Role',
    },
    legalForm: {
      placeholder: 'Form of legal entity',
      label: 'Legal form',
    },
    street: {
      placeholder: 'Street and house number',
      label: 'Street',
    },
    poBox: {
      placeholder: 'Post offic box',
      label: 'P.O. Box',
    },
    zipCode: {
      placeholder: 'Postal code',
      label: 'Postal code',
    },
    city: {
      placeholder: 'City',
      label: 'City',
    },
    country: {
      placeholder: 'Country',
      label: 'Country',
    },
    dateOfBirth: {
      placeholder: 'Date of birth',
      label: 'Date of birth',
    },
    placeOfBirth: {
      placeholder: 'Place of birth',
      label: 'Place of birth',
    },
    intermediary: {
      placeholder: 'Intermediary',
      label: 'Intermediary',
    },
    taxpayer: {
      placeholder: 'Client (Taxpayer)',
      label: 'Client',
    },
    activityLabel: {
      placeholder: 'Activity',
      label: 'Activity',
    },
  },

  /**
   * sections
   */
  section: {
    home: {
      heading: {
        label: 'DAC6 arrangements - effortless and safe monitoring',
      },
      subHeading: {
        label:
          'The clever way to manage cross-border reportable tax arrangements for customers and taxable persons.',
      },
    },
    signUp: {
      label: 'Sign-up',
      signedUpAlready: {
        label: 'Already signed up?',
      },
      header: {
        label: 'Start now and sign up for the free version!',
      },
      acceptTerms: {
        label: 'I agree to the contract for the use of the DAC6 Tracker',
      },
      userAgreement: {
        label: 'User agreement',
      },
    },
    signIn: {
      label: 'Sign in',
      notSignedUpYet: {
        label: 'Not signed up yet?',
      },
      header: {
        label: 'You can sign in here',
      },
      lostAccessData: {
        label: 'Have you lost your access data?',
      },
    },
    recover: {
      label: 'Recovery',
      header: {
        label: 'Here you can restore your account',
        instructions:
          'Use your emergency key that you received when you registered and set a new password. The username stays the same.',
      },
    },
    recoveryKey: {
      header: {
        label: 'Your emergency key',
        summary:
          'Please write down your emergency key and keep it in a safe place. With this key you can restore your account. Otherwise, your information will be permanently lost.',
        notAvailable:
          'Your emergency key is no longer available. You must have written it down and kept it in a safe place.',
        confirmation:
          'Please confirm that you have noted down your emergency key',
      },
    },
    dashboard: {
      header: {
        label: 'This is your overview',
      },
    },
    profile: {
      header: {
        label: 'Maintain your profile here',
      },
    },
    accounts: {
      header: {
        label: 'Accounts',
      },
      empty: "You don't have any accounts yet",
    },
    account: {
      header: {
        label: 'Account',
      },
    },
    persons: {
      header: {
        label: 'Legal entities and individuals',
      },
      empty: "You don't have any legal entities and individuals yet",
    },
    person: {
      header: {
        label: 'Person',
      },
    },
    arrangements: {
      header: {
        label: 'Arrangements',
      },
      empty: 'There are no arrangements available yet',
    },
    arrangement: {
      header: {
        label: 'Arrangement',
      },
    },
    disclosures: {
      header: {
        label: 'Disclosures',
      },
      empty: 'There are no disclosures available yet',
    },
    disclosure: {
      header: {
        label: 'Disclosure',
      },
    },
    notFound: {
      header: {
        label: 'Sorry, we could not find the requested page',
      },
    },
    signOff: {
      header: {
        label: 'You are now signed off',
      },
    },
  },

  /**
   * menu
   */
  menu: {
    dashboard: 'Dashboard',
    profile: 'Profile',
    accounts: 'Accounts',
    account: 'Account',
    persons: 'Persons',
    arrangements: 'Arrangements',
    disclosures: 'Disclosures',
    signOff: 'Sign off',
  },

  /**
   * components
   */
  component: {
    email: {
      label: 'Email address',
      empty: 'Your email address is still missing here',
    },
    password: {
      label: 'Password',
      empty: 'Your password is still missing here',
    },
    language: {
      label: 'Language',
      empty: 'Your language setting is still missing here',
    },
    workspace: {
      label: 'Workspace',
      empty: 'Enter your workspace details here',
    },
    workspaceSelection: {
      label: 'Workspace',
      empty: 'Select your default workspace here',
    },
    intermediarySelection: {
      label: 'Intermediary',
      empty: 'Select the corresponding intermediary',
    },
    taxpayerSelection: {
      label: 'Client (Taxpayer)',
      empty: 'Select the corresponding client',
    },
    topic: {
      label: 'Topic details',
      empty: 'Enter your topic details here',
    },
    participants: {
      label: 'Participants',
      placeholder: 'Invite other participants',
      empty: 'No participants were added',
    },
    person: {
      label: 'Person (Intermediary, Client, others)',
      placeholder: 'Enter your person details here',
      empty: 'No person details were added',
    },
    address: {
      label: 'Address',
      placeholder: 'Enter your address details here',
      empty: 'No address details were added',
    },
    individual: {
      label: 'Individual',
      placeholder: 'Enter your details for the individual here',
      empty: 'No individual details were added',
    },
    assessment: {
      intermediary: 'Intermediary',
      taxpayer: 'Relevant taxpayer',
      service: 'Consulting services',
      done: 'The assessment is completed',
    },
    activity: {
      label: 'Activity',
      placeholder: 'Enter your details for the activity here',
      empty: 'No activity details were added',
    },
  },

  dashboard: {
    account: {
      title: 'Account',
      empty: 'There is no account available',
    },
    persons: {
      title: 'Legal entities and Individuals',
      empty: 'There are no legal entities or individuals available yet',
    },
    arrangements: {
      title: 'Arrangements',
      empty: 'There are no arrangements available yet',
    },
    disclosures: {
      title: 'Disclosures / Messages',
      empty: 'There are no disclosures available yet',
    },
  },

  /**
   * actions
   */
  action: {
    signUp: 'Sign up',
    signIn: 'Sign in',
    recover: 'Recover',
    back: 'Back',
    next: 'Next',
    verify: 'Verify',
    edit: 'Edit',
    save: 'Save',
    apply: 'Apply',
    cancel: 'Cancel',
    remove: 'Remove',
    invite: 'Invite',
    continue: 'Continue',
    reset: 'Reset',
    show: 'Show',
    create: 'Create',
    addAccount: 'Add account',
    addPerson: 'Add person',
    addArrangement: 'Add arrangement',
    createDisclosure: 'Create disclosure',
    createSummary: 'Create summary',
    createFullReport: 'Create full report',
    createXMLde: 'XML-Record for Germany',
    upgrade: 'Upgrade',
    downgrade: 'Downgrade',
    pdfGenerate: 'Generate PDF',
    uploadDocument: "Upload document",
    download: 'Download'
  },

  /**
   * storage locations
   */
  storageLocation: {
    ch: 'Switzerland',
    de: 'Germany',
    fr: 'France',
    no: 'Norway',
  },

  /**
   * languages
   */
  language: {
    de: 'Deutsch',
    en: 'English',
  },

  /**
   * person kind
   */
  personKind: {
    organization: 'Legal entity',
    individual: 'Individual',
  },

  /**
   * person role
   */
  personRole: {
    intermediary: 'Intermediary',
    taxpayer: 'Client (Taxpayer)',
    other: 'Other',
  },

  /**
   * Person
   */
  person: {
    label: {
      singular: 'Person',
      plural: 'Persons',
    },
    header: {
      displayName: 'Name',
      kind: 'Kind',
      role: 'Role',
      assessed: 'Last assessment',
      file: 'File',
      action: 'Action',
    },
    deletePerson: {
      label: 'Do you really want to delete the person?',
    },
    personDetails: {
      label: 'Organization or indivdual details',
    },
    displayName: {
      label: 'Display name',
      placeholder: 'Meaningful Name',
    },
    personKind: {
      label: 'Kind',
      placeholder: 'Kind of person',
    },
    personRole: {
      label: 'Role',
      placeholder: 'Role of person',
    },
    intermediary: {
      label: 'Intermediary',
      placeholder: 'Intermediary',
    },
    additionalInformation: {
      label: 'Additional person information',
    },
    countriesOfResidence: {
      label: 'Countries of residence',
      placeholder: 'Please select at least one country of residence',
    },
    tin: {
      label: 'Tax identification number',
      placeholder: 'Tax identification number',
    },
    email: {
      label: 'Email address',
      placeholder: 'Email address',
    },
    organizationDetails: {
      label: 'Organization details',
    },
    organizationName: {
      label: 'Organization name',
      placeholder: 'Organization name',
    },
    individualDetails: {
      label: 'Individual details',
    },
    firstName: {
      label: 'First name',
      placeholder: 'First name',
    },
    middleName: {
      label: 'Middle name',
      placeholder: 'Middle name',
    },
    lastName: {
      label: 'Last name',
      placeholder: 'Last name',
    },
    dateOfBirth: {
      label: 'Date of birth',
      placeholder: 'Date of birth',
    },
    placeOfBirth: {
      label: 'Place of birth',
      placeholder: 'Place of birth',
    },
    addressDetails: {
      label: 'Address details',
    },
    street: {
      label: 'Street',
      placeholder: 'Street and number',
    },
    poBox: {
      label: 'P.O. Box',
      placeholder: 'Post office box',
    },
    postCode: {
      label: 'Post code',
      placeholder: 'Post code',
    },
    city: {
      label: 'City',
      placeholder: 'City',
    },
    country: {
      label: 'Country',
      placeholder: 'Country',
    },
    assessment: {
      label: 'Assessment',
      placeholder: 'Assessment',
    },
    deleteButton: {
      label: 'Delete person',
    },
  },

  /**
   * account
   */
  account: {
    label: {
      singular: 'Account',
      plural: 'Accounts',
    },
    header: {
      displayName: 'Name',
      accountType: 'Plan',
      company: 'Company',
      contact: 'Contact',
      phone: 'Phone',
      email: 'Email',
      since: 'Since',
      premium: 'Premium',
      action: 'Action',
    },
    credentials: {
      username: 'Administrator ID',
      password: 'Administrator Password',
    },
    type: {
      free: 'Free',
      premium: 'Premium',
    },
    noAccount: {
      label: 'No account available',
    },
    noAccounts: {
      label: 'No accounts available',
    },
    deleteAccount: {
      label: 'Do you really want to delete the account?',
    },
    checkoutMember: {
      label: 'Do you really want to remove the person from the user group?',
    },
    maxReached: {
      label: 'You have reached the maximum number of possible arrangements with the account type you have chosen'
    },
    accountDetails: {
      label: 'Account details',
      placeholder: 'Account details',
    },
    displayName: {
      label: 'Display name',
      placeholder: 'Meaningful name',
    },
    plan: {
      label: 'Plan',
      placeholder: 'Select a plan',
    },
    activationKey: {
      label: 'Activation key',
      placeholder: 'Activation key',
    },
    storageAccount: {
      label: 'Storage place',
      placeholder: 'Storage place',
    },
    publicBusinessName: {
      label: 'Public business name',
      placeholder: 'Official company name',
    },
    contactDetails: {
      label: 'Contact details',
      placeholder: 'Contact details',
    },
    contactName: {
      label: 'Contact person',
      placeholder: 'Contact person in charge',
    },
    email: {
      label: 'Email address',
      placeholder: 'Email address of contact person',
    },
    phone: {
      label: 'Phone',
      placeholder: 'Phone number of contact person',
    },
    language: {
      label: 'Language',
      placeholder: 'Language of contact person',
    },
    addressDetails: {
      label: 'Address details',
      placeholder: 'Address details',
    },
    street: {
      label: 'Street',
      placeholder: 'Street',
    },
    postCode: {
      label: 'Post code',
      placeholder: 'Post code',
    },
    city: {
      label: 'City',
      placeholder: 'City',
    },
    country: {
      label: 'Country',
      placeholder: 'Country',
    },
    deleteButton: {
      label: 'Delete account',
    },
  },

  /**
   * member
   */
  member: {
    label: {
      singular: 'User',
      plural: 'Users',
    },
    deleteMember: {
      label: 'Do you really want to delete your user?',
    },
    profile: {
      label: 'User profile',
      placeholder: 'User profile',
    },
    userDetails: {
      label: 'User details',
      placeholder: 'User details',
    },
    username: {
      label: 'Username',
      placeholder: 'Username',
    },
    uniqueUsername: {
      label: 'Unique username (can no longer be changed)',
      placeholder: 'Unique username',
    },
    memberName: {
      label: 'Full name',
      placeholder: 'Full name',
    },
    email: {
      label: 'Email address',
      placeholder: 'Email address',
    },
    mobile: {
      label: 'Mobile number',
      placeholder: 'Mobile number',
    },
    language: {
      label: 'Language',
      placeholder: 'Language',
    },
    deleteButton: {
      label: 'Delete user',
    },
  },

  /**
   * arrangement
   */
  arrangement: {
    label: {
      singular: 'Arrangement',
      plural: 'Arrangements',
    },
    header: {
      displayName: 'Name',
      taxpayer: 'Taxpayer',
      assessed: 'Last assessment',
      file: 'File',
      phase: 'Phase',
      action: 'Action',
    },
    arrangementDetails: {
      label: 'Arrangement details',
    },
    displayName: {
      label: 'Display name',
      placeholder: 'Meaningful name',
    },
    reference: {
      label: 'Internal reference',
      placeholder: 'Internal reference',
    },
    taxpayer: {
      label: 'Taxpayer',
      placeholder: 'Taxpayer',
    },
    comment: {
      label: 'Internal comment',
      placeholder: 'Internal comment',
    },
    assessment: {
      label: 'Assessment',
      placeholder: 'Assessment',
    },
    deleteArrangement: {
      label: 'Do you really want to delete the arrangement?',
    },
    deleteButton: {
      label: 'Delete arrangement',
    },
    documentsTitle: {
      label: 'Documents'
    },
    documentName : {
      label: 'Filename'
    },
    documentUploadDate : {
      label: 'Upload date'
    },
    documentAction : {
      label: 'Action'
    },
    documentsPresentWarning: {
      label: 'There are still existing documents. Please remove them in order to delete the arrangement.'
    }
  },

  /**
   * disclosure
   */
  disclosure: {
    label: {
      singular: 'Disclosure',
      plural: 'Disclosures',
    },
    report: {
      summary: 'summary',
      full: 'full',
    },
    header: {
      name: 'Name',
      taxpayer: 'Taxpayer',
      implementingDate: '1st implementation',
      action: 'Action',
    },
    arrangementSelection: {
      label: 'Selection of arrangements to file',
      placeholder: 'Selection of arrangements to file',
    },
    arrangement: {
      label: 'Arrangement',
      placeholder: 'Arrangement',
    },
    disclosureDetails: {
      label: 'Disclosure details',
    },
    messageId: {
      label: 'Message number',
      placeholder: 'Message number',
    },
    arrangementId: {
      label: 'Arrangement number',
      placeholder: 'Arrangement number',
    },
    disclosureId: {
      label: 'Disclosure number',
      placeholder: 'Disclosure number',
    },
    initialDisclosure: {
      label: 'Initial notification marketable arrangement',
      placeholder: 'Initial notification marketable arrangement',
    },
    receivingCountries: {
      label: 'Receiving countries',
      placeholder: 'Receiving countries',
    },
    discloserDetails: {
      label: 'Discloser details',
      placeholder: 'Discloser',
    },
    discloser: {
      label: 'Discloser',
      placeholder: 'Discloser',
    },
    intermediaryNexus: {
      label: 'Relation to the Member State',
      placeholder: 'Relation to the Member State',
    },
    intermediaryCapacity: {
      label: 'Intermediary role',
      placeholder: 'Intermediary role',
    },
    taxpayerNexus: {
      label: 'Relation to the Member State',
      placeholder: 'Relation to the Member State',
    },
    taxpayerCapacity: {
      label: `Reason of taxpayers' disclosure`,
      placeholder: `Reason of taxpayers' disclosure`,
    },
    taxpayerList: {
      label: 'List of taxpayers',
      placeholder: 'List of taxpayers',
    },
    implementingDate: {
      label: 'Start of implementation',
      placeholder: 'Start of implementation',
    },
    relevantTaxpayers: {
      label: 'Relevant taxpayers',
      placeholder: 'Relevant taxpayers',
    },
    intermediaryList: {
      label: 'List of involved intermediaries',
      placeholder: 'List of involved intermediaries',
    },
    involvedIntermediaries: {
      label: 'Involved intermediaries',
      placeholder: 'Involved intermediaries',
    },
    affectedPersonList: {
      label: 'List of affected persons',
      placeholder: 'List of affected persons',
    },
    affectedPersons: {
      label: 'Affected persons',
      placeholder: 'Affected persons',
    },
    messageReason: {
      label: 'Reason for the message',
      placeholder: 'Reason for the message',
    },
    reason: {
      label: 'Reason',
      placeholder: 'Reason',
    },
    arrangementDetails: {
      label: 'Arrangement details',
      placeholder: 'Arrangement details',
    },
    arrangementName: {
      label: 'Name',
      placeholder: 'Name',
    },
    description: {
      label: 'Description',
      placeholder: 'Description',
    },
    language: {
      label: 'Language',
      placeholder: 'Language',
    },
    economicValue: {
      label: 'Economic value',
      placeholder: 'Economic value',
    },
    currency: {
      label: 'Currency',
      placeholder: 'Currency',
    },
    dac6D1OtherInfo: {
      label: 'Description for hallmark DAC6D1Others',
      placeholder: 'Description for hallmark DAC6D1Others',
    },
    mainBenefit: {
      label: 'A tax benefit can be obtained through this arrangement',
      placeholder: 'A tax benefit can be obtained through this arrangement',
    },
    deleteDisclosure: {
      label: 'Do you really want to delete the disclosure?',
    },
    deleteButton: {
      label: 'Delete disclosure',
    },
  },

  xmlReportDE: {
    header: 'XML-Record for BZSt Germany',
    elmakomHeader: 'ELMAKOM information',
    accountId: {
      label: 'Portal account ID',
      placeholder: '10-digit account ID',
    } ,
    authTaxNumber: {
      label: 'BZSt number',
      placeholder: 'BZSt number, starts with BZ of BX followed by 9 digits',
    },
    customerId: {
      label: `Customer's own ID`,
      placeholder: 'Assignment to own information system',
    },
  },

  /**
   * legal forms
   */
  legalForm: {
    publicLimitedCompany: 'Public limited company',
    limitedLiabilityCompany: 'Limited liability company',
    establishment: 'Establishment',
    foundation: 'Foundation',
    other: 'Other',
  },

  /**
   * assessment
   */
  assessment: {
    section: {
      intermediary: 'Intermediary',
      taxpayer: 'Client (Taxpayer)',
      activity: 'Activity',
    },
    result: {
      intermediary_reporting_obligation_yes: 'reporting obligation',
      intermediary_reporting_obligation_no: 'no reporting obligation',
    },
  },

  /**
   * warnings
   */
  warning: {
    exitWithoutSaving: {
      label:
        'Die Eingaben sind unvollständig oder noch nicht gespeichert. Wollen Sie die Seite trotzdem verlassen?',
    },
  },

  /**
   * errors
   */

  error: {
    system: {
      unexpected: 'An unexpected error occurred: ',
    },
    signUp: {
      memberName: {
        invalid: 'Please enter your first name and last name',
      },
      email: {
        invalid: 'Please enter a valid email address',
      },
      password: {
        invalid:
          'The password must be at least 8 characters long and contain at least one upper and one lower case letter, one digit and one special character',
      },
      passwordConfirm: {
        invalid: 'Please repeat the password',
      },
      language: {
        invalid: 'Please select a language',
      },
      acceptTerms: {
        invalid: 'Please accept the terms',
      },
      username: {
        alreadyInUse:
          'The selected user name is already in use. Please choose another unique user name.',
      },
    },
    signIn: {
      failed:
        'Unfortunately the sign-in was not successful. Please check your data and try again',
    },
    recover: {
      emergencyKey: {
        invalid:
          'Please enter your emergency key in the format XXXXX-XXXXX-XXXXX-XXXXXX-XXXXXX',
      },
    },
    profile: {
      password: {
        same: 'The new password must be different from the old one',
      },
    },
  },

  toaster: {
    documentUploaded: 'Document uploaded!',
    documentDeleted: 'Document deleted!'
  }
}
