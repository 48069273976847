<script>
  import Router from 'svelte-spa-router'

  // Navigation
  import Sidebar from './navigation/Sidebar.svelte'

  // Routes
  import Home from './routes/Home.svelte'
  import SignUp from './routes/SignUp.svelte'
  import SignIn from './routes/SignIn.svelte'
  import Recover from './routes/Recover.svelte'
  import RecoveryKey from './routes/RecoveryKey.svelte'
  import Account from './routes/Account.svelte'
  import Terms from './routes/Terms.svelte'
  import TermsEN from './routes/TermsEN.svelte'
  import NotFound from './routes/NotFound.svelte'
  import Dashboard from './routes/Dashboard.svelte'
  import Profile from './routes/Profile.svelte'
  import Person from './routes/Person.svelte'
  import Persons from './routes/Persons.svelte'
  import Arrangement from './routes/Arrangement.svelte'
  import Arrangements from './routes/Arrangements.svelte'
  import Disclosure from './routes/Disclosure.svelte'
  import Disclosures from './routes/Disclosures.svelte'
  import SignOff from './routes/SignOff.svelte'
  import Accounts from './routes/Accounts.svelte'
  import XMLdeRecord from './routes/XMLdeRecord.svelte'

  // Toast
  import { SvelteToast } from '@zerodevx/svelte-toast'
  const options = {
    duration: 2500,
    reversed: true,
    intro: { y: 192 }
  }

  const routes = {
    '/': Home,
    '/signup': SignUp,
    '/signin': SignIn,
    '/recover': Recover,
    '/recoverykey': RecoveryKey,
    '/account': Account,
    '/terms': Terms,
    '/terms_en': TermsEN,
    '/dashboard': Dashboard,
    '/profile': Profile,
    '/person': Person,
    '/persons': Persons,
    '/arrangement': Arrangement,
    '/arrangements': Arrangements,
    '/disclosure': Disclosure,
    '/disclosures': Disclosures,
    '/xmlderecord': XMLdeRecord,
    '/signoff': SignOff,
    '/_admin/accounts': Accounts,
    '*': NotFound,
  }
</script>

<style>
  :root {
    --toastBackground: #ec6907;
    --toastColor: white;
    --toastBarBackground: white;
    --toastContainerTop: auto;
    --toastContainerRight: auto;
    --toastContainerBottom: 2rem;
    --toastContainerLeft: calc(50vw - 8rem);
  }

  .app {
    position: relative;
    width: 100%;
  }

  .app-routes {
    width: 100%;
    height: 100%;
  }
</style>

<div class="app">
  <Sidebar />
  <div class="app-routes">
    <SvelteToast {options} />
    <Router {routes} />
  </div>
</div>
