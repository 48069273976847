<script>
  import { createEventDispatcher } from 'svelte'
  export let name = ''
  export let placeholder = ''
  export let value = ''

  const dispatch = createEventDispatcher()

  const handleInput = (e) => {
    value = e.target.value
    dispatch('input')
  }

  const handleKeyRelease = (e) => {
    if (e.keyCode === 13) {
      dispatch('execute')
    }
  }
</script>

<style>
  .input-field {
    position: relative;
    width: 100%;
    user-select: none;
  }

  input {
    display: block;
    width: 100%;
  }

  input {
    font-size: 100%;
    font-weight: 300;
    color: #383432;
    background-color: transparent;
    line-height: 20px;
    border: 1px solid #ececec;
    border-radius: 0px;
    padding: 0 0 0 10px;
    outline: none;
  }

  input {
    background-image: url('images/searchIcon.png');
    background-position: 5px 10px;
    background-repeat: no-repeat;
    background-size: 20px;
    height: 40px;
    padding-left: 30px;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ccc;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ccc;
  }
</style>

<div class="input-field">
  <input
    type="text"
    {name}
    id={name}
    {placeholder}
    {value}
    on:input={handleInput}
    on:keyup={handleKeyRelease} />
</div>
