<script>
  import { onMount } from 'svelte'
  
  import { State } from '@axelity/dac6trackerjs'

  // Transition
  import { fade } from 'svelte/transition'
  import { sineInOut } from 'svelte/easing'

  // Routing
  import { push } from 'svelte-spa-router'

  // I18N
  import { _, locale } from 'svelte-i18n'

  // Components
  import SignInComponent from '../components/SignInComponent.svelte'

  State.setLocale($locale)

  // Handler
  const handleHome = (e) => {
    e.preventDefault()
    e.stopPropagation()
    push('/')
  }

  const handleSignUp = (e) => {
    e.preventDefault()
    e.stopPropagation()
    push('/signup')
  }

  const handleRecover = (e) => {
    e.preventDefault()
    e.stopPropagation()
    push('/recover')
  }

  const handleLocaleDE = () => {
    locale.set('de')
  }

  const handleLocaleEN = () => {
    locale.set('en')
  }

  onMount(() => {
    if ($locale === 'en') {
      locale.set('en')
    } else {
      locale.set('de')
    }
  })  
</script>

<style>
  .locale {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    padding-top: 10px;
  }

  .locale-de {
    margin-right: 5px;
    cursor: pointer;
  }

  .locale-en {
    margin-left: 5px;
    cursor: pointer;
  }

  .locale-active {
    font-weight: bold;
  }

  .page {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
  }

  .page-header {
    display: block;
    width: 100%;
    padding: 5% 20% 60px 20%;
  }

  .page-header p {
    text-align: center;
  }

  .logo img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }

  h2 {
    color: #383432;
    text-align: center;
    padding: 5% 0 0 0;
  }

  .page-content {
    width: 100%;
    padding: 0 25% 5% 25%;
  }

  @media (min-width: 640px) {
    .page {
      max-width: none;
    }
  }

  @media (max-width: 540px) {
    .page {
      margin-bottom: 100px;
    }
    .page-header {
      padding: 3% 5% 30px 5%;
    }
    .page-content {
      padding: 3% 5% 30px 5%;
    }
  }

  @media (max-width: 992px) {
    .page {
      margin-bottom: 100px;
    }
    .page-header {
      padding: 0 15% 60px 15%;
    }
    .page-content {
      padding: 0 15% 60px 15%;
    }
  }
</style>

<div class="page" transition:fade={{ duration: 200, easing: sineInOut }}>
  <div class="locale">
    <div class="locale-de" on:click={handleLocaleDE} class:locale-active={$locale === 'de'}>DE</div>
    <div class="locale-en" on:click={handleLocaleEN} class:locale-active={$locale === 'en'}>EN</div>
  </div>

  <div class="page-header">
    <div class="logo">
      <img
        src="images/dac6tracker_logo.png"
        alt="DAC6Tracker logo"
        on:click={handleHome} />
    </div>
    <h2>{$_('section.signIn.header.label')}</h2>
    <p>
      {$_('section.signIn.notSignedUpYet.label')}
      <a href="#!" on:click={handleSignUp}>{$_('action.signUp')}</a>
    </p>
  </div>
  <div class="page-content">
    <SignInComponent />
  </div>
  <div class="page-footer">
    <p>
      {$_('section.signIn.lostAccessData.label')}
      <a href="#!" on:click={handleRecover}>{$_('action.recover')}</a>
    </p>
  </div>
</div>
