<script>
  import {
    State,
    QueryArrangementsInteractor,
    QueryAssetsInteractor,
    AddAssetInteractor,
    UseArrangements,
    ArrangementReport,
    ROBOTO_FONTS,
  } from '@axelity/dac6trackerjs'

  import { onMount } from 'svelte'

  // Transition
  import { fade } from 'svelte/transition'
  import { sineInOut } from 'svelte/easing'

  // Routing
  import { push, pop, replace } from 'svelte-spa-router'

  // I18N
  import { _ } from 'svelte-i18n'

  // Time
  import moment from 'moment'

  // Navigation;
  import Topnav from '../navigation/Topnav.svelte'

  // Components
  import ActionRibbon from '../components/ActionRibbon.svelte'

  // Elements
  import SearchField from '../elements/SearchField.svelte'
  import Loader from '../elements/Loader.svelte'
  import { toast } from '@zerodevx/svelte-toast'

  if (!State.isAuthenticated) {
    replace('/signin')
  }

  let menuId = ''

  let arrangements = []
  let arrangementsAll = [] // used for reset filter only

  let sortByNameAsc = false
  let sortByTaxpayerAsc = false
  let sortByDateAsc = false
  let sortByPhaseAsc = false

  let searchArgument = ''

  let errors = {
    system: '',
  }

  let inProcess = false

  let documentUploadInput

  // Handler
  const handleCancel = () => {
    menuId = ''
    pop()
  }

  const handleNew = () => {
    // Limit of FREE version
    if (arrangements.length === State.maxArrangements) {
      alert($_('account.maxReached.label'))
      return
    }
    State.setCurrentArrangement(undefined)
    State.setAssets([])
    menuId = ''
    push('/arrangement')
  }

  const handleEdit = (e) => {
    menuId = ''
    State.setCurrentArrangement(State.getArrangement(e.target.dataset.key))
    push('/arrangement')
  }

  const handleToggleContextMenu = (e) => {
    if (menuId === '') {
      menuId = e.target.dataset.key
    } else {
      menuId = ''
    }
  }

  const handleArrangementReport = (e) => {
    if (menuId !== '') {
      menuId = ''
      State.setCurrentArrangement(State.getArrangement(e.target.dataset.key))

      const dd = new ArrangementReport().create({
        disclosure: State.currentArrangement,
        locale: State.locale,
      })
      pdfMake.fonts = ROBOTO_FONTS

      const pdfDocGenerator = pdfMake.createPdf(dd)
      pdfDocGenerator.getBlob((blob) => {
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${State.currentArrangement.reference}_${$_('disclosure.arrangement.label')}.pdf`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }
  }

  const convertFile = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        const arrayBuffer = reader.result
        resolve(arrayBuffer)
      }
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  }

  const handleUploadDocument = async (event) => {
    if (menuId !== '') {
      inProcess = true
      const uploadDate = new Date().toLocaleString('de-CH')
      const extractDate = uploadDate.split(',')
      const file = event.target.files[0]

      if (file) {
        const convertedFile = await convertFile(file)

        if (convertedFile) {
          try {
            await new AddAssetInteractor().execute({
              displayName: file.name,
              arrangementId: menuId,
              uploadDate: extractDate[0],
              file: convertedFile,
            })
          } catch (error) {
            console.log(error.message)
            inProcess = false
          } finally {
            reFetchAssets()
            menuId = ''
            inProcess = false
            toast.push($_('toaster.documentUploaded'))
          }
        }
      }
    }
  }

  const sortByNameHandler = () => {
    if (sortByNameAsc) {
      // Ascending
      sortByNameAsc = false
      arrangements = arrangements.sort((a, b) => {
        if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
          return 1
        }
        if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
          return -1
        }
        return 0
      })
    } else {
      // Descending
      sortByNameAsc = true
      arrangements = arrangements.sort((a, b) => {
        if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
          return -1
        }
        if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
          return 1
        }
        return 0
      })
    }
  }

  const sortByTaxpayerHandler = () => {
    if (sortByTaxpayerAsc) {
      // Ascending
      sortByTaxpayerAsc = false
      arrangements = arrangements.sort((a, b) => {
        if (a.taxpayer.toLowerCase() > b.taxpayer.toLowerCase()) {
          return 1
        }
        if (a.taxpayer.toLowerCase() < b.taxpayer.toLowerCase()) {
          return -1
        }
        return 0
      })
    } else {
      // Descending
      sortByTaxpayerAsc = true
      arrangements = arrangements.sort((a, b) => {
        if (a.taxpayer.toLowerCase() > b.taxpayer.toLowerCase()) {
          return -1
        }
        if (a.taxpayer.toLowerCase() < b.taxpayer.toLowerCase()) {
          return 1
        }
        return 0
      })
    }
  }

  const sortByPhaseHandler = () => {
    if (sortByPhaseAsc) {
      // Ascending
      sortByPhaseAsc = false
      arrangements = arrangements.sort((a, b) => {
        if (a.phase > b.phase) {
          return 1
        }
        if (a.phase < b.phase) {
          return -1
        }
        return 0
      })
    } else {
      // Descending
      sortByPhaseAsc = true
      arrangements = arrangements.sort((a, b) => {
        if (a.phase > b.phase) {
          return -1
        }
        if (a.phase < b.phase) {
          return 1
        }
        return 0
      })
    }
  }

  const handleSearch = () => {
    if (searchArgument !== '') {
      arrangements = arrangementsAll.filter((p) => {
        if (
          p.displayName.toLowerCase().includes(searchArgument.toLowerCase()) ||
          p.taxpayer.toLowerCase().includes(searchArgument.toLowerCase())
        ) {
          return true
        } else {
          return false
        }
      })
    } else {
      arrangements = [...arrangementsAll]
    }
  }

  const sortByDateHandler = () => {
    if (sortByDateAsc) {
      // Ascending
      sortByDateAsc = false
      arrangements = arrangements.sort((a, b) => {
        if (a.assessed === '') {
          return 1
        }
        if (moment(a.assessed, 'DD.MM.YYYY').toDate().getTime() > moment(b.assessed, 'DD.MM.YYYY').toDate().getTime()) {
          return 1
        }
        if (moment(a.assessed, 'DD.MM.YYYY').toDate().getTime() < moment(b.assessed, 'DD.MM.YYYY').toDate().getTime()) {
          return -1
        }
        return 0
      })
    } else {
      // Descending
      sortByDateAsc = true
      arrangements = arrangements.sort((a, b) => {
        if (a.assessed === '') {
          return 1
        }
        if (moment(a.assessed, 'DD.MM.YYYY').toDate().getTime() > moment(b.assessed, 'DD.MM.YYYY').toDate().getTime()) {
          return -1
        }
        if (moment(a.assessed, 'DD.MM.YYYY').toDate().getTime() < moment(b.assessed, 'DD.MM.YYYY').toDate().getTime()) {
          return 1
        }
        return 0
      })
    }
  }

  const handleRenew = async () => {
    try {
      menuId = ''
      inProcess = true
      await new QueryArrangementsInteractor().execute()
      arrangements = new UseArrangements().list()
      sortByTaxpayerAsc = true
      sortByTaxpayerHandler()
      arrangementsAll = [...arrangements]
    } catch (error) {
      console.log(error.message)
    } finally {
      inProcess = false
    }
  }

  const reFetchAssets = async () => {
    State.setAssets([])
    try {
      inProcess = true
      await new QueryAssetsInteractor().execute()
    } catch (error) {
      inProcess = false
      console.log(error.message)
    } finally {
      inProcess = false
    }
  }

  onMount(async () => {
    try {
      inProcess = true
      State.setAssets([])
      await new QueryAssetsInteractor().execute()
      arrangements = new UseArrangements().list()
      sortByTaxpayerAsc = true
      sortByTaxpayerHandler()
      arrangementsAll = [...arrangements]
    } catch (error) {
      errors.system = error
      inProcess = false
    } finally {
      inProcess = false
    }
  })
</script>

<Topnav />
<ActionRibbon
  title={$_('section.arrangements.header.label')}
  actionLabel={$_('action.addArrangement')}
  actionIcon="add"
  on:cancel={handleCancel}
  on:action={handleNew}
/>

<div class="page" transition:fade={{ duration: 200, easing: sineInOut }}>
  <div class="error">{errors.system}</div>

  <div class="search">
    <div class="search-field">
      <SearchField name="searchField" placeholder="" bind:value={searchArgument} on:input={handleSearch} />
    </div>
    <div class="material-icons renew-button" on:click={handleRenew}>autorenew</div>
  </div>

  <div class="grid-container">
    <div class="grid-item grid-header" on:click={sortByTaxpayerHandler}>{$_('arrangement.header.taxpayer')}</div>
    <div class="grid-item grid-header" on:click={sortByNameHandler}>
      {$_('arrangement.header.displayName')}
    </div>
    <div class="grid-item grid-header" on:click={sortByDateHandler}>{$_('arrangement.header.assessed')}</div>
    <div class="grid-item grid-header">{$_('arrangement.header.file')}</div>
    <div class="grid-item grid-header" on:click={sortByPhaseHandler}>{$_('arrangement.header.phase')}</div>
    <div class="grid-item grid-header right-align">
      {$_('arrangement.header.action')}
    </div>
  </div>

  {#each arrangements as arrangement}
    <div class="grid-row">
      <div class="grid-container">
        <div class="grid-item">{arrangement.taxpayer}</div>
        <div class="grid-item">{arrangement.displayName}</div>
        <div class="grid-item">{arrangement.assessed}</div>

        {#if arrangement.file}
          <span class="material-icons warn-sign">warning</span>
        {:else}
          <span class="material-icons good-sign">check_circle</span>
        {/if}
        <div class="grid-item">{arrangement.phase}</div>

        <div class="dropdown">
          <div
            data-key={arrangement.id}
            class="material-icons grid-item right-align action-icon"
            on:click={handleToggleContextMenu}
          >
            more_vert
          </div>
          <div
            id={`contextMenu_${arrangement.id}`}
            class="dropdown-content"
            class:show-context-menu={menuId === arrangement.id}
          >
            <li data-key={arrangement.id} on:click={handleEdit}>
              {$_('action.edit')}
            </li>
            <li data-key={arrangement.id} on:click={handleArrangementReport}>
              {$_('action.pdfGenerate')}
            </li>
            <li on:click={documentUploadInput.click()}>
              {$_('action.uploadDocument')}
              <input
                id="documentUpload"
                class="hidden"
                accept="application/*, image/*"
                type="file"
                bind:this={documentUploadInput}
                on:change={handleUploadDocument}
              />
            </li>
          </div>
        </div>
      </div>
    </div>
  {:else}
    <p>{$_('section.arrangements.empty')}</p>
  {/each}
</div>

{#if inProcess}
  <Loader />
{/if}

<style>
  .page {
    width: 100%;
    display: block;
    padding: 5%;
    padding-top: 150px;
  }

  .grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: 25% 20% 15% 15% 15% 10%;
    background-color: transparent;
    padding: 0;
  }

  .search {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    align-items: center;
  }

  .search-field {
    width: 100%;
  }

  .renew-button {
    cursor: pointer;
    font-size: 150%;
    margin-left: 20px;
  }

  .grid-header {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .grid-header:hover {
    cursor: pointer;
  }

  .grid-row {
    display: flex;
    background-color: #fff;
    height: 40px;
    align-items: center;
  }

  .grid-row:hover {
    cursor: pointer;
    background-color: #fff8f3;
  }

  .right-align {
    text-align: right;
  }

  .grid-item {
    padding: 0;
    font-size: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .action-icon {
    font-size: 120%;
  }

  .dropdown {
    position: relative;
    text-align: right;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    left: -80px;
    background-color: #f1f1f1;
    width: 180px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown-content li {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown li:hover {
    background-color: #ddd;
  }

  .show-context-menu {
    display: block;
  }

  .error {
    font-weight: bold;
    color: red;
  }

  @media (min-width: 640px) {
    .page {
      max-width: none;
    }
  }

  @media (max-width: 640px) {
    .page {
      margin-bottom: 100px;
    }
    .grid-item {
      font-size: 80%;
    }
    .action-icon {
      font-size: 100%;
    }
  }

  .hidden {
    visibility: hidden;
  }
</style>
