<script>
  import {
    State,
    UpdateDisclosureInteractor,
    AddDisclosureInteractor,
    BZStAccountIdValidator,
    BZStAuthTaxNumberIdValidator,
    DiscloserCompletenessCheck,
    ElmaHeaderType,
    createXmlReportDE,
  } from '@axelity/dac6trackerjs'
  import { equals, clone } from 'ramda'

  import moment from 'moment'

  // Routing
  import { pop, replace } from 'svelte-spa-router'

  // I18N
  import { _, locale } from 'svelte-i18n'

  // Navigation;
  import Topnav from '../navigation/Topnav.svelte'

  // Components
  import ActionRibbon from '../components/ActionRibbon.svelte'

  // Elements
  import Button from '../elements/Button.svelte'
  import Segment from '../elements/Segment.svelte'
  import Page from '../elements/Page.svelte'
  import InputField from '../elements/InputField.svelte'
  import ToggleSwitch from '../elements/ToggleSwitch.svelte'
  import MultiSelect from '../elements/MultiSelect.svelte'
  import Dropdown from '../elements/Dropdown.svelte'

  if (!State.isAuthenticated) {
    replace('/signin')
  }

  let fields = {
    accountId: '',
    authTaxNumber: '',
    customerId: '',
    valid: true,
  }
  let errors = {
    accountId: '',
    authTaxNumber: '',
    customerId: '',
    system: '',
  }

  let taxpayerName = ''
  let arrangementName = ''

  const initialize = () => {
    fields.accountId = localStorage.getItem('dac6tracker.xml.de.accountId') || ''
    fields.authTaxNumber = localStorage.getItem('dac6tracker.xml.de.authTaxNumber') || ''

    taxpayerName = State.currentDisclosure.relevantTaxpayers[0].displayName
    arrangementName = State.currentDisclosure.arrangement.displayName
  }
  initialize()

  // Handler
  const handleCancel = () => {
    State.setCurrentDisclosure(undefined)
    pop()
  }

  const handleCreateReport = async () => {
    // Check completeness of disclosure and persons
    fields.valid = true
    errors = {
      accountId: '',
      authTaxNumber: '',
      customerId: '',
      system: '',
    }

    try {
      fields.accountId = await new BZStAccountIdValidator(State.locale).execute(
        fields.accountId
      )
      localStorage.setItem('dac6tracker.xml.de.accountId', fields.accountId)
    } catch (error) {
      fields.valid = false
      errors.accountId = error.message
    }

    try {
      fields.authTaxNumber = await new BZStAuthTaxNumberIdValidator(
        State.locale
      ).execute(fields.authTaxNumber)
      localStorage.setItem('dac6tracker.xml.de.authTaxNumber', fields.authTaxNumber)
    } catch (error) {
      fields.valid = false
      errors.authTaxNumber = error.message
    }

    try {
      let result = new DiscloserCompletenessCheck(State.locale).execute(
        State.currentDisclosure
      )
      if (!result.valid) {
        let msg = result.errors.join('\n')
        errors.system = msg
        fields.valid = false
      }
    } catch (error) {
      fields.valid = false
      errors.system = error.message
    }

    if (!fields.valid) {
      fields.valid = false
      return
    }

    try {
      const header = {
        accountId: fields.accountId,
        authTaxNumber: fields.authTaxNumber,
        customerId: fields.customerId,
      }

      const s = createXmlReportDE(State.currentDisclosure, header)

      let blob = new Blob([s], { type: 'text/xml' })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${State.currentDisclosure.messageId}.xml`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      State.setCurrentDisclosure(undefined)

      pop()
    } catch (error) {
      errors.system = error.message
    }
  }
</script>

<style>
  .error {
    font-weight: bold;
    color: red;
  }
</style>

<Topnav />
<ActionRibbon
  title={`${$_('xmlReportDE.header')} - ${taxpayerName}: ${arrangementName}`}
  actionLabel={$_('action.create')}
  actionIcon="save_alt"
  on:cancel={handleCancel}
  on:action={handleCreateReport} />

<Page>

  <div class="error">{errors.system}</div>

  <Segment title={$_('xmlReportDE.elmakomHeader')} expand={true}>

    <InputField
      type="text"
      name="authTaxNumber"
      placeholder={$_('xmlReportDE.authTaxNumber.placeholder')}
      label={$_('xmlReportDE.authTaxNumber.label')}
      required={true}
      bind:value={fields.authTaxNumber} />
    <div class="error">{errors.authTaxNumber}</div>

    <InputField
      type="text"
      name="accountId"
      placeholder={$_('xmlReportDE.accountId.placeholder')}
      label={$_('xmlReportDE.accountId.label')}
      required={true}
      bind:value={fields.accountId} />
    <div class="error">{errors.accountId}</div>

    <InputField
      type="text"
      name="customerId"
      placeholder={$_('xmlReportDE.customerId.placeholder')}
      label={$_('xmlReportDE.customerId.label')}
      required={true}
      bind:value={fields.customerId} />
    <div class="error">{errors.customerId}</div>

  </Segment>

</Page>
